import React, { useState, useEffect } from "react";
import {
  // NavLink as RRNavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch
} from "react-router-dom";
import {
  Card,
  Row,
  Col,
  // Spinner,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
  // CardBody
} from "reactstrap";
import axios from "axios";
// import { v1 as uuidv5 } from "uuid";
// import _ from "lodash";
// import classnames from "classnames";
// import TopicItem from "./TopicItem";
import NoMatch from "../NoMatch";
import {hyphen} from "../Utilities/emdash";


// const hyphen = word => {
//   return word.replace(/[^a-zA-Z0-9\- ]/g,"").replace(/ /g, "-").toLowerCase();
// };

const TopicPage = () => {
  let { category, pageId } = useParams();
  const [topicItem, setTopicItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState("");

  // const [activeTab, setActiveTab] = useState();
  //
  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  let { path, url } = useRouteMatch();

  const cleanPath = url => {
    const fixedPath = url.endsWith("/")
      ? url.substring(0, url.length - 1)
      : url;
    return fixedPath;
  };

  useEffect(
    () => {
      let isCancelled = false;
      async function fetchData() {
        const response = await axios.get(`/api/topics/${category}/${pageId}/`);
        // .then(res => {
        //   const data = res.data;
        //   if (!isCancelled) {
        //     setTopicItem(data);
        //   }
        // })
        // .catch(err => console.log(err));

        if (!isCancelled) {
          if (!Array.isArray(response.data) || !response.data.length) {
            setNotFound("topic was not found, here's a hug instead 🤗");
            setIsLoading(false);
          } else {
            setTopicItem(response.data);
            setIsLoading(false);
          }
        }
      }

      fetchData();
      // topicId && setActiveTab(hyphen(topicId));
      // setActiveTab(hyphen(topicId));

      return () => {
        isCancelled = true;
      };
    },
    [category, pageId]
  );

  return (
    <div>
      {/*{path}*/}

      {isLoading && (
        <Row>


          <Col lg="12" className="mb-2 text-center px-1 px-sm-2" >
            <Card className="loading-card mb-0 pb-4">
              <div>
                <span className="gray-dot" style={{opacity: "0.6"}} />
                {/*<Spinner className="gray-dot" color="secondary" />*/}
                <div className="gray-line w-75 mt-n2" />
                <div className="gray-line w-50 mb-0" />
              </div>
            </Card>
          </Col>
          {/*<Col lg="12" className="mb-2 ">*/}
          {/*  <Card className="loading-card">*/}
          {/*    <Spinner className="mx-auto" color="secondary" />*/}
          {/*  </Card>*/}
          {/*</Col>*/}
        </Row>
      )}


      {notFound && <NoMatch what="topic" />}

      {!Array.isArray(topicItem) || !topicItem.length ? (
        <Row>
          {/*<Col lg="12" className="mb-2">*/}
          {/*  <Card className="loading-card">*/}
          {/*    <Spinner className="mx-auto" color="secondary" />*/}
          {/*  </Card>*/}
          {/*</Col>*/}
        </Row>
      ) : (
        <div>
          {/*{topicItem.length}*/}
          <Switch>
            <Route exact path={path}>
              {topicItem.length ? (
                <Redirect
                  to={`${cleanPath(url)}/${hyphen(
                    topicItem[0].parentTopicTitle
                  )}`}
                />
              ) : (
                // <Redirect to="/topics/covid19/data" />
                <NoMatch what="topic" />
              )}

              {/*// OLD WAY OF MAKING ITEM LIST PAGE*/}
              {/*// --------------------------------*/}
              {/*{topicItem.map(({ parentTopicTitle, subtitle, image, _id }, topicIndex) => {*/}
              {/*  return (*/}
              {/*    <Card body className="col-lg-4 justify-content-center" key={topicIndex + _id}>*/}
              {/*      /!*className="media-body" ---made widths equal but not responsive*!/*/}
              {/*      <NavLink*/}
              {/*        style={{color: "black", borderRadius: "9px"}}*/}
              {/*        tag={RRNavLink}*/}
              {/*        to={`${url}/${hyphen(parentTopicTitle)}`}*/}
              {/*      >*/}
              {/*        <img*/}
              {/*          style={{ maxWidth: "80px" }}*/}
              {/*          src={image}*/}
              {/*          // className="pb-2"*/}
              {/*          alt=""*/}
              {/*        />*/}
              {/*        <h5>{parentTopicTitle}</h5>*/}
              {/*        <div style={{fontSize: "14px"}}>{subtitle}</div>*/}
              {/*        <div className="text-black-50 ">select topic for detailed info</div>*/}
              {/*      </NavLink>*/}
              {/*    </Card>*/}
              {/*  );*/}
              {/*})}*/}

              {/*<Nav className="justify-content-center align-items-stretch em-nav p-0 text-center d-flex" pills fill justified>*/}
              {/*  {topicItem.map(({ parentTopicTitle, subtitle, image, _id }, topicIndex) => {*/}
              {/*    return (*/}
              {/*      <Card body className="col-lg-4 justify-content-center" key={topicIndex + _id}>*/}
              {/*        /!*className="media-body" ---made widths equal but not responsive*!/*/}
              {/*        <NavLink*/}
              {/*                 style={{color: "black", borderRadius: "9px"}}*/}
              {/*          tag={RRNavLink}*/}
              {/*          to={`${url}/${hyphen(parentTopicTitle)}`}*/}
              {/*        >*/}
              {/*          <img*/}
              {/*            style={{ maxWidth: "80px" }}*/}
              {/*            src={image}*/}
              {/*            // className="pb-2"*/}
              {/*            alt=""*/}
              {/*          />*/}
              {/*          <h5>{parentTopicTitle}</h5>*/}
              {/*          <div style={{fontSize: "14px"}}>{subtitle}</div>*/}
              {/*          <div className="text-black-50 ">select topic for detailed info</div>*/}
              {/*        </NavLink>*/}
              {/*      </Card>*/}
              {/*    );*/}
              {/*  })}*/}
              {/*</Nav>*/}
            </Route>

            {/*<Route path="*">*/}
            {/*  <div>Didn't match</div>*/}
            {/*</Route>*/}

            {/*<Route  path={`${path}/:topicId`}>*/}
            {/*  <TopicItem2 item={topicItem} />*/}
            {/*</Route>*/}
            {/*<Route path={`*`}>*/}
            {/*  <Redirect to={`${pageId}/${hyphen(topicItem[0].parentTopicTitle)}`} />*/}
            {/*</Route>*/}

            {/*<Route path={`${path}/`}>*/}
            {/*  <TopicItem />*/}
            {/*</Route>*/}
            {/*<Route path={`${path}`}>*/}
            {/*  <Redirect to="/*" />*/}
            {/*</Route>*/}
          </Switch>
        </div>
      )}
    </div>
  );
};

export default TopicPage;

// <Card>
//
//   {/*{topicItem.length}*/}
//   <Switch>
//
//     <Route exact path={path}>
//       <Nav className="em-nav align-items-bottom p-0 text-center" pills fill justified>
//         {topicItem.map(({ parentTopicTitle, subtitle, image, _id }, topicIndex) => {
//           return (
//             <NavItem  style={{display: "grid"}} key={topicIndex + _id}>
//               <NavLink className="bg-white "
//                        style={{border: "1px solid rgba(0,0,0, 0.1)", color: "black"}}
//                        tag={RRNavLink}
//                        to={`${url}/${hyphen(parentTopicTitle)}`}
//               >
//                 <img
//                   style={{ maxWidth: "50px" }}
//                   src={image}
//                   // className="pb-2"
//                   alt=""
//                 />
//                 <h5>{parentTopicTitle}</h5>
//                 <div style={{fontSize: "14px"}}>{subtitle}</div>
//                 <div className="text-black-50 gentle-border p-1">select to read more</div>
//               </NavLink>
//             </NavItem>
//           );
//         })}
//       </Nav>
//     </Route>
//   </Switch>
//
// </Card>
