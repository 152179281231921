import React, { useState, useEffect } from "react";
import {
  NavLink as RRNavLink,
  // Redirect,
  // Route,
  // Switch,
  useParams,
  // useRouteMatch
} from "react-router-dom";
import {
  Card,
  Spinner,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
  CardDeck,
  // Navbar,
  // NavbarToggler,
  // Collapse
} from "reactstrap";
import axios from "axios";
// import { v1 as uuidv5 } from "uuid";
// import _ from "lodash";
import SubTopicItem from "./SubTopicItem";
import classnames from "classnames";
// import NoMatch from "../NoMatch";
// import TopicHeaderMenu from "./TopicHeaderMenu";
import { hyphen } from "../Utilities/emdash";

// const hyphen = word => {
//   console.log("ok " + word.replace(/[^a-zA-Z0-9\- ]/g,"").replace(/ /g, "-").toLowerCase() )
//   return word.replace(/[^a-zA-Z0-9\- ]/g,"").replace(/ /g, "-").toLowerCase();
// };

const TopicItem = () => {
  let { category, pageId, topicId } = useParams();
  const [topicItem, setTopicItem] = useState([]);
  const [activeTab, setActiveTab] = useState(hyphen(topicId));
  const [isLoading, setIsLoading] = useState(true);
  // const [notFound, setNotFound] = useState("");

  // const [noSubtopic, setNoSubtopic] = useState(null);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      // setNoSubtopic(null);
    }
  };

  // let { path, url } = useRouteMatch();

  // const cleanPath = url => {
  //   const fixedPath = url.endsWith("/")
  //     ? url.substring(0, url.length - 1)
  //     : url;
  //   return fixedPath;
  // };

  // const [hasError, setErrors] = useState(false);
  // const [active, setActive] = useState(null);
  // const [count, setCount] = useState(0);
  // const [pageId, setTopicId] = useState(useParams().pageId);
  // const isCancelled = useRef(false);
  // const [showCitations, setShowCitations] = useState(null);

  useEffect(
    () => {
      let isCancelled = false;
      async function fetchData() {
        const response = await axios.get(`/api/topics/${category}/${pageId}/`);
        // .then(res => {
        //   const data = res.data;
        //
        //   if (!isCancelled) {
        //     setTopicItem(data);
        //   }
        //   return data;
        // })
        // .catch(err => console.log(err));
        if (!isCancelled) {
          // if (!Array.isArray(response.data) || !response.data.length) {
          //   setNotFound("topic was not found, here's a hug instead 🤗");
          //   setIsLoading(false);
          //   console.log("ran");
          // } else {
          setTopicItem(response.data);
          setIsLoading(false);
          // }

          // set no subtopic in order to redirect if they mistyped subtopic name into browser
          // if (!Array.isArray(response.data) || !response.data.length) {
          //   console.log("Error my friend, no response.data in TopicItem");
          // } else {
          //   // gives an array of [true, true, true], but if one is false, then the topic exists
          //   const trueArray = response.data.map(
          //     topObj => hyphen(topObj.parentTopicTitle) !== hyphen(topicId)
          //   );
          //   trueArray.every(x => x) && setNoSubtopic(topicId);
          // }
        }
        // return response.data;
      }

      fetchData();

      pageId && setActiveTab(hyphen(topicId));

      return () => {
        isCancelled = true;
      };
    },
    [category, pageId, topicId]
  );

  return (
    <div>
      {/*{!Array.isArray(topicItem) || !topicItem.length || !pageId ? (<span />) : (*/}
      {/*  <div>{topicItem.length > 1 &&*/}
      {/*  <TopicHeaderMenu topic={topicItem} />*/}
      {/*  }</div>*/}
      {/*)}*/}

      {/*<TopicHeaderMenu topic={} />*/}
      {/*<Col lg="12" className="mb-2 text-center">*/}
      {/*  <Card className="loading-card mb-0 pb-4">*/}
      {/*    <div>*/}
      {/*      <Spinner className="gray-dot" style={{opacity: "0.5", marginBottom: "25px"}} color="secondary" />*/}
      {/*      <div className="gray-line w-75 mt-n2" />*/}
      {/*      <div className="gray-line w-50 mb-0" />*/}
      {/*    </div>*/}
      {/*  </Card>*/}
      {/*</Col>*/}

      {/*{noSubtopic && <Redirect to={`/topics/covid19/${pageId}`} />}*/}
      {/*{noSubtopic && <NoMatch what="subtopic" />}*/}
      {/*<Switch>*/}
      {/*  <Route exact path={path}>*/}
      {/*    Category: {hyphen(category)} | pageId: {pageId} | hyphen pageId: {hyphen(pageId)} | ACTIVE: {activeTab}*/}
      {/*    <div>Topic: {topicItem.length}</div>*/}
      {/*    <Nav className="em-nav align-items-bottom p-0 text-center" pills fill justified>*/}
      {/*      {topicItem.map(({ parentTopicTitle, image, _id }, topicIndex) => {*/}
      {/*        return (*/}
      {/*          <NavItem style={{display: "grid"}} key={topicIndex + _id}>*/}
      {/*            <NavLink*/}
      {/*              // className={classnames({*/}
      {/*              //   active: activeTab === hyphen(parentTopicTitle)*/}
      {/*              // })}*/}
      {/*              tag={RRNavLink}*/}
      {/*              to={`${url}/${hyphen(parentTopicTitle)}`}*/}
      {/*            >*/}
      {/*              <img*/}
      {/*                style={{ maxWidth: "50px" }}*/}
      {/*                src={image}*/}
      {/*                // className="pb-2"*/}
      {/*                alt=""*/}
      {/*              />*/}
      {/*              <div>{parentTopicTitle} apples</div>*/}
      {/*            </NavLink>*/}
      {/*          </NavItem>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </Nav>*/}
      {/*  </Route>*/}
      {/*</Switch>*/}
      {isLoading && (
        <Row>
          <Col lg="12" className="mb-2 text-center px-1 px-sm-2">
            <Card className="loading-card mb-0 pb-4">
              <div>
                <Spinner
                  className="gray-dot opacity-1"
                  style={{ marginBottom: "25px" }}
                  color="secondary"
                />
                <div className="gray-line w-75 mt-n2" />
                <div className="gray-line w-50 mb-0" />
              </div>
            </Card>
          </Col>
          {/*<Col lg="12" className="mb-2 ">*/}
          {/*  <Card className="loading-card">*/}
          {/*    <Spinner className="mx-auto" color="secondary" />*/}
          {/*  </Card>*/}
          {/*</Col>*/}
        </Row>
      )}
      {/*{notFound && <div>{notFound}</div>}*/}
      <Row>
        {!Array.isArray(topicItem) || !topicItem.length || !pageId ? (
          <Col>
            {/*<Col lg="12" className="mb-2 px-2 px-md-3">*/}
            {/*  <Card className="loading-card p-3">*/}
            {/*    <Spinner className="mx-auto" color="secondary" />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col lg="12" className="mb-2 ">*/}
            {/*  <Card className="loading-card">*/}
            {/*    <Spinner className="mx-auto" color="secondary" />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col lg="6" className="mb-2 px-2 px-md-3">*/}
            {/*  <Card className="loading-card">*/}
            {/*    <Spinner className="mx-auto" color="secondary" />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
          </Col>
        ) : (
          <Col className="px-1 px-sm-2">
            <Card style={{ backgroundColor: "#fafafa" }}>
              {/*hyphen pageId: {hyphen(pageId)} | hyphen topicId: {hyphen(topicId)} | ACTIVE: {activeTab}*/}

              <Nav
                className="em-nav align-items-bottom p-0 text-center"
                pills
                fill
                justified
              >
                {topicItem.map(
                  ({ parentTopicTitle, image, _id }, topicIndex) => {
                    return (
                      <NavItem
                        style={{ display: "grid" }}
                        key={topicIndex + _id}
                        // className="position-relative"
                      >
                        <NavLink
                          className={classnames({
                            active: activeTab === hyphen(parentTopicTitle)
                          })}
                          tag={RRNavLink}
                          to={`${hyphen(parentTopicTitle)}`}
                          onClick={() => {
                            toggle(hyphen(parentTopicTitle));
                          }}
                        >

                          <Row className="no-gutters d-inline-flex flex-row justify-content-center align-items-center ">
                            <Col md="auto" className="p-1">
                              <img
                                style={{ maxWidth: "30px" }}
                                src={image}
                                alt=""
                              />
                            </Col>
                            <Col md="auto" className="p-1 mt-n2 mt-sm-0 line-h-1">
                              {parentTopicTitle}
                            </Col><Col sm="3" />
                          </Row>
                        </NavLink>
                      </NavItem>
                    );
                  }
                )}
              </Nav>

              <TabContent activeTab={activeTab} className="row" />
              {topicItem.map(
                (
                  { parentTopicTitle, subtitle, topicGroups, image, _id },
                  topicIndex
                ) => {
                  return (
                    <TabPane tabId={hyphen(parentTopicTitle)} key={_id}>
                      {activeTab === hyphen(parentTopicTitle) && (
                        <div className="px-0 align-items-center text-center">
                          {/*<Col className="mb-2 d-md-flex px-2 px-md-3" key={_id}>*/}
                          {/*  <Card*/}
                          {/*    className="h-100 align-items-center text-center"*/}
                          {/*    body*/}
                          {/*    size="sm"*/}
                          {/*  >*/}

                          {/*<img*/}
                          {/*  style={{ maxHeight: "120px", maxWidth: "120px" }}*/}
                          {/*  src={image}*/}
                          {/*  className="img-fluid w-50 pb-2"*/}
                          {/*  alt=""*/}
                          {/*/>*/}
                          {/*<h5>*/}
                          {/*  <b>{parentTopicTitle.toUpperCase()}</b>*/}
                          {/*</h5>*/}
                          {/*<div className="mb-2">*/}
                          {/*  <h5 className="text-black-50">*/}
                          {/*    <b>{subtitle}</b>*/}
                          {/*  </h5>*/}
                          {/*  <hr />*/}
                          {/*</div>*/}

                          {/*{active === _id && (*/}
                          {!Array.isArray(topicGroups) ||
                          !topicGroups.length ? (
                            <span />
                          ) : (
                            <CardBody className="px-sm-4 px-0">
                              {/*<Row*/}
                              <CardDeck
                                className="flex-row"
                                style={{ fontSize: "16px" }}
                              >
                                {topicGroups.map((topGroupObj, i) => (
                                  // <Col
                                  <Card
                                    // className="gentle-border-soft px-md-4 p-sm-1 mb-lg-0 mb-sm-5"
                                    className="mb-lg-0 mb-md-0 mb-3 px-0 px-sm-2 py-2 mx-1"
                                    key={i + "topGroupObj"}
                                  >
                                    <div className="title-group-title">
                                      {topGroupObj.image && (
                                        <div>
                                          <img
                                            className="top-group-img"
                                            src={topGroupObj.image}
                                            alt=""
                                          />
                                        </div>
                                      )}

                                      {topGroupObj.title && (
                                        <div
                                        // className="group-box-text"
                                        // className="title-group-title"
                                        >
                                          {topGroupObj.title}
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="justify-content-center align-items-stretch"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {/*<CardDeck>*/}
                                      {topGroupObj.subtopics &&
                                        topGroupObj.subtopics.map(
                                          (subtopicObj, ind) => (
                                            <Card
                                              // md="6"
                                              key={ind + "topicGroupsBody"}
                                              // style={{ minWidth: "230px" }}

                                              // style={{ margin: "1px" }}

                                              // className="subtopic-card mx-1 d-flex"
                                              className="mx-1 d-flex"


                                              // className="justify-content-center mb-2 mx-1"
                                              // className="gentle-outline-no-shadow mx-md-2 m-sm-3 "
                                            >
                                              <SubTopicItem
                                                sectionObj={subtopicObj}
                                                ind={ind}
                                              />
                                            </Card>
                                          )
                                        )}
                                      {/*</CardDeck>*/}
                                    </div>
                                  </Card>
                                ))}
                              </CardDeck>
                            </CardBody>
                          )}

                          {/*</Card>*/}
                        </div>
                      )}
                    </TabPane>
                  );
                }
              )}

              {topicItem[0].disclaimer === "health" && (
                <div
                  style={{ fontSize: "0.7em" }}
                  className="gentle-border opacity-2 text-center"
                >
                  This app does not provide medical advice. It is intended for
                  informational purposes only. It is not a substitute for
                  professional medical advice, diagnosis or treatment.
                </div>
              )}
            </Card>
          </Col>
        )}
      </Row>
      <TabContent activeTab={activeTab} />
    </div>
  );
};

export default TopicItem;
