import React, {
  useState
  // , useEffect
} from "react";
import { useHistory } from "react-router-dom";
import { Card, Button, Popover, PopoverBody } from "reactstrap";
import axios from "axios";

const DeleteTopicButton = ({ topicType, idToDelete, setTopic }) => {
  const [errors, setErrors] = useState(null);
  const [response, setResponse] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const history = useHistory();

  const toggle = () => setPopoverOpen(!popoverOpen);

  // useEffect to clear response message
  // useEffect(
  //   () => {
  //     const timer = setTimeout(() => {
  //       setResponse(null);
  //     }, 10000);
  //     return () => clearTimeout(timer);
  //   },
  //   [response]
  // );

  const refreshMan = joe => {
    console.log("refreshMan ran");
    const timer = setTimeout(() => {
      setTopic(joe);
      console.log("did we get here?");
    }, 1000);
    return () => clearTimeout(timer);
  };

  const deleteSubtopic = async id => {
    console.log("DELETING SUBTOPIC: " + id);
    // setResponse("DELETED SUBTOPIC =]");

    const joe = await axios
      .delete(`/api/topics/subtopic/${id}`)
      .then(res => {
        setResponse("deleted successfully");
        console.log("XXXXXXXXXX " + JSON.stringify(res.data.updatedTopic));
        return res.data;
      })
      .catch(err => setErrors(err));

    //coming from the server with SUCCESS, so didn't put it on the front-end
    joe.success && refreshMan(joe.updatedTopic);
  };

  const deleteTopicGroup = async id => {
    console.log("DELETING TOPIC-GROUP: " + id);

    // setResponse("DELETED TOPIC GROUP =]");
    const joe = await axios
      .delete(`/api/topics/topic-group/${id}`)
      .then(res => {
        setResponse("deleted group successfully");
        return res.data;
      })
      .catch(err => setErrors(err));

    //coming from the server with SUCCESS, so didn't put it on the front-end
    joe.success && refreshMan(joe.updatedTopic);
  };

  const deleteParentTopic = async id => {
    console.log("DELETING PARENT TOPIC: " + id);

    const joe = await axios
      .delete(`/api/topics/${id}`)
      .then(res => {
        setResponse("deleted successfully");
        return res.data;
      })
      .catch(err => setErrors(err));

    //coming from the server with SUCCESS, so didn't put it on the front-end
    joe.success && history.push("/update/admin/dash");
  };

  const checkWhatType = (topicType, id) => {
    switch (topicType) {
      case "subtopic":
        return (
          <div>
            <Button
              id={"Popover3" + id}
              type="button"
              className="btn-danger px-3"
            >
              Delete Subtopic
            </Button>
            <Popover
              trigger="legacy"
              placement="bottom"
              isOpen={popoverOpen}
              target={"Popover3" + id}
              toggle={toggle}
            >
              <PopoverBody>
                <Button
                  className="btn-danger px-3"
                  onClick={() => deleteSubtopic(id)}
                >
                  Confirm Delete
                </Button>
              </PopoverBody>
            </Popover>
          </div>
        );
      case "topicGroup":
        return (
          <div>
            <Button
              id={"Popover2" + id}
              type="button"
              className="btn-danger px-3"
            >
              Delete Group
            </Button>
            <Popover
              trigger="legacy"
              placement="bottom"
              isOpen={popoverOpen}
              target={"Popover2" + id}
              toggle={toggle}
            >
              <PopoverBody>
                <Button
                  className="btn-danger"
                  onClick={() => deleteTopicGroup(id)}
                >
                  Confirm Delete
                </Button>
              </PopoverBody>
            </Popover>
          </div>
        );
      case "parentTopic":
        return (
          <div>
            <Button
              id={"Popover1" + id}
              type="button"
              className="btn-danger px-3"
            >
              Delete
            </Button>
            <Popover
              trigger="legacy"
              placement="bottom"
              isOpen={popoverOpen}
              target={"Popover1" + id}
              toggle={toggle}
            >
              <PopoverBody>
                <Button
                  className="btn-danger"
                  onClick={() => deleteParentTopic(id)}
                >
                  Confirm Delete
                </Button>
              </PopoverBody>
            </Popover>
          </div>
        );

      default:
        return <div>Nothing to delete, no button</div>;
    }
  };

  // const {_id} = idToDelete;
  // const {typeOfTopic} = topicType;

  return (
    <div>
      {checkWhatType(topicType, idToDelete)}
      {/*<div className="text-black-50">*/}
      {/*{topicType}: */}
      {/*  {idToDelete}*/}
      {/*</div>*/}
      {response && (
        <Card className="gentle-outline-x">
          {JSON.stringify(response)}{" "}
          <Button onClick={() => setResponse(null)}>Clear</Button>
        </Card>
      )}
      {errors && (
        <Card className="gentle-outline-x col-6 bg-danger text-white">
          {JSON.stringify(errors)}
          <Button onClick={() => setErrors(null)}>Clear</Button>
        </Card>
      )}
    </div>
  );
};

export default DeleteTopicButton;
