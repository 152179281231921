import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner, Row, Col, Button } from "reactstrap";
import axios from "axios";
import _ from "lodash";
import ChartNew from "../Charts/ChartNew";
// import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
// import CountrySearchForm from "./CountrySearchForm";
import DetailedCasesDash from "../DetailedCasesDash";
import { useLocation } from "react-router-dom";
import USSearchForm from "./USSearchForm";
import useClipboard from "react-use-clipboard";
import GoBack from "../../Utilities/GoBack";

const USDash = ({ incomingCases }) => {
  let query = new URLSearchParams(useLocation().search);
  const location = useLocation();

  // const [allCases, setAllCases] = useState([]);
  const [countyCase, setCountyCase] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCopied, setCopied] = useClipboard(
    `https://kindthink.com/t/covid19/data/us/l?city=${query.get("city")}&state=${query.get("state")}`,
    {
      successDuration: 2000,
    }
  );
  const _isMounted = useRef(true);
  // const [noResult, setNoResult] = useState(false);
  // const [cityNamesArray, setCityNamesArray] = useState([]);

  useEffect(() => {
    // check if a clickedCountry value was passed down from CoronaDash and CoronaCaseTable
    // const isThereClickedCountry = clickedCountry.trim() === null || clickedCountry.trim() === "";

    async function fetchData() {
      const queriedState = _.startCase(query.get("state"));
      const queriedCityClean = _.startCase(query.get("city"));
      const cityCleanNYC = queriedCityClean === "New York City" ? "New York" : queriedCityClean;
      const result = await axios.get("/api/jhu/get/us", { params: { state: queriedState, city: cityCleanNYC } });
      setCityData(result);

      // console.log("RES singingggg: " + JSON.stringify(result));
      // if (result.data.success) {
      //   const theResults = result.data.jhuDataRes.jhuData;
      //   // const theResults = result.data.jhuDataRes.jhuData;
      //
      //   // setAllCases(result.data.jhuDataRes.jhuData);
      setIsLoading(false);
      //   // selectCountry("Los Angeles");
      //   // const filteredCases = result.data.jhuDataRes.jhuData.filter(x => x.Admin2 === "Los Angeles");
      //
      //   const queryCityClean = _.startCase(query.get("city"));
      //   const cityCleanNYC = queryCityClean === "New York City" ? "New York" : queryCityClean;
      //   setCityData(
      //     theResults.filter((x) => x.Admin2 === cityCleanNYC && x.Province_State === _.startCase(query.get("state")))
      //   );
      //   // console.log("asdasdsdasd " + _.startCase(query.get("city")) + " --- " + _.startCase(query.get("state")));
      //   // get all county names, remove first 5 indexes since they're null
      //   // setCityNamesArray(theResults.slice(5, theResults.length - 1).map(x => x.Admin2));
      //
      //   // console.log("CITY NAMES ARRAY: " + JSON.stringify(theResults.slice(5, theResults.length-1).map(x => x.Combined_Key)));
      // } else {
      //   console.log("res failed: " + JSON.stringify(result));
      // }
    }

    // isThereClickedCountry ? fetchData() : selectCountry(clickedCountry);
    fetchData();
    // selectCountry("Los Angeles");

    return () => {
      _isMounted.current = false;
      setIsLoading(true);
    };
  }, [location]);

  // const selectCountry = countyInput => {
  const setCityData = (cityData) => {
    // let county = countyInput === "United States" ? "US" : countyInput;
    // const filteredCases = allCases.filter(x => x.Admin2 === "Los Angeles");

    // cleaned the Lat and Long fields that were screwing up the summing totals
    // const result = cityData.map(({ Lat, Long_, UID, iso2, iso3, code3, FIPS, ...rest }) => ({ ...rest }));
    const result = cityData;

    // console.log("SMACKKKK: " + JSON.stringify(result));

    // result exists
    if (result) {
      // setSelectedCounty(countyInput);
      setSelectedCounty(_.startCase(query.get("city")));
      // let cray = [];
      // let sliced = [];
      let notSliced = [];

      // when many Country/Region objects match, need to be merged

      const converted = Object.entries(result.data.jhuDataRes[0].jhuCountyData).map(([key, value]) => ({
        // date: key.replace(/\/20/, ""),
        date: key,
        confirmed: parseInt(value),
      }));
      // console.log("CONVERTED: " + JSON.stringify(converted));

      const countDaily = converted.map((obj, i, arr) => ({
        date: obj.date,
        confirmed: obj.confirmed,
        new: arr[i - 1] ? obj.confirmed - arr[i - 1].confirmed : obj.confirmed,
      }));

      // remove first elements of the converted array
      const sliced = countDaily.slice(countDaily.length - (countDaily.length - 5), countDaily.length);
      // console.log("CONVERTED: " + JSON.stringify(sliced));

      // notSliced = sliced;

      // setCountyCase(sliced);
      setCountyCase(sliced);
      // setNoResult(false);
    } else {
      // setNoResult(true);
    }
  };

  // const filterCases = county => {
  //   // let jam = county === "United States" ? "US" : county;
  //   const filtered = incomingCases.filter(c => c.county === county);
  //   // let result = filtered.map(({ confirmed, deaths, recovered }) => ({ confirmed, deaths, recovered }))
  //   return filtered[0];
  // };

  //to shorten cases being sent to components
  const sliceCase = (casesWithAllDates, shortenBy) => {
    return casesWithAllDates.slice(casesWithAllDates.length - shortenBy);
  };

  const calcPeakDate = (county) => {
    return county.reduce((peakDate, date) => {
      return (peakDate.new || 0) > date.new ? peakDate : date;
    }, {});
  };

  const calcSixtyDayPeak = (county) => {
    const countySliced = _.takeRight(county, 60);
    return countySliced.reduce((peakDate, date) => {
      return (peakDate.new || 0) > date.new ? peakDate : date;
    }, {});
  };

  const calcSixtyDayLow = (county) => {
    const countySliced = _.takeRight(county, 60);
    return countySliced.reduce((lowestDate, date) => {
      return lowestDate.new < date.new || 1 > date.new ? lowestDate : date;
    }, {});
  };

  //---------------------------------------------------------//
  //---------------------------------------------------------//
  //---------------------------------------------------------//
  return (
    <Row className="text-center">
      <Col className="px-1 px-sm-2" lg="4">
        {/*<Card body className="py-2 pb-3 px-1 py-md-3 px-md-2">*/}
        <USSearchForm />
        {/*</Card>*/}
        <div className="opacity-1 mb-n1 mt-n2">
          <GoBack />
        </div>
      </Col>

      <Col className="px-1 px-sm-2" lg="8">
        {isLoading ? (
          <Card className="loading-card">
            <h4 className="opacity-3 pt-0 mt-n4">
              <b>{_.startCase(query.get("city"))}</b>
            </h4>
            <span className="opacity-3 pt-0 mt-n1">loading data</span>
            <Spinner className="mx-auto opacity-1" color="secondary" />
          </Card>
        ) : (
          <Card
            body
            className="p-2 p-md-3"
            // style={{ minHeight: "500px" }}
          >
            {/*<CountrySearchForm selectCountry={selectCountry} />*/}
            {/*{noResult && (*/}
            {/*	// <div className="pt-1 mt-2 mb-0 px-3 d-inline-block">*/}
            {/*	<div className="mt-n1 mb-0 px-3 d-inline-block">*/}
            {/*		<h5>*/}
            {/*			<b>we currently have no data for that county</b>*/}
            {/*		</h5>*/}
            {/*	</div>*/}
            {/*)}*/}

            {countyCase.length > 0 && countyCase[countyCase.length - 1].confirmed > 2 ? (
              <div>
                <h4 className="mb-md-n5 mb-n4 mt-sm-1">
                  <b>{selectedCounty}</b>
                </h4>
                <ChartNew selection={sliceCase(countyCase, 121)} />
                {/*<h4 className="opacity-2 mt-n3 mb-0">*/}
                {/*  <b>{selectedCounty}</b>*/}
                {/*</h4>*/}

                {/*<div>Peak Date: {JSON.stringify(calcPeakDate(countyCase))}</div>*/}

                <DetailedCasesDash
                  // todayTotal={calcTotals(selectedCounty)}
                  todayTotal={countyCase[countyCase.length - 1]}
                  yesterdayTotal={countyCase[countyCase.length - 1]}
                  ereYesterdayTotal={countyCase[countyCase.length - 2]}
                  peakDate={calcPeakDate(countyCase)}
                  sixtyDayLow={calcSixtyDayLow(countyCase)}
                  sixtyDayPeakDate={calcSixtyDayPeak(countyCase)}
                  allDates={countyCase.slice(1, countyCase.length)}
                  cityData={true}
                />
                <div className="opacity-2 mt-n2 mb-n1 font-sm">
                  <b>KINDTHINK.COM</b>
                </div>
                <div className="opacity-2 pt-1">
                  <Button size="sm" className="mt-0 p-1" color="light" onClick={setCopied}>
                    <span role="img" aria-label="Clipboard">
                      📋
                    </span>{" "}
                    Copy Shareable Link{" "}
                    <span role="img" aria-label="Clipboard">
                      📋
                    </span>
                  </Button>
                  {isCopied && <span className="opacity-3 position-absolute pl-2 pt-1">Copied ✔</span>}
                </div>
              </div>
            ) : (
              <Row noGutters={true}>
                <Col>
                  <h3 className="opacity-1 p-4" style={{ paddingTop: "80x" }}>
                    county info not found or unavailable
                  </h3>
                </Col>
              </Row>
            )}
          </Card>
        )}
      </Col>
      {/*<Col className="px-1 px-sm-2" lg="4" className="opacity-2"><GoBack /></Col>*/}
    </Row>
  );
};

export default USDash;

// <Animate
//   play={isCopied}
//   duration={0.6}
//   // delay={0.1}
//   start={{
//     opacity: "0",
//     transform: "translateY(0px)"
//   }}
//   end={{
//     opacity: "0.7",
//     transform: "translateY(-50px)"
//   }}
//   complete={{ display: 'none' }}
// >
//                   <span className="position-absolute">
//                     Copied
//                   </span>
// </Animate>
