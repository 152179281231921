import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Card, Form, Col, Label, Button } from "reactstrap";
import axios from "axios";
import classnames from "classnames";

const UpdateParentTopic = ({ topic, setTopic }) => {
  const [response, setResponse] = useState(null);
  const [addNavItem, setAddNavItem] = useState(!!topic.navItem);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      category: topic.category,
      page: topic.page,
      parentTopicTitle: topic.parentTopicTitle,
      subtitle: topic.subtitle,
      image: topic.image,
      importance: topic.importance ? topic.importance : 100,
      disclaimer: topic.disclaimer,
      navItem: topic.navItem && {
        text: topic.navItem.text,
        image: topic.navItem.image,
        ranking: topic.navItem.ranking
      }
      // addNavItem: topic.navItem && true
    }
  });

  // useEffect to clear response message
  useEffect(
    () => {
      const timer = setTimeout(() => {
        setResponse(null);
      }, 10000);
      return () => clearTimeout(timer);
    },
    [response]
  );

  const onSubmit = async data => {
    console.log(data);
    // delete data.addNavItem;
    data.parentTopicTitle = data.parentTopicTitle.trim();
    // data.navItem.text = data.navItem.text.trim();

    const joe = await axios
      .put(`/api/topics/${topic._id}`, data)
      .then(res => {
        setResponse(res.data.message);
        return { success: true, updatedTopic: res.data.updatedTopic };
      })
      .catch(err => {
        setResponse(err);
        console.log("wtf " + err);
        return { success: false, error: err };
      });

    // joe.success && reset(joe.updatedTopic);
    // joe.success && setTopic(joe.updatedTopic);

    // setTopic(joe);
    if (joe.success) {
      reset(joe.updatedTopic);
      setTopic(joe.updatedTopic);
      joe.updatedTopic.navItem ? setAddNavItem(true) : setAddNavItem(false);
    }

    // nothingChange (UNTOUCHED?)
    //   ? console.log("it exists")
    //   : axios
    //       .post(`/api/topics/group/${groupId}`, data)
    //       .then(res => setResponse(res.data))
    //       .catch(err => {
    //         setResponse(err);
    //         console.log("wtf " + err);
    //       });
  };

  return (
    <div className="gentle-outline-x">
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <h4>
          {topic.parentTopicTitle && (
            <span>
              <b> {topic.parentTopicTitle}</b>
            </span>
          )}
        </h4>

        {/*{groupId && <div>{groupId}</div>}*/}
        {/*<Row>*/}
        {/*  <Col md="6" className="p-0">*/}
        {/*----------------------------------------*/}
        {/*----------------------------------------*/}
        {/*-Add Title / Image / Importance Section-*/}
        {/*----------------------------------------*/}
        {/*----------------------------------------*/}
        <div>
          {/*<div className="gentle-border">*/}
          <Row>
            <Label sm={2}>Category</Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="category"
                placeholder="category"
                ref={register({ required: true })}
              />
            </Col>
          </Row>
          <Row>
            <Label sm={2}>Page</Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="page"
                placeholder="page"
                ref={register({ required: true })}
              />
            </Col>
          </Row>
          <Row>
            <Label sm={2}>Title</Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="parentTopicTitle"
                placeholder="parent topic title"
                ref={register({ required: true })}
              />
            </Col>
          </Row>
          <Row>
            <Label sm={2}>Subtitle </Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="subtitle"
                placeholder="subtitle"
                ref={register}
                // ref={register({ required: true })}
              />
            </Col>
          </Row>
          <Row>
            <Label sm={2}>Importance</Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="importance"
                type="number"
                placeholder="importance"
                ref={register({ required: true })}
              />
            </Col>
          </Row>{" "}
          <Row>
            <Label sm={2}>Image</Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="image"
                placeholder="topic image"
                ref={register}
              />
              {topic.image && (
                <img
                  style={{ maxWidth: "40px" }}
                  src={topic.image}
                  className="img-fluid w-50 pb-2"
                  alt=""
                />
              )}
            </Col>
          </Row>
          <Row>
            <Label sm={2}>Disclaimer</Label>
            <Col sm={10}>
              <input
                className="form-control"
                name="disclaimer"
                placeholder="disclaimer"
                ref={register}
              />
            </Col>
          </Row>
          <div
            className={classnames("rounded border mx-n3 p-2 mb-3", {
              "bg-primary": topic.navItem
            })}
          >
            {addNavItem ? (
              <div>
                <Row>
                  <Label sm={2}>Menu Text</Label>
                  <Col sm={10}>
                    <input
                      className="form-control"
                      name="navItem.text"
                      placeholder="nav item text"
                      ref={register}
                    />
                  </Col>
                </Row>
                <Row>
                  <Label sm={2}>Menu Image</Label>
                  <Col sm={10}>
                    <input
                      className="form-control"
                      name="navItem.image"
                      placeholder="nav item image"
                      ref={register}
                    />
                    {topic.navItem && (
                      <div>
                        {topic.navItem.image && (
                          <img
                            style={{ maxWidth: "40px" }}
                            src={topic.navItem.image}
                            className="img-fluid w-50 pb-2"
                            alt=""
                          />
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Label sm={2}>Menu Ranking</Label>
                  <Col sm={10}>
                    <input
                      className="form-control"
                      name="navItem.ranking"
                      type="number"
                      placeholder="nav item ranking"
                      ref={register}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <Button
                className="font-sm btn-sm"
                onClick={() => setAddNavItem(!addNavItem)}
              >
                Make Nav Item?
              </Button>
            )}
          </div>
          <div>{topic.topicGroups.length} topic groups </div>
          <input
            type="submit"
            disabled={!formState.dirty || formState.isSubmitting}
            className="btn btn-primary btn-block mt-1"
            value="Update Parent"
          />
          {/*</div>*/}
        </div>
        {/*  </Col>*/}
        {/*</Row>*/}
      </Form>

      {response && (
        <Card className="gentle-outline-x">
          <h3>Response</h3>
          {JSON.stringify(response)}
        </Card>
      )}
    </div>
  );
};

export default UpdateParentTopic;
