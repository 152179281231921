// import 'materialize-css/dist/css/materialize.min.css';
import React from "react";
import ReactDOM from "react-dom";
// import { Provider } from 'react-redux';
// import { createStore, applyMiddleware } from 'redux';
// import reduxThunk from "redux-thunk";

import App from "./components/App";
// import reducers from "./reducers";

// const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
// /*<Provider store={store}><App /></Provider>,*/

ReactDOM.render(<App />, document.querySelector("#root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
