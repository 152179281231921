import React, { useState, useEffect } from "react";
import { Card, Spinner } from "reactstrap";
import axios from "axios";

const Questions = category => {
  const [hasError, setErrors] = useState(false);
  const [active, setActive] = useState(null);
  const [info, setInfo] = useState([]);

  useEffect(
    () => {
      let isCancelled = false;

      async function fetchData() {
        axios
          .get(`/api/q/${category.category}/`)
          .then(res => {
            const data = res.data;
            if (!isCancelled) {
              const sorted = data.sort(
                (a, b) => (a.importance > b.importance ? 1 : -1)
              );
              setInfo(sorted);
            }
          })
          .catch(err => setErrors(err));
      }

      fetchData();

      return () => {
        isCancelled = true;
      };
    },
    [category.category]
  );

  return (
    <div>
      {hasError === true && <span>Has error</span>}

      {!Array.isArray(info) || !info.length ? (
        <Card className="loading-card">
          <Spinner className="mx-auto opacity-1" color="secondary" />
        </Card>
      ) : (
        <span>
          {info.map(({ question, answer, citations, _id }) => {
            return (
              <Card body key={_id} size="sm">
                <div
                  className="custom-link-hover"
                  onClick={() =>
                    active === _id ? setActive(null) : setActive(_id)
                  }
                >
                  <h6>{question}</h6>
                </div>

                {active === _id && (
                  <div>
                    {answer}
                    {citations[0] && (
                      <Card
                        style={{
                          backgroundColor: "rgba(255,255,255,0.2)",
                          opacity: "0.5"
                        }}
                      >
                        {citations.map((c, i) => {
                          return (
                            <div style={{ fontSize: "10px" }}>
                              {i + 1}. {c}
                            </div>
                          );
                        })}
                      </Card>
                    )}
                  </div>
                )}

                {/*<Selected answer={answer} citations={citations} id={_id}/>*/}

                {/*{active === true && (*/}
                {/*  <div>*/}
                {/*    {answer}*/}
                {/*    {citations[0] &&*/}
                {/*      citations.map(c => {*/}
                {/*        return <span>{c}</span>;*/}
                {/*      })}{" "}*/}
                {/*  </div>*/}
                {/*)}*/}
              </Card>
            );
          })}
        </span>
      )}
    </div>
  );
};

export default Questions;
