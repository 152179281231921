import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// import { connect } from "react-redux";
// import * as actions from "../actions";
import {Container} from 'reactstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

import Header from "./Header";
import Meditate from "./Utilities/Meditate";
import Misinformation from "./Misinformation";
import TopicsAdminDash from "./Topics/TopicsAdmin/TopicsAdminDash";
import Topics from "./Topics/Topics";
import EditTopic from "./Topics/TopicsAdmin/EditTopic";
import JhuAdmin from "./Cases/CaseAdmin/JhuAdmin";
// import CoronaDash from "./CoronaDash";
// import TopicItem from "./Topics/TopicItem";

class App extends Component {
  // componentDidMount() {
  //   this.fetchInfo();
  // }

  // fetchInfo = id => {
  //   axios
  //     .get(`/api/info/${id}`)
  //     .then(res => {
  //       const { upcomingRelease, currentRelease } = res.data;
  //       this.setState({
  //         upcomingName: upcomingRelease.name,
  //         upcomingSubtitle: upcomingRelease.subtitle,
  //         currentName: currentRelease.name,
  //         currentSubtitle: currentRelease.subtitle,
  //         currentSpotifyUrl: currentRelease.spotifyUrl
  //       })
  //     })
  //     .catch(function(error) {
  //       // handle error
  //       console.log(error);
  //     });
  // };

  render() {
    return (
      <BrowserRouter>
        <Header />

        <Container fluid="xl"
          // className="px-5 px-sm-4"
        >
          <Switch>
          <Route exact path="/" render={() => (<Redirect to="/t/covid19" />)} />
          <Route exact path="/meditate" component={Meditate} />

          <Route exact path="/corona/data" render={() => (<Redirect to="/t/covid19/data" />)} />
          {/*TEMPORARY to adapt for browser history routing to /topics instead of the /t I changed to on 6-27-20*/}
          <Route exact path="/topics/covid19/data" render={() => (<Redirect to="/t/covid19/data" />)} />

          <Route exact path="/misinformation" component={Misinformation} />

          <Route path="/t/:category" component={Topics} />
          {/*<Route exact path="/corona/topics/:category" component={TopicItem} />*/}


          <Route exact path="/update/admin/dash" component={TopicsAdminDash} />
          <Route exact path="/update/admin/edit/topic/:id" component={EditTopic} />
          <Route exact path="/update/admin/jhu" component={JhuAdmin} />

            <Route path="/*" render={() => (<Redirect to="/"/>)} />
          </Switch>
        </Container>
      </BrowserRouter>
    );
  }
}

// export default connect(
//   null,
//   actions
// )(App);

export default App;