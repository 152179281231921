import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Form, Col, Row, Label } from "reactstrap";
import axios from "axios";
import DeleteTopicButton from "./DeleteTopicButton";

const UpdateTopicGroup = ({ groupId, setTopic, title, image }) => {
  const [response, setResponse] = useState(null);
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      title: title,
      image: image
    }
  });

  // useEffect to clear response message
  useEffect(
    () => {
      const timer = setTimeout(() => {
        setResponse(null);
      }, 10000);
      return () => clearTimeout(timer);
    },
    [response]
  );

  const onSubmit = async data => {
    // data.image.trim().length < 10 && delete data.image;
    // console.log(data);

    const joe = await axios
      .put(`/api/topics/topic-group/${groupId}`, data)
      .then(res => {
        setResponse(res.data.message);
        return { success: true, updatedTopic: res.data.updatedTopic };
      })
      .catch(err => {
        setResponse(err);
        console.log("wtf " + err);
        return { success: false, error: err };
      });

    joe.success && setTopic(joe.updatedTopic);
  };

  return (
    <div className="gentle-border">
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="3">
            <h2>
              <b>{title}</b>
            </h2>
            {image &&   <img
              style={{maxWidth: "40px", transform: "translate(85px, -40px)" }}
              src={image}
              className="img-fluid w-50 pb-2 position-absolute opacity-3"
              alt=""
            />}
          </Col>

          <Col md="6">
            <Row noGutters={true}>
              <Label sm={2}>Title</Label>
              <Col sm={10}>
                <input
                  className="form-control"
                  name="title"
                  placeholder="topic group title"
                  ref={register({ required: true })}
                />
              </Col>
            </Row>
            <Row noGutters={true}>
              <Label sm={2}>Image</Label>
              <Col sm={10}>
                <input
                  className="form-control"
                  name="image"
                  placeholder="group image"
                  ref={register}
                />
              </Col>
            </Row>
          </Col>
          <Col md="3">
            <input
              type="submit"
              disabled={!formState.dirty || formState.isSubmitting}
              className="btn btn-primary btn-sm btn-block mt-2"
              value="Update Group"
            />
            <div>
              <DeleteTopicButton
                setTopic={setTopic}
                topicType="topicGroup"
                idToDelete={groupId}
              />
            </div>
          </Col>
        </Row>
      </Form>

      {response && (
        <Card className="gentle-border">
          <h3>Response</h3>
          {JSON.stringify(response)}
        </Card>
      )}
    </div>
  );
};

export default UpdateTopicGroup;
