import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner, Row, Col } from "reactstrap";
import axios from "axios";
import DeleteTopicButton from "./DeleteTopicButton";

const GetAllTopics = () => {
  const [hasError, setErrors] = useState(false);
  // const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([]);

  const _isMounted = useRef(true);

  useEffect(
    () => {
      // let isCancelled = false;

      async function fetchData() {
        axios
          .get(`/api/topics/all`)
          .then(res => {
            const data = res.data;
            if (_isMounted.current) {
              // const sorted = data.sort(
              //   (a, b) => (a.importance > b.importance ? 1 : -1)
              // );
              // setInfo(sorted);
              setTopics(data);
              setLoading(false);
            }
          })
          .catch(err => setErrors(err));
      }

      fetchData();
      // console.log("useEffect in GetAllTopics ran");
      return () => {
        _isMounted.current = false;
      };
    },
    [topics, loading]
  );

  const forceFetch = async () => {
    console.log("forceFetch ran");
    setLoading(true);
    const updatedResult = await axios
      .get(`/api/topics/all`)
      .then(res => {
        const data = res.data;
        // setLoading(false);
        return data;
      })
      .catch(err => setErrors(err));

    updatedResult && setTopics(updatedResult);
  };

  return (
    // <Row >
    <Card body>
      <h3>
        <div className="custom-link-hover pb-1" onClick={() => forceFetch()}>
          <b>ALL TOPICS</b>{" "}
        </div>
      </h3>
      {hasError && JSON.stringify(hasError)}

      {!Array.isArray(topics) || !topics.length ? (
        <div>
          {!loading ? (
            "There are no topics in the database"
          ) : (
            <Card className="loading-card">
              <Spinner className="mx-auto" color="secondary" />
            </Card>
          )}
        </div>
      ) : (
        <Row>
          {topics.map(
            ({
              category,
              page,
              parentTopicTitle,
              importance,
              navItem,
              _id
            }) => {
              return (
                <Col md="2" className="px-1" key={_id}>
                  <Card
                    body
                    size="sm"
                    className="px-1 pt-2"
                    style={{ backgroundColor: navItem ? "#d0f5ff" : "" }}
                  >
                    <Link
                      to={"edit/topic/" + _id}
                      className="custom-link-hover nav-link text-dark"
                    >
                      <div>{category} {navItem && <sup>{navItem.ranking}</sup>}</div>
                      <div className="opacity-1 my-n2">{importance}</div>
                      <div className="text-black-50">
                        <b>{page}</b>
                      </div>
                      <div>
                        <b>{parentTopicTitle}</b>
                      </div>
                    </Link>
                    <DeleteTopicButton
                      topicType="parentTopic"
                      idToDelete={_id}
                    />
                  </Card>
                </Col>
              );
            }
          )}
        </Row>
      )}
    </Card>
    // </Row>
  );
};

export default GetAllTopics;
