import React, { useState } from "react";
// import { useForm } from "react-hook-form";
import { Card, Col, Button, Row } from "reactstrap";
import axios from "axios";
// import GetAllTopics from "../../Topics/TopicsAdmin/GetAllTopics";
// import AddParentTopic from "../../Topics/TopicsAdmin/AddParentTopic";
import DeleteCases from "./DeleteCases";
// import CountryDash from "../Charts/CountryDash";

const JhuAdmin = () => {
  // const { register, watch, handleSubmit, reset, formState } = useForm();
  const [response, setResponse] = useState(null);
  // const [selection, setSelection] = useState("US");

  // const onSubmit = async data => {
  //   const resObj = await UpdateCoronaStats.deleteOlderCases(
  //     data.start,
  //     data.end
  //   );
  //   setResponse(resObj);
  // };

  const updateJhu = async () => {
    const resObj = await axios.get("/api/jhu/update/global-time");
    setResponse(resObj);
  };

  const getJhuGlobalTime = async () => {
    const resObj = await axios.get("/api/jhu/get/global-time");
    setResponse(resObj);
  };

  const deleteJhuGlobalTime = async () => {
    const resObj = await axios.delete("/api/jhu/delete/global-time");
    setResponse(resObj);
  };

  const updateUS = async () => {
    const resObj = await axios.get("/api/jhu/update/us");
    setResponse(resObj);
  };

  const getUS = async () => {
    const resObj = await axios.get("/api/jhu/get/us");
    setResponse(resObj);
  };

  const deleteUS = async () => {
    const resObj = await axios.delete("/api/jhu/delete/us");
    setResponse(resObj);
  };

  return (
    <div className="text-center">
      <Row>
        <Col sm="4">
          <Card body>
            <b>United States JHU</b>
            <Button color="warning" onClick={updateUS} className="mx-2 px-2">
              Update US County  Local Data
            </Button>
            <Button color="success" onClick={deleteUS} className="mx-2 px-2">
              Delete Older US County  Data
            </Button>
            <Button outline color="primary" onClick={getUS} className="mx-2 px-2">
              Get US County JHU Data (crashes)
            </Button>
          </Card>
        </Col>
        <Col sm="4">
          <Card body>
            <b>Global JHU</b>
            <Button color="warning" onClick={updateJhu} className="mx-2 px-2">
              Update JHU Countries New Data
            </Button>
            <Button color="success" onClick={deleteJhuGlobalTime} className="mx-2 px-2">
              Delete Older JHU Countries Data
            </Button>
            <Button color="primary" onClick={getJhuGlobalTime} className="mx-2 px-2">
              Get JHU Countries Data
            </Button>
          </Card>
        </Col>
        <Col sm="4">
          <DeleteCases />
        </Col>
      </Row>

      {/*<Row>*/}
      {/*  <Col md="6">*/}
      {/*    <Card body>*/}
      {/*   */}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      <Row>
        <Col>
          <Card body>
            <h5>Response</h5>
            <div>{response && JSON.stringify(response.data)}</div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default JhuAdmin;
