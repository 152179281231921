import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Spinner } from "reactstrap";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
// import Questions from "../Questions";
import UpdateCoronaStats from "./CaseAdmin/UpdateCoronaStats";
// import CoronaCaseTable from "./CoronaCaseTable";
// import MarchCDCTable from "./MarchCDCTable";
// import Topics from "./Topics/Topics";
// import Topics from "./Topics/Topics"

const GlobalData = () => {
  const [hasError, setErrors] = useState(false);
  const [global, setGlobal] = useState({});
  const [ydayCases, setYdayCases] = useState({});
  const _isMounted = useRef(true);

  useEffect(() => {
    // let isCancelled = false;

    async function fetchData() {
      //in coronaRoutes.js
      axios
        .get(`/api/test/pup`)
        .then((res) => {
          // const globalCases = res.data;
          const globalCases = res.data.latestCases;
          const yesterdayCases = res.data.yesterdayCases;
          // console.log("globalCases " + JSON.stringify(yesterdayCases));

          // if yesterdayCases exists, setState for yesterday to today comparisons
          yesterdayCases && setYdayCases(yesterdayCases);

          if (_isMounted.current) {
            setGlobal(globalCases);
          }
        })
        .catch((err) => {
          UpdateCoronaStats.updateUnconditionally();
          setErrors(err);
          console.log("In catch error of CoronaDash: " + JSON.stringify(err));
          return null;
        });
    }

    fetchData();

    // console.log("in stats useEffects");
    return () => {
      // isCancelled = true;
      _isMounted.current = false;
    };
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      {/*<Row>*/}
      {/*  <Col lg="8">*/}
      {hasError && <div className="font-sm">{hasError}</div>}
      {global && (
        <div>
          {global.confirmed ? (
            <div>
              {/*<Card body className="pb-2">*/}
              <div>
                {/*<h6>Global COVID-19 Totals</h6>*/}
                <Card body className="px-0 pt-1 pb-0">
                  <h5 className="mb-1 mt-2">
                    <b>COVID-19 GLOBAL TRACKER</b>
                  </h5>
                  <Row className="no-gutters align-items-center global-numbers mt-n2">
                    <Col md="6" xs="6">
                      <div
                        className="py-2 p-md-1"
                        // style={{borderRight: "1px solid rgb(204, 204, 204)"}}
                      >
                        <div className="mb-n2 opacity-3">Confirmed</div>
                        <div style={{ fontSize: "1.6em" }}>
                          <b> {global.confirmed.toLocaleString("en")} </b>
                        </div>
                        {/*<div style={{ fontSize: "14px" }}>Confirmed</div>*/}
                        {/*<div className="mt-n2" style={{ fontSize: "14px" }}>Cases</div>*/}
                        <div className="mt-n2" style={{ fontSize: "13px" }}>
                          {ydayCases ? (
                            <span>
                              {ydayCases.confirmed && ydayCases.confirmed < global.confirmed ? (
                                <span>+{(global.confirmed - ydayCases.confirmed).toLocaleString()} Today</span>
                              ) : (
                                <span>Cases</span>
                              )}
                            </span>
                          ) : (
                            <span>Cases</span>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col md="6" xs="6" style={{ color: "rgb(181,91,89)" }}>
                      <div
                        className="py-2 p-md-1"
                        // style={{borderLeft: "1px solid rgb(204, 204, 204)"}}
                      >
                        <div className="mb-n2 opacity-3">Deaths</div>
                        <div style={{ fontSize: "1.6em" }}>
                          <b>{global.deaths.toLocaleString("en")}</b>
                        </div>
                        <div className="mt-n2" style={{ fontSize: "13px" }}>
                          {/*Deaths*/}
                          <span className="d-inline-block mx-2">
                            {/*<b>*/}
                            {_.round((global.deaths / global.confirmed) * 100, 1)}%{/*</b>*/}
                          </span>

                          {ydayCases && (
                            <span>
                              {ydayCases.confirmed && ydayCases.deaths < global.deaths ? (
                                <span className="d-inline-block mx-2">
                                  +{(global.deaths - ydayCases.deaths).toLocaleString()} Today{" "}
                                </span>
                              ) : (
                                <span />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>

                    {/*<Col md="3" xs="6" style={{ color: "#1da000" }}>*/}
                    {/*  <div className="py-2 p-md-1">*/}
                    {/*    <div className="mb-n2 opacity-3">Recovered</div>*/}
                    {/*    <div style={{ fontSize: "1.6em" }} className="pt-0">*/}
                    {/*      /!*<b>*!/*/}
                    {/*      <b> {global.recovered.toLocaleString("en")}</b>*/}
                    {/*      /!*</b>*!/*/}
                    {/*    </div>*/}
                    {/*    <div className="mt-n2" style={{ fontSize: "13px" }}>*/}
                    {/*      <div>*/}
                    {/*        <span className="d-inline-block mx-2">*/}
                    {/*          {_.round((global.recovered / global.confirmed) * 100, 1)}%*/}
                    {/*        </span>*/}

                    {/*        {ydayCases && (*/}
                    {/*          <span>*/}
                    {/*            {ydayCases.confirmed && ydayCases.recovered < global.recovered ? (*/}
                    {/*              <span className="d-inline-block mx-2">*/}
                    {/*                +{(global.recovered - ydayCases.recovered).toLocaleString()} Today*/}
                    {/*              </span>*/}
                    {/*            ) : (*/}
                    {/*              <span />*/}
                    {/*            )}*/}
                    {/*          </span>*/}
                    {/*        )}*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</Col>*/}

                    {/*<Col md="3" xs="6">*/}
                    {/*  <div*/}
                    {/*    className="py-2 p-md-1"*/}
                    {/*    // style={{borderRight: "1px solid rgb(204, 204, 204)"}}*/}
                    {/*  >*/}
                    {/*    <div className="mb-n2 opacity-3">Active</div>*/}
                    {/*    <div*/}
                    {/*      style={{*/}
                    {/*        fontSize: "1.6em",*/}
                    {/*        color: "rgb(132, 132, 132)",*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      <b>{(global.confirmed - global.recovered - global.deaths).toLocaleString("en")}</b>*/}
                    {/*    </div>*/}
                    {/*    <div className="mt-n2" style={{ fontSize: "13px" }}>*/}
                    {/*      Pending Outcomes*/}
                    {/*    </div>*/}
                    {/*    /!*<div style={{ fontSize: "14px" }}>Infected</div>*!/*/}
                    {/*  </div>*/}
                    {/*</Col>*/}
                  </Row>
                  <div
                    style={{ marginBottom: "-22px", opacity: "0.1" }}
                    className="badge badge-light text-white mb-0 pb-0"
                  >
                    {global.source} updated {moment(global.dateAdded).fromNow()}
                  </div>
                </Card>
              </div>
            </div>
          ) : (
            <Card className="loading-card">
              <h6>COVID-19 DATA LOADING</h6>
              <Spinner className="mx-auto opacity-1" color="secondary" />
            </Card>
          )}
        </div>
      )}

      {/*  </Col>*/}
      {/*</Row>*/}
    </div>
  );
};

export default GlobalData;
