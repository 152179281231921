import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Card, Form, Button } from "reactstrap";
import axios from "axios";

const defaultValues = {
  importance: 100
  // text: [""],
  // citations: [{}],
  // claims: [{}]
};

const AddSubtopicForm = ({ groupId, parentTopic, setTopic }) => {
  const { register, watch, handleSubmit, control, reset, formState } = useForm({
    defaultValues
  });
  const { fields, append, remove } = useFieldArray({ control, name: "claims" });
  const {
    fields: textFields,
    append: textAppend,
    remove: textRemove
  } = useFieldArray({ control, name: "text" });
  const {
    fields: citationFields,
    append: citationAppend,
    remove: citationRemove
  } = useFieldArray({ control, name: "citations" });

  const [response, setResponse] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const addImage = watch("addImage");

  // useEffect to clear response message
  useEffect(
    () => {
      const timer = setTimeout(() => {
        setResponse(null);
      }, 10000);
      return () => clearTimeout(timer);
    },
    [response]
  );

  const onSubmit = async data => {
    delete data.addImage;
    data.title.trim().length < 1 && delete data.title;
    data.importance.trim().length < 1 && delete data.importance;
    console.log(data);

    const joe = await axios
      .post(`/api/topics/group/${groupId}`, data)
      .then(res => {
        setResponse("successfully added =]");
        return { success: true, updatedTopic: res.data };
      })
      .catch(err => {
        setResponse(err);
        console.log("wtf " + err);
        return { success: false, err: err };
      });

    // joe.success && setTopic(joe.updatedTopic);
    // joe.success && reset({ ...defaultValues }, { isSubmitted: false });

    if(joe.success) {
      setTopic(joe.updatedTopic);
      reset({ ...defaultValues }, { isSubmitted: false });
    }
    // existingTopic
    //   ? console.log("it exists")
    //   : axios
    //       .post(`/api/topics/group/${groupId}`, data)
    //       .then(res => setResponse(res.data))
    //       .catch(err => {
    //         setResponse(err);
    //         console.log("wtf " + err);
    //       });
  };

  return (
    <div className="gentle-border bg-warning">
      <div>
        <Button
          className="px-2 py-0 mb-2 btn-dark btn-sm custom-btn"
          onClick={() => {
            setShowForm(!showForm);
            reset({ ...defaultValues });
          }}
        >
          {showForm ? "Close" : "Show"} Add Subtopic Form
        </Button>
      </div>
      {!showForm ? (
        <div />
      ) : (
        <div className="mb-4">
          <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {/*<h4>*/}
            {/*  Add <b>Subtopic</b>{" "}*/}
            {/*  {parentTopic && (*/}
            {/*    <span>*/}
            {/*      to <b>{parentTopic}</b>*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*</h4>*/}

            {/*{groupId && <div>{groupId}</div>}*/}


            {/*--------------*/}
            {/*--ADD CLAIMS--*/}
            {/*--------------*/}
              <div >
                <ul className="list-group">
                  {fields.map((item, index) => (
                    <li className="list-group-item px-2"  key={item.id}>
                      <input
                        className="form-control w-85"
                        placeholder="claim"
                        name={`claims[${index}].claim`}
                        ref={register({ required: true })}
                      />
                      <input
                        className="form-control w-85"
                        placeholder="rating value (true, false, mixed)"
                        name={`claims[${index}].rating`}
                        ref={register({ required: true })}
                      />
                      <input
                        className="form-control w-85 float-left"
                        placeholder="rating text"
                        name={`claims[${index}].ratingText`}
                        ref={register({ required: true })}
                      />
                      <button
                        className="btn btn-danger float-right"
                        onClick={() => remove(index)}
                      >
                        x
                      </button>
                    </li>
                  ))}
                </ul>

                <section>
                  <button
                    className="btn btn-light btn-block p-1 mb-3"
                    type="button"
                    onClick={() => append({ name: "claims" })}
                  >
                    Add Claim
                  </button>
                </section>
              </div>

              <hr className="border-dark" />

            {/*-----------------*/}
            {/*--Simple Inputs--*/}
            {/*-----------------*/}
            <div>
              <textarea
                className="form-control"
                name="summary"
                placeholder="summary"
                ref={register({ required: true })}
              />
            </div>
            < hr className="border-dark" />

            {/*----------------------*/}
            {/*Append Text Paragraphs*/}
            {/*----------------------*/}
            {/*  <div className="gentle-border" >*/}
                <ul className="list-group">
                  {textFields.map((textItem, index) => (
                    <li className="list-group-item px-2" key={textItem.id}>
                      <textarea
                        className="form-control w-85 float-left mb-1"
                        placeholder="text paragraph"
                        name={`text[${index}]`}
                        ref={register({ required: true })}
                      />
                      <button
                        className="btn btn-danger w-10 float-right"
                        onClick={() => textRemove(index)}
                      >
                        x
                      </button>
                    </li>
                  ))}
                </ul>
                <section>
                  <button
                    className="btn btn-light btn-block p-1 mb-3"
                    type="button"
                    onClick={() => textAppend({ name: "text" })}
                  >
                    Add Paragraph
                  </button>
                </section>
              {/*</div>*/}

            <hr className="border-dark" />
            {/*---------------------*/}
            {/*------CITATIONS------*/}
            {/*---------------------*/}
            <div>
                <ul className="list-group">
                  {citationFields.map((citationItem, indexC) => (
                    <li className="list-group-item px-2"  key={citationItem.id}>
                      <input
                        className="form-control w-50 float-left mb-1"
                        placeholder="number"
                        type="number"
                        name={`citations[${indexC}].number`}
                        ref={register({ required: true })}
                      />
                      <input
                        className="form-control w-85 float-left mb-1"
                        placeholder="link"
                        name={`citations[${indexC}].link`}
                        ref={register({ required: true })}
                      />
                      <input
                        className="form-control w-85 float-left mb-1"
                        placeholder="citation"
                        name={`citations[${indexC}].citation`}
                        ref={register}
                      />
                      <button
                        className="btn btn-danger w-10 float-right"
                        onClick={() => citationRemove(indexC)}
                      >
                        x
                      </button>
                    </li>
                  ))}
                </ul>
                <section>
                  <button
                    className="btn btn-light btn-block p-1 mb-3"
                    type="button"
                    onClick={() => citationAppend({ name: "citations" })}
                  >
                    Add Citations
                  </button>
                </section>
            </div>

            <hr className="border-dark" />


            {/*----------------------------------------*/}
            {/*----------------------------------------*/}
            {/*-Add Title / Image / Importance Section-*/}
            {/*----------------------------------------*/}
            {/*----------------------------------------*/}
            <div>
              <div className="list-group-item" style={{ opacity: "0.5" }}>
                <input
                  className="form-control"
                  name="title"
                  placeholder="subtopic title"
                  ref={register}
                />
                <input
                  className="form-control"
                  name="importance"
                  type="number"
                  placeholder="importance"
                  ref={register}
                />
                <div>
                  Add Subtopic Image?{" "}
                  <input name="addImage" type="checkbox" ref={register} />
                </div>

                {addImage && (
                  <input
                    className="form-control"
                    name="image"
                    placeholder="subtopic image"
                    ref={register}
                  />
                )}
              </div>
            </div>

            <input
              type="submit"
              disabled={!formState.dirty || formState.isSubmitting}
              value="Add Subtopic"
              className="btn btn-primary mt-1"
            />
          </Form>
        </div>
      )}

      {response && (
        <Card className="gentle-outline-x">
          <h3>Response</h3>
          {JSON.stringify(response)}
        </Card>
      )}
    </div>
  );
};

export default AddSubtopicForm;
