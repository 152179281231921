// import React from "react";
import moment from 'moment';

class CalculateDate {

  static months = amount => {
    return moment()
      .add(amount, "months")
      .format("YYYY-MM-DD");
  }

  static days = amount => {
    return moment(moment(), "YYYY-MM-DDTHH:mm:ss")
      .add(amount, "days")
      .format("YYYY-MM-DD");
  }

  static time = (amount, unit) => {
    return moment(moment(), "YYYY-MM-DDTHH:mm:ss")
      .add(amount, unit)
      .format("YYYY-MM-DDTHH:mm:ss");
  }

  static today = () => {
    return moment(moment(), "YYYY-MM-DDTHH:mm:ss")
      .format("YYYY-MM-DD");
  }

}


export default CalculateDate;