import React, { useState } from "react";
// import { useParams } from "react-router-dom";
import {
  CardBody,
  // Spinner, Row, Col, Card,
  Button,
  CardFooter,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
// import axios from "axios";
// import _ from "lodash";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const SubTopicItem = props => {
  const [active, setActive] = useState(null);
  const [showCitations, setShowCitations] = useState(null);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const checkIsValid = isValid => {
    switch (isValid.toLowerCase()) {
      case "true":
        return "success";
      case "false":
        return "danger";
      default:
        return "warning";
    }
  };

  const {
    // title,
    text,
    summary,
    claims,
    citations,
    _id
    // image,
    // dateModified
  } = props.sectionObj;

  // const { ind } = props.ind;

  const replaceSubscript = text => {
    const replaced = text.replace(/\[/g, "<span class='opacity-3'>[").replace(/\]/g, "]</span>");

    // // LATEST ORIGINAL
    // const replaced = text
    //   .replace(/\[/g, "<sup>")
    //   .replace(/\]/g, "</sup> ")
    //   .replace(/sup> <sup>/g, "sup><sup>,")
    //   .replace(/<\/sup> \./g, "</sup>.");

    // // OLD ORIGINAL
    // const replaced = text
    //   .replace(/\[/g, "<sup>")
    //   .replace(/\]/g, ",</sup>")
    //   .replace(/\,\<\/sup\> /g, "</sup> ")
    //   .replace(/\,\<\/sup\>\./g, "</sup>.");

    return replaced;
  };

  return (
    <div>
      <CardBody className="pt-3 px-0">
        <div>
          {claims && (
            <div>
              {claims.map((claimObj, indexClaim) => (
                <div className="px-2 valid-claim-box" key={indexClaim + "sectionObjClaim"}>
                  {/*{claimObj.claim} */}
                  {claimObj.claim && (
                    <Badge color={checkIsValid(claimObj.rating)} className="d-block">
                      <span style={{ fontSize: "16px" }}>{claimObj.claim.toUpperCase()}</span>
                      <div style={{ fontSize: "small", opacity: "0.5" }}>
                        {checkIsValid(claimObj.rating) === "success" && "✦ LIKELY TRUE ✦"}
                        {checkIsValid(claimObj.rating) === "danger" && "⚠ INACCURATE ⚠"}
                        {checkIsValid(claimObj.rating) === "warning" && "♺ MIXED ♺"}
                      </div>
                    </Badge>
                  )}

                  {/*{checkIsValid(claimObj.rating) === "success" && <Badge color="success">✦ TRUE ✦</Badge>}*/}
                  {/*{checkIsValid(claimObj.rating) === "danger" && <Badge color="danger">⚠ FALSE ⚠</Badge>}*/}
                  {/*{checkIsValid(claimObj.rating) === "warning" && <Badge color="warning">♺ MIXED ♺</Badge>}*/}

                  {/*<br />*/}

                  {claimObj.rating && <Badge>{claimObj.ratingText.toUpperCase()}</Badge>}

                  {/*{claimObj.rating && (*/}
                  {/*  <Badge color={checkIsValid(claimObj.rating)}>*/}
                  {/*    <div style={{fontSize: "small", opacity: "0.5"}}>*/}
                  {/*      {checkIsValid(claimObj.rating) === "success" && "TRUE"}*/}
                  {/*      {checkIsValid(claimObj.rating) === "danger" && "FALSE"}*/}
                  {/*      {checkIsValid(claimObj.rating) === "warning" && "MIXED"}*/}
                  {/*    </div>*/}
                  {/*    {claimObj.ratingText.toUpperCase()}*/}
                  {/*  </Badge>*/}
                  {/*)}*/}
                </div>
              ))}
            </div>
          )}
        </div>

        {summary && (
          <div>
            {/*<Button color="danger" onClick={toggle}>*/}
            {/*  Show Modal*/}
            {/*</Button>*/}

            {text.length > 0 ? (
              // <div onClick={() => (active === _id ? setActive(null) : setActive(_id))}>
              <div onClick={toggle}>
                <div
                  className="gentle-outline p-2 custom-link-hover mb-0 mx-3"
                  style={{
                    color: active === _id && "#b4b4b4",
                    // fontSize: "1.2em",
                    fontSize: "1em",
                    // fontFamily: "Ropa Sans",
                    lineHeight: "1.3"
                  }}
                >
                  {parse(DOMPurify.sanitize(summary))}
                  <div className="white-bg-link px-1">
                    {active === _id ? "▲ SHOW LESS DETAILS ▲" : "▼ SHOW MORE DETAILS ▼"}
                  </div>

                  {/*-----------------------MODAL-------------------------*/}
                  {/*-----------------------MODAL-------------------------*/}
                  {/*-----------------------MODAL-------------------------*/}
                  <Modal isOpen={modal} toggle={toggle} centered={true}>
                    <ModalHeader className="justify-content-center">
                      {claims && (
                        <div>
                          {claims.map((claimObj, indexClaim) => (
                            <div key={indexClaim + "sectionObjClaim1"}>
                              {claimObj.claim && (
                                <Badge color={checkIsValid(claimObj.rating)} className="d-block">
                                  <span style={{ fontSize: "13px" }}>{claimObj.claim.toUpperCase()}</span>
                                  <div style={{ fontSize: "12px", opacity: "0.5" }}>
                                    {checkIsValid(claimObj.rating) === "success" && "✦ LIKELY TRUE ✦"}
                                    {checkIsValid(claimObj.rating) === "danger" && "⚠ INACCURATE ⚠"}
                                    {checkIsValid(claimObj.rating) === "warning" && "♺ MIXED ♺"}
                                  </div>
                                </Badge>
                              )}
                              {/*{claimObj.rating && <Badge>{claimObj.ratingText.toUpperCase()}</Badge>}*/}
                            </div>
                          ))}
                        </div>
                      )}
                    </ModalHeader>
                    <ModalBody
                      // style={{ overflow: "auto", maxHeight: "350px", fontSize: "14px", fontFamily: "Ropa Sans" }}
                      style={{
                        overflow: "auto",
                        maxHeight: "350px",
                        // fontSize: "18px",
                        // lineHeight: "1.4",
                        fontFamily: "PT sans"
                        // fontFamily: "Ropa Sans"
                      }}
                      className="text-justify"
                    >
                      {text.map((paragraph, indexParagraph) => (
                        <div key={indexParagraph + "sectionObjText1"}>
                          {parse(DOMPurify.sanitize(replaceSubscript(paragraph)))}
                          {/*{parse(DOMPurify.sanitize(paragraph))}*/}
                          {indexParagraph + 1 < text.length && (
                            <hr
                              style={{
                                maxWidth: "70%"
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" className="btn-sm btn-block" onClick={toggle}>
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/*-----------------------END MODAL-------------------------*/}
                  {/*-----------------------END MODAL-------------------------*/}
                  {/*-----------------------END MODAL-------------------------*/}
                </div>
              </div>
            ) : (
              <div
                className="gentle-outline p-1 mb-0 mx-3"
                style={{
                  fontSize: "1.2em",
                  fontFamily: "Ropa Sans",
                  lineHeight: "1.3"
                }}
              >
                {/*<b>{parse(DOMPurify.sanitize(summary))}</b>*/}
                {parse(DOMPurify.sanitize(summary))}
              </div>
            )}

            {/*{active === _id && (*/}
            {/*  <div*/}
            {/*    className="mx-0 mb-3"*/}
            {/*    style={{ fontSize: "16px" }}*/}
            {/*  >*/}
            {/*    {text.map((paragraph, indexParagraph) => (*/}
            {/*      <div*/}
            {/*        className="px-3"*/}
            {/*        // style={{ fontFamily: "Roboto, sans-serif"}}*/}
            {/*        key={indexParagraph + "sectionObjText"}*/}
            {/*      >*/}
            {/*        {parse(DOMPurify.sanitize(replaceSubscript(paragraph)))}*/}
            {/*        {indexParagraph + 1 < text.length && (*/}
            {/*          <hr*/}
            {/*            style={{*/}
            {/*              maxWidth: "50%"*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        )}*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        )}
      </CardBody>

      {/*<CardFooter*/}
      {/*  // className="footer-special"*/}
      {/*>*/}
      {citations && (
        <div>
          {citations.length > 0 && (
            <CardFooter className="footer-special">
              {showCitations ? (
                <div>
                  {/*<h6>Sources</h6>*/}
                  {citations.map((gg, i) => {
                    return (
                      <div key={_id + (i + 1) * 3}>
                        {gg.number}.{" "}
                        <a href={gg.link} target="_blank" rel="noopener noreferrer">
                          {gg.citation.length > 1 ? (
                            <span>{gg.citation.slice(0, 20)}...</span>
                          ) : (
                            <span>{gg.link.slice(0, 20)}...</span>
                          )}
                        </a>
                        {/*// ORIGINAL BEFORE CONVERTING TO OBJECTS //*/}
                        {/*{i + 1}.{" "}*/}
                        {/*<a href={gg} target="_blank" rel="noopener noreferrer" >*/}
                        {/*  {gg.slice(0, 30)}...*/}
                        {/*</a>*/}
                      </div>
                    );
                  })}
                  <Button block className="btn-light" onClick={() => setShowCitations(!showCitations)}>
                    Hide References
                  </Button>
                </div>
              ) : (
                <Button block className="btn-light m-0" onClick={() => setShowCitations(_id)}>
                  Show References
                </Button>
              )}
            </CardFooter>
          )}
        </div>
      )}
      {/*</CardFooter>*/}
    </div>
  );
};

export default SubTopicItem;
