import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Form } from "reactstrap";
import axios from "axios";

const AddTopicGroup = ({ topicId, setTopic }) => {
  const { register, watch, handleSubmit, reset, formState } = useForm(); // initialise the hook
  const [response, setResponse] = useState(null);
  const addImage = watch("addImage");

  // useEffect to clear response message
  useEffect(
    () => {
      const timer = setTimeout(() => {
        setResponse(null);
      }, 10000);
      return () => clearTimeout(timer);
    },
    [response]
  );

  const onSubmit = async data => {
    delete data.addImage;
    console.log(data);

    const joe = await axios
      .post(`/api/topics/${topicId}/topic-group`, data)
      .then(res => {
        setResponse(res.data);
        return { success: true, updatedTopic: res.data };
      })
      .catch(err => {
        setResponse(err);
        console.log("wtf " + err);
        return { success: false, error: err };
      });

    if(joe.success) {
      setTopic(joe.updatedTopic);
      reset();
    }
  };

  return (
    // <Col md="6">
    <div className="gentle-outline-x">
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <h4>
          Add <b>Topic Groups</b>
        </h4>
        {/*<input*/}
        {/*  className="form-control"*/}
        {/*  name="category"*/}
        {/*  placeholder="parent category"*/}
        {/*  ref={register}*/}
        {/*/>*/}
        {/*<input*/}
        {/*  className="form-control"*/}
        {/*  name="parentTopicTitle"*/}
        {/*  placeholder="parent topic to be added to"*/}
        {/*  ref={register}*/}
        {/*/>*/}
        <input
          className="form-control"
          name="title"
          placeholder="topic group title"
          ref={register({ required: true })}
        />

        <div className="gentle-outline mt-3">
          <div>
            Add Image? <input name="addImage" type="checkbox" ref={register} />
          </div>

          {addImage && (
            <input
              className="form-control"
              name="image"
              placeholder="subtopic image"
              ref={register}
            />
          )}
        </div>

        <input type="submit" disabled={!formState.dirty || formState.isSubmitting} className="btn btn-dark mt-1" />
      </Form>
      <br />
      {response && (
        <Card className="gentle-border">
          <h3>Response</h3>
          {JSON.stringify(response)}
        </Card>
      )}
    </div>
    // </Col>
  );
};

export default AddTopicGroup;
