import React, {
  useState,
  // useEffect, useRef
} from "react";
import { Button, Input, Row, Col, Card } from "reactstrap";
import _ from "lodash";
import { Link } from "react-router-dom";
import { hyphen } from "../../Utilities/emdash";
// import moment from "moment";

const cities = [
  "Autauga, Alabama, US",
  "Baldwin, Alabama, US",
  "Barbour, Alabama, US",
  "Bibb, Alabama, US",
  "Blount, Alabama, US",
  "Bullock, Alabama, US",
  "Butler, Alabama, US",
  "Calhoun, Alabama, US",
  "Chambers, Alabama, US",
  "Cherokee, Alabama, US",
  "Chilton, Alabama, US",
  "Choctaw, Alabama, US",
  "Clarke, Alabama, US",
  "Clay, Alabama, US",
  "Cleburne, Alabama, US",
  "Coffee, Alabama, US",
  "Colbert, Alabama, US",
  "Conecuh, Alabama, US",
  "Coosa, Alabama, US",
  "Covington, Alabama, US",
  "Crenshaw, Alabama, US",
  "Cullman, Alabama, US",
  "Dale, Alabama, US",
  "Dallas, Alabama, US",
  "DeKalb, Alabama, US",
  "Elmore, Alabama, US",
  "Escambia, Alabama, US",
  "Etowah, Alabama, US",
  "Fayette, Alabama, US",
  "Franklin, Alabama, US",
  "Geneva, Alabama, US",
  "Greene, Alabama, US",
  "Hale, Alabama, US",
  "Henry, Alabama, US",
  "Houston, Alabama, US",
  "Jackson, Alabama, US",
  "Jefferson, Alabama, US",
  "Lamar, Alabama, US",
  "Lauderdale, Alabama, US",
  "Lawrence, Alabama, US",
  "Lee, Alabama, US",
  "Limestone, Alabama, US",
  "Lowndes, Alabama, US",
  "Macon, Alabama, US",
  "Madison, Alabama, US",
  "Marengo, Alabama, US",
  "Marion, Alabama, US",
  "Marshall, Alabama, US",
  "Mobile, Alabama, US",
  "Monroe, Alabama, US",
  "Montgomery, Alabama, US",
  "Morgan, Alabama, US",
  "Perry, Alabama, US",
  "Pickens, Alabama, US",
  "Pike, Alabama, US",
  "Randolph, Alabama, US",
  "Russell, Alabama, US",
  "St. Clair, Alabama, US",
  "Shelby, Alabama, US",
  "Sumter, Alabama, US",
  "Talladega, Alabama, US",
  "Tallapoosa, Alabama, US",
  "Tuscaloosa, Alabama, US",
  "Walker, Alabama, US",
  "Washington, Alabama, US",
  "Wilcox, Alabama, US",
  "Winston, Alabama, US",
  "Aleutians East, Alaska, US",
  "Aleutians West, Alaska, US",
  "Anchorage,Alaska,US",
  "Bethel, Alaska, US",
  "Bristol Bay, Alaska, US",
  "Denali, Alaska, US",
  "Dillingham, Alaska, US",
  "Fairbanks North Star,Alaska,US",
  "Haines, Alaska, US",
  "Hoonah-Angoon, Alaska, US",
  "Juneau, Alaska, US",
  "Kenai Peninsula,Alaska,US",
  "Ketchikan Gateway, Alaska, US",
  "Kodiak Island, Alaska, US",
  "Kusilvak, Alaska, US",
  "Lake and Peninsula, Alaska, US",
  "Matanuska-Susitna,Alaska,US",
  "Nome, Alaska, US",
  "North Slope, Alaska, US",
  "Northwest Arctic, Alaska, US",
  "Petersburg, Alaska, US",
  "Prince of Wales-Hyder, Alaska, US",
  "Sitka, Alaska, US",
  "Skagway, Alaska, US",
  "Southeast Fairbanks, Alaska, US",
  "Valdez-Cordova, Alaska, US",
  "Wrangell, Alaska, US",
  "Yakutat, Alaska, US",
  "Yukon-Koyukuk, Alaska, US",
  "Apache, Arizona, US",
  "Cochise, Arizona, US",
  "Coconino, Arizona, US",
  "Gila, Arizona, US",
  "Graham, Arizona, US",
  "Greenlee, Arizona, US",
  "La Paz, Arizona, US",
  "Maricopa, Arizona, US",
  "Mohave, Arizona, US",
  "Navajo, Arizona, US",
  "Pima, Arizona, US",
  "Pinal, Arizona, US",
  "Santa Cruz, Arizona, US",
  "Yavapai, Arizona, US",
  "Yuma, Arizona, US",
  "Arkansas, Arkansas, US",
  "Ashley, Arkansas, US",
  "Baxter, Arkansas, US",
  "Benton, Arkansas, US",
  "Boone, Arkansas, US",
  "Bradley, Arkansas, US",
  "Calhoun, Arkansas, US",
  "Carroll, Arkansas, US",
  "Chicot, Arkansas, US",
  "Clark, Arkansas, US",
  "Clay, Arkansas, US",
  "Cleburne, Arkansas, US",
  "Cleveland, Arkansas, US",
  "Columbia, Arkansas, US",
  "Conway, Arkansas, US",
  "Craighead, Arkansas, US",
  "Crawford, Arkansas, US",
  "Crittenden, Arkansas, US",
  "Cross, Arkansas, US",
  "Dallas, Arkansas, US",
  "Desha, Arkansas, US",
  "Drew, Arkansas, US",
  "Faulkner, Arkansas, US",
  "Franklin, Arkansas, US",
  "Fulton, Arkansas, US",
  "Garland, Arkansas, US",
  "Grant, Arkansas, US",
  "Greene, Arkansas, US",
  "Hempstead, Arkansas, US",
  "Hot Spring, Arkansas, US",
  "Howard, Arkansas, US",
  "Independence, Arkansas, US",
  "Izard, Arkansas, US",
  "Jackson, Arkansas, US",
  "Jefferson, Arkansas, US",
  "Johnson, Arkansas, US",
  "Lafayette, Arkansas, US",
  "Lawrence, Arkansas, US",
  "Lee, Arkansas, US",
  "Lincoln, Arkansas, US",
  "Little River, Arkansas, US",
  "Logan, Arkansas, US",
  "Lonoke, Arkansas, US",
  "Madison, Arkansas, US",
  "Marion, Arkansas, US",
  "Miller, Arkansas, US",
  "Mississippi, Arkansas, US",
  "Monroe, Arkansas, US",
  "Montgomery, Arkansas, US",
  "Nevada, Arkansas, US",
  "Newton, Arkansas, US",
  "Ouachita, Arkansas, US",
  "Perry, Arkansas, US",
  "Phillips, Arkansas, US",
  "Pike, Arkansas, US",
  "Poinsett, Arkansas, US",
  "Polk, Arkansas, US",
  "Pope, Arkansas, US",
  "Prairie, Arkansas, US",
  "Pulaski, Arkansas, US",
  "Randolph, Arkansas, US",
  "St. Francis, Arkansas, US",
  "Saline, Arkansas, US",
  "Scott, Arkansas, US",
  "Searcy, Arkansas, US",
  "Sebastian, Arkansas, US",
  "Sevier, Arkansas, US",
  "Sharp, Arkansas, US",
  "Stone, Arkansas, US",
  "Union, Arkansas, US",
  "Van Buren, Arkansas, US",
  "Washington, Arkansas, US",
  "White, Arkansas, US",
  "Woodruff, Arkansas, US",
  "Yell, Arkansas, US",
  "Alameda, California, US",
  "Alpine, California, US",
  "Amador, California, US",
  "Butte, California, US",
  "Calaveras, California, US",
  "Colusa, California, US",
  "Contra Costa, California, US",
  "Del Norte, California, US",
  "El Dorado, California, US",
  "Fresno, California, US",
  "Glenn, California, US",
  "Humboldt, California, US",
  "Imperial, California, US",
  "Inyo, California, US",
  "Kern, California, US",
  "Kings, California, US",
  "Lake, California, US",
  "Lassen, California, US",
  "Los Angeles, California, US",
  "Madera, California, US",
  "Marin, California, US",
  "Mariposa, California, US",
  "Mendocino, California, US",
  "Merced, California, US",
  "Modoc, California, US",
  "Mono, California, US",
  "Monterey, California, US",
  "Napa, California, US",
  "Nevada, California, US",
  "Orange, California, US",
  "Placer, California, US",
  "Plumas, California, US",
  "Riverside, California, US",
  "Sacramento, California, US",
  "San Benito, California, US",
  "San Bernardino, California, US",
  "San Diego, California, US",
  "San Francisco, California, US",
  "San Joaquin, California, US",
  "San Luis Obispo, California, US",
  "San Mateo, California, US",
  "Santa Barbara, California, US",
  "Santa Clara, California, US",
  "Santa Cruz, California, US",
  "Shasta, California, US",
  "Sierra, California, US",
  "Siskiyou, California, US",
  "Solano, California, US",
  "Sonoma, California, US",
  "Stanislaus, California, US",
  "Sutter, California, US",
  "Tehama, California, US",
  "Trinity, California, US",
  "Tulare, California, US",
  "Tuolumne, California, US",
  "Ventura, California, US",
  "Yolo, California, US",
  "Yuba, California, US",
  "Adams, Colorado, US",
  "Alamosa, Colorado, US",
  "Arapahoe, Colorado, US",
  "Archuleta, Colorado, US",
  "Baca, Colorado, US",
  "Bent, Colorado, US",
  "Boulder, Colorado, US",
  "Broomfield, Colorado, US",
  "Chaffee, Colorado, US",
  "Cheyenne, Colorado, US",
  "Clear Creek, Colorado, US",
  "Conejos, Colorado, US",
  "Costilla, Colorado, US",
  "Crowley, Colorado, US",
  "Custer, Colorado, US",
  "Delta, Colorado, US",
  "Denver, Colorado, US",
  "Dolores, Colorado, US",
  "Douglas, Colorado, US",
  "Eagle, Colorado, US",
  "Elbert, Colorado, US",
  "El Paso, Colorado, US",
  "Fremont, Colorado, US",
  "Garfield, Colorado, US",
  "Gilpin, Colorado, US",
  "Grand, Colorado, US",
  "Gunnison, Colorado, US",
  "Hinsdale, Colorado, US",
  "Huerfano, Colorado, US",
  "Jackson, Colorado, US",
  "Jefferson, Colorado, US",
  "Kiowa, Colorado, US",
  "Kit Carson, Colorado, US",
  "Lake, Colorado, US",
  "La Plata, Colorado, US",
  "Larimer, Colorado, US",
  "Las Animas, Colorado, US",
  "Lincoln, Colorado, US",
  "Logan, Colorado, US",
  "Mesa, Colorado, US",
  "Mineral, Colorado, US",
  "Moffat, Colorado, US",
  "Montezuma, Colorado, US",
  "Montrose, Colorado, US",
  "Morgan, Colorado, US",
  "Otero, Colorado, US",
  "Ouray, Colorado, US",
  "Park, Colorado, US",
  "Phillips, Colorado, US",
  "Pitkin, Colorado, US",
  "Prowers, Colorado, US",
  "Pueblo, Colorado, US",
  "Rio Blanco, Colorado, US",
  "Rio Grande, Colorado, US",
  "Routt, Colorado, US",
  "Saguache, Colorado, US",
  "San Juan, Colorado, US",
  "San Miguel, Colorado, US",
  "Sedgwick, Colorado, US",
  "Summit, Colorado, US",
  "Teller, Colorado, US",
  "Washington, Colorado, US",
  "Weld, Colorado, US",
  "Yuma, Colorado, US",
  "Fairfield, Connecticut, US",
  "Hartford, Connecticut, US",
  "Litchfield, Connecticut, US",
  "Middlesex, Connecticut, US",
  "New Haven, Connecticut, US",
  "New London, Connecticut, US",
  "Tolland, Connecticut, US",
  "Windham, Connecticut, US",
  "Kent, Delaware, US",
  "New Castle, Delaware, US",
  "Sussex, Delaware, US",
  "District of Columbia,District of Columbia,US",
  "Alachua, Florida, US",
  "Baker, Florida, US",
  "Bay, Florida, US",
  "Bradford, Florida, US",
  "Brevard, Florida, US",
  "Broward, Florida, US",
  "Calhoun, Florida, US",
  "Charlotte, Florida, US",
  "Citrus, Florida, US",
  "Clay, Florida, US",
  "Collier, Florida, US",
  "Columbia, Florida, US",
  "DeSoto,Florida,US",
  "Dixie, Florida, US",
  "Duval, Florida, US",
  "Escambia, Florida, US",
  "Flagler, Florida, US",
  "Franklin, Florida, US",
  "Gadsden, Florida, US",
  "Gilchrist, Florida, US",
  "Glades, Florida, US",
  "Gulf, Florida, US",
  "Hamilton, Florida, US",
  "Hardee, Florida, US",
  "Hendry, Florida, US",
  "Hernando, Florida, US",
  "Highlands, Florida, US",
  "Hillsborough, Florida, US",
  "Holmes, Florida, US",
  "Indian River, Florida, US",
  "Jackson, Florida, US",
  "Jefferson, Florida, US",
  "Lafayette, Florida, US",
  "Lake, Florida, US",
  "Lee, Florida, US",
  "Leon, Florida, US",
  "Levy, Florida, US",
  "Liberty, Florida, US",
  "Madison, Florida, US",
  "Manatee, Florida, US",
  "Marion, Florida, US",
  "Martin, Florida, US",
  "Miami-Dade, Florida, US",
  "Monroe, Florida, US",
  "Nassau, Florida, US",
  "Okaloosa, Florida, US",
  "Okeechobee, Florida, US",
  "Orange, Florida, US",
  "Osceola, Florida, US",
  "Palm Beach, Florida, US",
  "Pasco, Florida, US",
  "Pinellas, Florida, US",
  "Polk, Florida, US",
  "Putnam, Florida, US",
  "St. Johns, Florida, US",
  "St. Lucie, Florida, US",
  "Santa Rosa, Florida, US",
  "Sarasota, Florida, US",
  "Seminole, Florida, US",
  "Sumter, Florida, US",
  "Suwannee, Florida, US",
  "Taylor, Florida, US",
  "Union, Florida, US",
  "Volusia, Florida, US",
  "Wakulla, Florida, US",
  "Walton, Florida, US",
  "Washington, Florida, US",
  "Appling, Georgia, US",
  "Atkinson, Georgia, US",
  "Bacon, Georgia, US",
  "Baker, Georgia, US",
  "Baldwin, Georgia, US",
  "Banks, Georgia, US",
  "Barrow, Georgia, US",
  "Bartow, Georgia, US",
  "Ben Hill, Georgia, US",
  "Berrien, Georgia, US",
  "Bibb, Georgia, US",
  "Bleckley, Georgia, US",
  "Brantley, Georgia, US",
  "Brooks, Georgia, US",
  "Bryan, Georgia, US",
  "Bulloch, Georgia, US",
  "Burke, Georgia, US",
  "Butts, Georgia, US",
  "Calhoun, Georgia, US",
  "Camden, Georgia, US",
  "Candler, Georgia, US",
  "Carroll, Georgia, US",
  "Catoosa, Georgia, US",
  "Charlton, Georgia, US",
  "Chatham, Georgia, US",
  "Chattahoochee, Georgia, US",
  "Chattooga, Georgia, US",
  "Cherokee, Georgia, US",
  "Clarke, Georgia, US",
  "Clay, Georgia, US",
  "Clayton, Georgia, US",
  "Clinch, Georgia, US",
  "Cobb, Georgia, US",
  "Coffee, Georgia, US",
  "Colquitt, Georgia, US",
  "Columbia, Georgia, US",
  "Cook, Georgia, US",
  "Coweta, Georgia, US",
  "Crawford, Georgia, US",
  "Crisp, Georgia, US",
  "Dade, Georgia, US",
  "Dawson, Georgia, US",
  "Decatur, Georgia, US",
  "DeKalb, Georgia, US",
  "Dodge, Georgia, US",
  "Dooly, Georgia, US",
  "Dougherty, Georgia, US",
  "Douglas, Georgia, US",
  "Early, Georgia, US",
  "Echols, Georgia, US",
  "Effingham, Georgia, US",
  "Elbert, Georgia, US",
  "Emanuel, Georgia, US",
  "Evans, Georgia, US",
  "Fannin, Georgia, US",
  "Fayette, Georgia, US",
  "Floyd, Georgia, US",
  "Forsyth, Georgia, US",
  "Franklin, Georgia, US",
  "Fulton, Georgia, US",
  "Gilmer, Georgia, US",
  "Glascock, Georgia, US",
  "Glynn, Georgia, US",
  "Gordon, Georgia, US",
  "Grady, Georgia, US",
  "Greene, Georgia, US",
  "Gwinnett, Georgia, US",
  "Habersham, Georgia, US",
  "Hall, Georgia, US",
  "Hancock, Georgia, US",
  "Haralson, Georgia, US",
  "Harris, Georgia, US",
  "Hart, Georgia, US",
  "Heard, Georgia, US",
  "Henry, Georgia, US",
  "Houston, Georgia, US",
  "Irwin, Georgia, US",
  "Jackson, Georgia, US",
  "Jasper, Georgia, US",
  "Jeff Davis, Georgia, US",
  "Jefferson, Georgia, US",
  "Jenkins, Georgia, US",
  "Johnson, Georgia, US",
  "Jones, Georgia, US",
  "Lamar, Georgia, US",
  "Lanier, Georgia, US",
  "Laurens, Georgia, US",
  "Lee, Georgia, US",
  "Liberty, Georgia, US",
  "Lincoln, Georgia, US",
  "Long, Georgia, US",
  "Lowndes, Georgia, US",
  "Lumpkin, Georgia, US",
  "McDuffie,Georgia,US",
  "McIntosh, Georgia, US",
  "Macon, Georgia, US",
  "Madison, Georgia, US",
  "Marion, Georgia, US",
  "Meriwether, Georgia, US",
  "Miller, Georgia, US",
  "Mitchell, Georgia, US",
  "Monroe, Georgia, US",
  "Montgomery, Georgia, US",
  "Morgan, Georgia, US",
  "Murray, Georgia, US",
  "Muscogee, Georgia, US",
  "Newton, Georgia, US",
  "Oconee, Georgia, US",
  "Oglethorpe, Georgia, US",
  "Paulding, Georgia, US",
  "Peach, Georgia, US",
  "Pickens, Georgia, US",
  "Pierce, Georgia, US",
  "Pike, Georgia, US",
  "Polk, Georgia, US",
  "Pulaski, Georgia, US",
  "Putnam, Georgia, US",
  "Quitman, Georgia, US",
  "Rabun, Georgia, US",
  "Randolph, Georgia, US",
  "Richmond, Georgia, US",
  "Rockdale, Georgia, US",
  "Schley, Georgia, US",
  "Screven, Georgia, US",
  "Seminole, Georgia, US",
  "Spalding, Georgia, US",
  "Stephens, Georgia, US",
  "Stewart, Georgia, US",
  "Sumter, Georgia, US",
  "Talbot, Georgia, US",
  "Taliaferro, Georgia, US",
  "Tattnall, Georgia, US",
  "Taylor, Georgia, US",
  "Telfair, Georgia, US",
  "Terrell, Georgia, US",
  "Thomas, Georgia, US",
  "Tift, Georgia, US",
  "Toombs, Georgia, US",
  "Towns, Georgia, US",
  "Treutlen, Georgia, US",
  "Troup, Georgia, US",
  "Turner, Georgia, US",
  "Twiggs, Georgia, US",
  "Union, Georgia, US",
  "Upson, Georgia, US",
  "Walker, Georgia, US",
  "Walton, Georgia, US",
  "Ware, Georgia, US",
  "Warren, Georgia, US",
  "Washington, Georgia, US",
  "Wayne, Georgia, US",
  "Webster, Georgia, US",
  "Wheeler, Georgia, US",
  "White, Georgia, US",
  "Whitfield, Georgia, US",
  "Wilcox, Georgia, US",
  "Wilkes, Georgia, US",
  "Wilkinson, Georgia, US",
  "Worth, Georgia, US",
  "Hawaii, Hawaii, US",
  "Honolulu, Hawaii, US",
  "Kalawao, Hawaii, US",
  "Kauai, Hawaii, US",
  "Maui, Hawaii, US",
  "Ada, Idaho, US",
  "Adams, Idaho, US",
  "Bannock, Idaho, US",
  "Bear Lake, Idaho, US",
  "Benewah, Idaho, US",
  "Bingham, Idaho, US",
  "Blaine, Idaho, US",
  "Boise, Idaho, US",
  "Bonner, Idaho, US",
  "Bonneville, Idaho, US",
  "Boundary, Idaho, US",
  "Butte, Idaho, US",
  "Camas, Idaho, US",
  "Canyon, Idaho, US",
  "Caribou, Idaho, US",
  "Cassia, Idaho, US",
  "Clark, Idaho, US",
  "Clearwater, Idaho, US",
  "Custer, Idaho, US",
  "Elmore, Idaho, US",
  "Franklin, Idaho, US",
  "Fremont, Idaho, US",
  "Gem, Idaho, US",
  "Gooding, Idaho, US",
  "Idaho, Idaho, US",
  "Jefferson, Idaho, US",
  "Jerome, Idaho, US",
  "Kootenai, Idaho, US",
  "Latah, Idaho, US",
  "Lemhi, Idaho, US",
  "Lewis, Idaho, US",
  "Lincoln, Idaho, US",
  "Madison, Idaho, US",
  "Minidoka, Idaho, US",
  "Nez Perce, Idaho, US",
  "Oneida, Idaho, US",
  "Owyhee, Idaho, US",
  "Payette, Idaho, US",
  "Power, Idaho, US",
  "Shoshone, Idaho, US",
  "Teton, Idaho, US",
  "Twin Falls, Idaho, US",
  "Valley, Idaho, US",
  "Washington, Idaho, US",
  "Adams, Illinois, US",
  "Alexander, Illinois, US",
  "Bond, Illinois, US",
  "Boone, Illinois, US",
  "Brown, Illinois, US",
  "Bureau, Illinois, US",
  "Calhoun, Illinois, US",
  "Carroll, Illinois, US",
  "Cass, Illinois, US",
  "Champaign, Illinois, US",
  "Christian, Illinois, US",
  "Clark, Illinois, US",
  "Clay, Illinois, US",
  "Clinton, Illinois, US",
  "Coles, Illinois, US",
  "Cook, Illinois, US",
  "Crawford, Illinois, US",
  "Cumberland, Illinois, US",
  "DeKalb, Illinois, US",
  "De Witt, Illinois, US",
  "Douglas, Illinois, US",
  "DuPage, Illinois, US",
  "Edgar, Illinois, US",
  "Edwards, Illinois, US",
  "Effingham, Illinois, US",
  "Fayette, Illinois, US",
  "Ford, Illinois, US",
  "Franklin, Illinois, US",
  "Fulton, Illinois, US",
  "Gallatin, Illinois, US",
  "Greene, Illinois, US",
  "Grundy, Illinois, US",
  "Hamilton, Illinois, US",
  "Hancock, Illinois, US",
  "Hardin, Illinois, US",
  "Henderson, Illinois, US",
  "Henry, Illinois, US",
  "Iroquois, Illinois, US",
  "Jackson, Illinois, US",
  "Jasper, Illinois, US",
  "Jefferson, Illinois, US",
  "Jersey, Illinois, US",
  "Jo Daviess, Illinois, US",
  "Johnson, Illinois, US",
  "Kane, Illinois, US",
  "Kankakee, Illinois, US",
  "Kendall, Illinois, US",
  "Knox, Illinois, US",
  "Lake, Illinois, US",
  "LaSalle,Illinois,US",
  "Lawrence, Illinois, US",
  "Lee, Illinois, US",
  "Livingston, Illinois, US",
  "Logan, Illinois, US",
  "McDonough, Illinois, US",
  "McHenry, Illinois, US",
  "McLean, Illinois, US",
  "Macon, Illinois, US",
  "Macoupin, Illinois, US",
  "Madison, Illinois, US",
  "Marion, Illinois, US",
  "Marshall, Illinois, US",
  "Mason, Illinois, US",
  "Massac, Illinois, US",
  "Menard, Illinois, US",
  "Mercer, Illinois, US",
  "Monroe, Illinois, US",
  "Montgomery, Illinois, US",
  "Morgan, Illinois, US",
  "Moultrie, Illinois, US",
  "Ogle, Illinois, US",
  "Peoria, Illinois, US",
  "Perry, Illinois, US",
  "Piatt, Illinois, US",
  "Pike, Illinois, US",
  "Pope, Illinois, US",
  "Pulaski, Illinois, US",
  "Putnam, Illinois, US",
  "Randolph, Illinois, US",
  "Richland, Illinois, US",
  "Rock Island, Illinois, US",
  "St. Clair, Illinois, US",
  "Saline, Illinois, US",
  "Sangamon, Illinois, US",
  "Schuyler, Illinois, US",
  "Scott, Illinois, US",
  "Shelby, Illinois, US",
  "Stark, Illinois, US",
  "Stephenson, Illinois, US",
  "Tazewell, Illinois, US",
  "Union, Illinois, US",
  "Vermilion, Illinois, US",
  "Wabash, Illinois, US",
  "Warren, Illinois, US",
  "Washington, Illinois, US",
  "Wayne, Illinois, US",
  "White, Illinois, US",
  "Whiteside, Illinois, US",
  "Will, Illinois, US",
  "Williamson, Illinois, US",
  "Winnebago, Illinois, US",
  "Woodford, Illinois, US",
  "Adams, Indiana, US",
  "Allen, Indiana, US",
  "Bartholomew, Indiana, US",
  "Benton, Indiana, US",
  "Blackford, Indiana, US",
  "Boone, Indiana, US",
  "Brown, Indiana, US",
  "Carroll,Indiana,US",
  "Cass, Indiana, US",
  "Clark, Indiana, US",
  "Clay, Indiana, US",
  "Clinton, Indiana, US",
  "Crawford, Indiana, US",
  "Daviess, Indiana, US",
  "Dearborn, Indiana, US",
  "Decatur, Indiana, US",
  "DeKalb, Indiana, US",
  "Delaware, Indiana, US",
  "Dubois, Indiana, US",
  "Elkhart, Indiana, US",
  "Fayette, Indiana, US",
  "Floyd, Indiana, US",
  "Fountain, Indiana, US",
  "Franklin, Indiana, US",
  "Fulton, Indiana, US",
  "Gibson, Indiana, US",
  "Grant, Indiana, US",
  "Greene, Indiana, US",
  "Hamilton, Indiana, US",
  "Hancock, Indiana, US",
  "Harrison, Indiana, US",
  "Hendricks, Indiana, US",
  "Henry, Indiana, US",
  "Howard, Indiana, US",
  "Huntington, Indiana, US",
  "Jackson, Indiana, US",
  "Jasper, Indiana, US",
  "Jay, Indiana, US",
  "Jefferson, Indiana, US",
  "Jennings, Indiana, US",
  "Johnson, Indiana, US",
  "Knox, Indiana, US",
  "Kosciusko, Indiana, US",
  "LaGrange, Indiana, US",
  "Lake, Indiana, US",
  "LaPorte, Indiana, US",
  "Lawrence, Indiana, US",
  "Madison, Indiana, US",
  "Marion, Indiana, US",
  "Marshall, Indiana, US",
  "Martin, Indiana, US",
  "Miami, Indiana, US",
  "Monroe, Indiana, US",
  "Montgomery, Indiana, US",
  "Morgan, Indiana, US",
  "Newton, Indiana, US",
  "Noble, Indiana, US",
  "Ohio, Indiana, US",
  "Orange, Indiana, US",
  "Owen, Indiana, US",
  "Parke, Indiana, US",
  "Perry, Indiana, US",
  "Pike, Indiana, US",
  "Porter, Indiana, US",
  "Posey, Indiana, US",
  "Pulaski, Indiana, US",
  "Putnam, Indiana, US",
  "Randolph, Indiana, US",
  "Ripley, Indiana, US",
  "Rush, Indiana, US",
  "St. Joseph, Indiana, US",
  "Scott, Indiana, US",
  "Shelby, Indiana, US",
  "Spencer, Indiana, US",
  "Starke, Indiana, US",
  "Steuben, Indiana, US",
  "Sullivan, Indiana, US",
  "Switzerland, Indiana, US",
  "Tippecanoe, Indiana, US",
  "Tipton, Indiana, US",
  "Union, Indiana, US",
  "Vanderburgh, Indiana, US",
  "Vermillion, Indiana, US",
  "Vigo, Indiana, US",
  "Wabash, Indiana, US",
  "Warren, Indiana, US",
  "Warrick, Indiana, US",
  "Washington, Indiana, US",
  "Wayne, Indiana, US",
  "Wells, Indiana, US",
  "White, Indiana, US",
  "Whitley, Indiana, US",
  "Adair, Iowa, US",
  "Adams, Iowa, US",
  "Allamakee, Iowa, US",
  "Appanoose, Iowa, US",
  "Audubon, Iowa, US",
  "Benton, Iowa, US",
  "Black Hawk, Iowa, US",
  "Boone, Iowa, US",
  "Bremer, Iowa, US",
  "Buchanan, Iowa, US",
  "Buena Vista, Iowa, US",
  "Butler, Iowa, US",
  "Calhoun, Iowa, US",
  "Carroll, Iowa, US",
  "Cass, Iowa, US",
  "Cedar, Iowa, US",
  "Cerro Gordo, Iowa, US",
  "Cherokee, Iowa, US",
  "Chickasaw, Iowa, US",
  "Clarke, Iowa, US",
  "Clay, Iowa, US",
  "Clayton, Iowa, US",
  "Clinton, Iowa, US",
  "Crawford, Iowa, US",
  "Dallas, Iowa, US",
  "Davis, Iowa, US",
  "Decatur, Iowa, US",
  "Delaware, Iowa, US",
  "Des Moines, Iowa, US",
  "Dickinson, Iowa, US",
  "Dubuque, Iowa, US",
  "Emmet, Iowa, US",
  "Fayette, Iowa, US",
  "Floyd, Iowa, US",
  "Franklin, Iowa, US",
  "Fremont, Iowa, US",
  "Greene, Iowa, US",
  "Grundy, Iowa, US",
  "Guthrie, Iowa, US",
  "Hamilton, Iowa, US",
  "Hancock, Iowa, US",
  "Hardin, Iowa, US",
  "Harrison, Iowa, US",
  "Henry, Iowa, US",
  "Howard, Iowa, US",
  "Humboldt, Iowa, US",
  "Ida, Iowa, US",
  "Iowa, Iowa, US",
  "Jackson, Iowa, US",
  "Jasper, Iowa, US",
  "Jefferson, Iowa, US",
  "Johnson, Iowa, US",
  "Jones, Iowa, US",
  "Keokuk, Iowa, US",
  "Kossuth, Iowa, US",
  "Lee, Iowa, US",
  "Linn, Iowa, US",
  "Louisa, Iowa, US",
  "Lucas, Iowa, US",
  "Lyon, Iowa, US",
  "Madison, Iowa, US",
  "Mahaska, Iowa, US",
  "Marion, Iowa, US",
  "Marshall, Iowa, US",
  "Mills, Iowa, US",
  "Mitchell, Iowa, US",
  "Monona, Iowa, US",
  "Monroe, Iowa, US",
  "Montgomery, Iowa, US",
  "Muscatine, Iowa, US",
  "O'Brien, Iowa, US",
  "Osceola, Iowa, US",
  "Page, Iowa, US",
  "Palo Alto, Iowa, US",
  "Plymouth, Iowa, US",
  "Pocahontas, Iowa, US",
  "Polk, Iowa, US",
  "Pottawattamie, Iowa, US",
  "Poweshiek, Iowa, US",
  "Ringgold, Iowa, US",
  "Sac, Iowa, US",
  "Scott, Iowa, US",
  "Shelby, Iowa, US",
  "Sioux, Iowa, US",
  "Story, Iowa, US",
  "Tama, Iowa, US",
  "Taylor, Iowa, US",
  "Union, Iowa, US",
  "Van Buren, Iowa, US",
  "Wapello, Iowa, US",
  "Warren, Iowa, US",
  "Washington, Iowa, US",
  "Wayne, Iowa, US",
  "Webster, Iowa, US",
  "Winnebago, Iowa, US",
  "Winneshiek, Iowa, US",
  "Woodbury, Iowa, US",
  "Worth, Iowa, US",
  "Wright, Iowa, US",
  "Allen, Kansas, US",
  "Anderson, Kansas, US",
  "Atchison, Kansas, US",
  "Barber, Kansas, US",
  "Barton, Kansas, US",
  "Bourbon, Kansas, US",
  "Brown, Kansas, US",
  "Butler, Kansas, US",
  "Chase, Kansas, US",
  "Chautauqua, Kansas, US",
  "Cherokee, Kansas, US",
  "Cheyenne, Kansas, US",
  "Clark, Kansas, US",
  "Clay, Kansas, US",
  "Cloud, Kansas, US",
  "Coffey, Kansas, US",
  "Comanche, Kansas, US",
  "Cowley, Kansas, US",
  "Crawford, Kansas, US",
  "Decatur, Kansas, US",
  "Dickinson, Kansas, US",
  "Doniphan, Kansas, US",
  "Douglas, Kansas, US",
  "Edwards, Kansas, US",
  "Elk, Kansas, US",
  "Ellis, Kansas, US",
  "Ellsworth, Kansas, US",
  "Finney, Kansas, US",
  "Ford, Kansas, US",
  "Franklin, Kansas, US",
  "Geary, Kansas, US",
  "Gove, Kansas, US",
  "Graham, Kansas, US",
  "Grant, Kansas, US",
  "Gray, Kansas, US",
  "Greeley, Kansas, US",
  "Greenwood, Kansas, US",
  "Hamilton, Kansas, US",
  "Harper, Kansas, US",
  "Harvey, Kansas, US",
  "Haskell, Kansas, US",
  "Hodgeman, Kansas, US",
  "Jackson, Kansas, US",
  "Jefferson, Kansas, US",
  "Jewell, Kansas, US",
  "Johnson, Kansas, US",
  "Kearny, Kansas, US",
  "Kingman, Kansas, US",
  "Kiowa, Kansas, US",
  "Labette, Kansas, US",
  "Lane, Kansas, US",
  "Leavenworth, Kansas, US",
  "Lincoln, Kansas, US",
  "Linn, Kansas, US",
  "Logan, Kansas, US",
  "Lyon, Kansas, US",
  "McPherson, Kansas, US",
  "Marion, Kansas, US",
  "Marshall, Kansas, US",
  "Meade, Kansas, US",
  "Miami, Kansas, US",
  "Mitchell, Kansas, US",
  "Montgomery, Kansas, US",
  "Morris, Kansas, US",
  "Morton, Kansas, US",
  "Nemaha, Kansas, US",
  "Neosho, Kansas, US",
  "Ness, Kansas, US",
  "Norton, Kansas, US",
  "Osage, Kansas, US",
  "Osborne, Kansas, US",
  "Ottawa, Kansas, US",
  "Pawnee, Kansas, US",
  "Phillips, Kansas, US",
  "Pottawatomie, Kansas, US",
  "Pratt, Kansas, US",
  "Rawlins, Kansas, US",
  "Reno, Kansas, US",
  "Republic, Kansas, US",
  "Rice, Kansas, US",
  "Riley, Kansas, US",
  "Rooks, Kansas, US",
  "Rush, Kansas, US",
  "Russell, Kansas, US",
  "Saline, Kansas, US",
  "Scott, Kansas, US",
  "Sedgwick, Kansas, US",
  "Seward, Kansas, US",
  "Shawnee, Kansas, US",
  "Sheridan, Kansas, US",
  "Sherman, Kansas, US",
  "Smith, Kansas, US",
  "Stafford, Kansas, US",
  "Stanton, Kansas, US",
  "Stevens, Kansas, US",
  "Sumner, Kansas, US",
  "Thomas, Kansas, US",
  "Trego, Kansas, US",
  "Wabaunsee, Kansas, US",
  "Wallace, Kansas, US",
  "Washington, Kansas, US",
  "Wichita, Kansas, US",
  "Wilson, Kansas, US",
  "Woodson, Kansas, US",
  "Wyandotte, Kansas, US",
  "Adair, Kentucky, US",
  "Allen, Kentucky, US",
  "Anderson, Kentucky, US",
  "Ballard, Kentucky, US",
  "Barren, Kentucky, US",
  "Bath, Kentucky, US",
  "Bell, Kentucky, US",
  "Boone, Kentucky, US",
  "Bourbon, Kentucky, US",
  "Boyd, Kentucky, US",
  "Boyle, Kentucky, US",
  "Bracken, Kentucky, US",
  "Breathitt, Kentucky, US",
  "Breckinridge, Kentucky, US",
  "Bullitt, Kentucky, US",
  "Butler, Kentucky, US",
  "Caldwell, Kentucky, US",
  "Calloway, Kentucky, US",
  "Campbell, Kentucky, US",
  "Carlisle, Kentucky, US",
  "Carroll, Kentucky, US",
  "Carter, Kentucky, US",
  "Casey, Kentucky, US",
  "Christian, Kentucky, US",
  "Clark, Kentucky, US",
  "Clay, Kentucky, US",
  "Clinton, Kentucky, US",
  "Crittenden, Kentucky, US",
  "Cumberland, Kentucky, US",
  "Daviess, Kentucky, US",
  "Edmonson, Kentucky, US",
  "Elliott, Kentucky, US",
  "Estill, Kentucky, US",
  "Fayette, Kentucky, US",
  "Fleming, Kentucky, US",
  "Floyd, Kentucky, US",
  "Franklin, Kentucky, US",
  "Fulton, Kentucky, US",
  "Gallatin, Kentucky, US",
  "Garrard, Kentucky, US",
  "Grant, Kentucky, US",
  "Graves, Kentucky, US",
  "Grayson, Kentucky, US",
  "Green, Kentucky, US",
  "Greenup, Kentucky, US",
  "Hancock, Kentucky, US",
  "Hardin, Kentucky, US",
  "Harlan, Kentucky, US",
  "Harrison, Kentucky, US",
  "Hart, Kentucky, US",
  "Henderson, Kentucky, US",
  "Henry, Kentucky, US",
  "Hickman, Kentucky, US",
  "Hopkins, Kentucky, US",
  "Jackson, Kentucky, US",
  "Jefferson, Kentucky, US",
  "Jessamine, Kentucky, US",
  "Johnson, Kentucky, US",
  "Kenton, Kentucky, US",
  "Knott, Kentucky, US",
  "Knox, Kentucky, US",
  "Larue, Kentucky, US",
  "Laurel, Kentucky, US",
  "Lawrence, Kentucky, US",
  "Lee, Kentucky, US",
  "Leslie, Kentucky, US",
  "Letcher, Kentucky, US",
  "Lewis, Kentucky, US",
  "Lincoln, Kentucky, US",
  "Livingston, Kentucky, US",
  "Logan, Kentucky, US",
  "Lyon, Kentucky, US",
  "McCracken, Kentucky, US",
  "McCreary, Kentucky, US",
  "McLean, Kentucky, US",
  "Madison, Kentucky, US",
  "Magoffin, Kentucky, US",
  "Marion, Kentucky, US",
  "Marshall, Kentucky, US",
  "Martin, Kentucky, US",
  "Mason, Kentucky, US",
  "Meade, Kentucky, US",
  "Menifee, Kentucky, US",
  "Mercer, Kentucky, US",
  "Metcalfe, Kentucky, US",
  "Monroe, Kentucky, US",
  "Montgomery, Kentucky, US",
  "Morgan, Kentucky, US",
  "Muhlenberg, Kentucky, US",
  "Nelson, Kentucky, US",
  "Nicholas, Kentucky, US",
  "Ohio, Kentucky, US",
  "Oldham, Kentucky, US",
  "Owen, Kentucky, US",
  "Owsley, Kentucky, US",
  "Pendleton, Kentucky, US",
  "Perry, Kentucky, US",
  "Pike, Kentucky, US",
  "Powell, Kentucky, US",
  "Pulaski, Kentucky, US",
  "Robertson, Kentucky, US",
  "Rockcastle, Kentucky, US",
  "Rowan, Kentucky, US",
  "Russell, Kentucky, US",
  "Scott, Kentucky, US",
  "Shelby, Kentucky, US",
  "Simpson, Kentucky, US",
  "Spencer, Kentucky, US",
  "Taylor, Kentucky, US",
  "Todd, Kentucky, US",
  "Trigg, Kentucky, US",
  "Trimble, Kentucky, US",
  "Union, Kentucky, US",
  "Warren, Kentucky, US",
  "Washington, Kentucky, US",
  "Wayne, Kentucky, US",
  "Webster, Kentucky, US",
  "Whitley, Kentucky, US",
  "Wolfe, Kentucky, US",
  "Woodford, Kentucky, US",
  "Acadia, Louisiana, US",
  "Allen, Louisiana, US",
  "Ascension, Louisiana, US",
  "Assumption, Louisiana, US",
  "Avoyelles, Louisiana, US",
  "Beauregard, Louisiana, US",
  "Bienville, Louisiana, US",
  "Bossier, Louisiana, US",
  "Caddo, Louisiana, US",
  "Calcasieu, Louisiana, US",
  "Caldwell, Louisiana, US",
  "Cameron, Louisiana, US",
  "Catahoula, Louisiana, US",
  "Claiborne, Louisiana, US",
  "Concordia, Louisiana, US",
  "De Soto, Louisiana, US",
  "East Baton Rouge, Louisiana, US",
  "East Carroll, Louisiana, US",
  "East Feliciana, Louisiana, US",
  "Evangeline, Louisiana, US",
  "Franklin, Louisiana, US",
  "Grant, Louisiana, US",
  "Iberia, Louisiana, US",
  "Iberville, Louisiana, US",
  "Jackson, Louisiana, US",
  "Jefferson, Louisiana, US",
  "Jefferson Davis, Louisiana, US",
  "Lafayette, Louisiana, US",
  "Lafourche, Louisiana, US",
  "LaSalle,Louisiana,US",
  "Lincoln, Louisiana, US",
  "Livingston, Louisiana, US",
  "Madison, Louisiana, US",
  "Morehouse, Louisiana, US",
  "Natchitoches, Louisiana, US",
  "Orleans, Louisiana, US",
  "Ouachita, Louisiana, US",
  "Plaquemines, Louisiana, US",
  "Pointe Coupee, Louisiana, US",
  "Rapides, Louisiana, US",
  "Red River, Louisiana, US",
  "Richland, Louisiana, US",
  "Sabine, Louisiana, US",
  "St. Bernard, Louisiana, US",
  "St. Charles, Louisiana, US",
  "St. Helena, Louisiana, US",
  "St. James, Louisiana, US",
  "St. John the Baptist, Louisiana, US",
  "St. Landry, Louisiana, US",
  "St. Martin, Louisiana, US",
  "St. Mary, Louisiana, US",
  "St. Tammany, Louisiana, US",
  "Tangipahoa, Louisiana, US",
  "Tensas, Louisiana, US",
  "Terrebonne, Louisiana, US",
  "Union, Louisiana, US",
  "Vermilion, Louisiana, US",
  "Vernon, Louisiana, US",
  "Washington, Louisiana, US",
  "Webster, Louisiana, US",
  "West Baton Rouge, Louisiana, US",
  "West Carroll, Louisiana, US",
  "West Feliciana, Louisiana, US",
  "Winn, Louisiana, US",
  "Androscoggin, Maine, US",
  "Aroostook, Maine, US",
  "Cumberland, Maine, US",
  "Franklin, Maine, US",
  "Hancock, Maine, US",
  "Kennebec, Maine, US",
  "Knox, Maine, US",
  "Lincoln, Maine, US",
  "Oxford, Maine, US",
  "Penobscot, Maine, US",
  "Piscataquis, Maine, US",
  "Sagadahoc, Maine, US",
  "Somerset, Maine, US",
  "Waldo, Maine, US",
  "Washington, Maine, US",
  "York, Maine, US",
  "Allegany, Maryland, US",
  "Anne Arundel, Maryland, US",
  "Baltimore, Maryland, US",
  "Calvert, Maryland, US",
  "Caroline, Maryland, US",
  "Carroll, Maryland, US",
  "Cecil, Maryland, US",
  "Charles, Maryland, US",
  "Dorchester, Maryland, US",
  "Frederick, Maryland, US",
  "Garrett, Maryland, US",
  "Harford, Maryland, US",
  "Howard, Maryland, US",
  "Kent, Maryland, US",
  "Montgomery, Maryland, US",
  "Prince George's, Maryland, US",
  "Queen Anne's, Maryland, US",
  "St. Mary's, Maryland, US",
  "Somerset, Maryland, US",
  "Talbot, Maryland, US",
  "Washington, Maryland, US",
  "Wicomico, Maryland, US",
  "Worcester, Maryland, US",
  "Baltimore City, Maryland, US",
  "Barnstable, Massachusetts, US",
  "Berkshire, Massachusetts, US",
  "Bristol, Massachusetts, US",
  "Dukes, Massachusetts, US",
  "Essex, Massachusetts, US",
  "Franklin, Massachusetts, US",
  "Hampden, Massachusetts, US",
  "Hampshire, Massachusetts, US",
  "Middlesex, Massachusetts, US",
  "Nantucket, Massachusetts, US",
  "Norfolk, Massachusetts, US",
  "Plymouth, Massachusetts, US",
  "Suffolk, Massachusetts, US",
  "Worcester, Massachusetts, US",
  "Alcona, Michigan, US",
  "Alger, Michigan, US",
  "Allegan, Michigan, US",
  "Alpena, Michigan, US",
  "Antrim, Michigan, US",
  "Arenac, Michigan, US",
  "Baraga, Michigan, US",
  "Barry, Michigan, US",
  "Bay, Michigan, US",
  "Benzie, Michigan, US",
  "Berrien, Michigan, US",
  "Branch, Michigan, US",
  "Calhoun, Michigan, US",
  "Cass, Michigan, US",
  "Charlevoix, Michigan, US",
  "Cheboygan, Michigan, US",
  "Chippewa, Michigan, US",
  "Clare, Michigan, US",
  "Clinton, Michigan, US",
  "Crawford, Michigan, US",
  "Delta, Michigan, US",
  "Dickinson, Michigan, US",
  "Eaton, Michigan, US",
  "Emmet, Michigan, US",
  "Genesee, Michigan, US",
  "Gladwin, Michigan, US",
  "Gogebic, Michigan, US",
  "Grand Traverse, Michigan, US",
  "Gratiot, Michigan, US",
  "Hillsdale, Michigan, US",
  "Houghton, Michigan, US",
  "Huron, Michigan, US",
  "Ingham, Michigan, US",
  "Ionia, Michigan, US",
  "Iosco, Michigan, US",
  "Iron, Michigan, US",
  "Isabella, Michigan, US",
  "Jackson, Michigan, US",
  "Kalamazoo, Michigan, US",
  "Kalkaska, Michigan, US",
  "Kent, Michigan, US",
  "Keweenaw, Michigan, US",
  "Lake, Michigan, US",
  "Lapeer, Michigan, US",
  "Leelanau, Michigan, US",
  "Lenawee, Michigan, US",
  "Livingston, Michigan, US",
  "Luce, Michigan, US",
  "Mackinac, Michigan, US",
  "Macomb, Michigan, US",
  "Manistee, Michigan, US",
  "Marquette, Michigan, US",
  "Mason, Michigan, US",
  "Mecosta, Michigan, US",
  "Menominee, Michigan, US",
  "Midland, Michigan, US",
  "Missaukee, Michigan, US",
  "Monroe, Michigan, US",
  "Montcalm, Michigan, US",
  "Montmorency, Michigan, US",
  "Muskegon, Michigan, US",
  "Newaygo, Michigan, US",
  "Oakland, Michigan, US",
  "Oceana, Michigan, US",
  "Ogemaw, Michigan, US",
  "Ontonagon, Michigan, US",
  "Osceola, Michigan, US",
  "Oscoda, Michigan, US",
  "Otsego, Michigan, US",
  "Ottawa, Michigan, US",
  "Presque Isle, Michigan, US",
  "Roscommon, Michigan, US",
  "Saginaw, Michigan, US",
  "St. Clair, Michigan, US",
  "St. Joseph, Michigan, US",
  "Sanilac, Michigan, US",
  "Schoolcraft, Michigan, US",
  "Shiawassee, Michigan, US",
  "Tuscola, Michigan, US",
  "Van Buren, Michigan, US",
  "Washtenaw, Michigan, US",
  "Wayne, Michigan, US",
  "Wexford, Michigan, US",
  "Aitkin, Minnesota, US",
  "Anoka, Minnesota, US",
  "Becker, Minnesota, US",
  "Beltrami, Minnesota, US",
  "Benton, Minnesota, US",
  "Big Stone, Minnesota, US",
  "Blue Earth, Minnesota, US",
  "Brown, Minnesota, US",
  "Carlton, Minnesota, US",
  "Carver, Minnesota, US",
  "Cass, Minnesota, US",
  "Chippewa, Minnesota, US",
  "Chisago, Minnesota, US",
  "Clay, Minnesota, US",
  "Clearwater, Minnesota, US",
  "Cook, Minnesota, US",
  "Cottonwood, Minnesota, US",
  "Crow Wing, Minnesota, US",
  "Dakota, Minnesota, US",
  "Dodge, Minnesota, US",
  "Douglas, Minnesota, US",
  "Faribault, Minnesota, US",
  "Fillmore,Minnesota,US",
  "Freeborn, Minnesota, US",
  "Goodhue, Minnesota, US",
  "Grant, Minnesota, US",
  "Hennepin, Minnesota, US",
  "Houston, Minnesota, US",
  "Hubbard, Minnesota, US",
  "Isanti, Minnesota, US",
  "Itasca, Minnesota, US",
  "Jackson, Minnesota, US",
  "Kanabec, Minnesota, US",
  "Kandiyohi, Minnesota, US",
  "Kittson, Minnesota, US",
  "Koochiching, Minnesota, US",
  "Lac qui Parle,Minnesota,US",
  "Lake, Minnesota, US",
  "Lake of the Woods, Minnesota, US",
  "Le Sueur, Minnesota, US",
  "Lincoln, Minnesota, US",
  "Lyon, Minnesota, US",
  "McLeod, Minnesota, US",
  "Mahnomen, Minnesota, US",
  "Marshall, Minnesota, US",
  "Martin, Minnesota, US",
  "Meeker, Minnesota, US",
  "Mille Lacs, Minnesota, US",
  "Morrison, Minnesota, US",
  "Mower, Minnesota, US",
  "Murray, Minnesota, US",
  "Nicollet, Minnesota, US",
  "Nobles, Minnesota, US",
  "Norman, Minnesota, US",
  "Olmsted, Minnesota, US",
  "Otter Tail, Minnesota, US",
  "Pennington, Minnesota, US",
  "Pine, Minnesota, US",
  "Pipestone, Minnesota, US",
  "Polk, Minnesota, US",
  "Pope, Minnesota, US",
  "Ramsey, Minnesota, US",
  "Red Lake, Minnesota, US",
  "Redwood, Minnesota, US",
  "Renville, Minnesota, US",
  "Rice, Minnesota, US",
  "Rock, Minnesota, US",
  "Roseau, Minnesota, US",
  "St. Louis, Minnesota, US",
  "Scott, Minnesota, US",
  "Sherburne, Minnesota, US",
  "Sibley, Minnesota, US",
  "Stearns, Minnesota, US",
  "Steele, Minnesota, US",
  "Stevens, Minnesota, US",
  "Swift, Minnesota, US",
  "Todd, Minnesota, US",
  "Traverse, Minnesota, US",
  "Wabasha, Minnesota, US",
  "Wadena, Minnesota, US",
  "Waseca, Minnesota, US",
  "Washington, Minnesota, US",
  "Watonwan, Minnesota, US",
  "Wilkin, Minnesota, US",
  "Winona, Minnesota, US",
  "Wright, Minnesota, US",
  "Yellow Medicine, Minnesota, US",
  "Adams, Mississippi, US",
  "Alcorn, Mississippi, US",
  "Amite, Mississippi, US",
  "Attala, Mississippi, US",
  "Benton, Mississippi, US",
  "Bolivar, Mississippi, US",
  "Calhoun, Mississippi, US",
  "Carroll, Mississippi, US",
  "Chickasaw, Mississippi, US",
  "Choctaw, Mississippi, US",
  "Claiborne, Mississippi, US",
  "Clarke, Mississippi, US",
  "Clay, Mississippi, US",
  "Coahoma, Mississippi, US",
  "Copiah, Mississippi, US",
  "Covington, Mississippi, US",
  "DeSoto, Mississippi, US",
  "Forrest, Mississippi, US",
  "Franklin, Mississippi, US",
  "George, Mississippi, US",
  "Greene, Mississippi, US",
  "Grenada, Mississippi, US",
  "Hancock, Mississippi, US",
  "Harrison, Mississippi, US",
  "Hinds, Mississippi, US",
  "Holmes, Mississippi, US",
  "Humphreys, Mississippi, US",
  "Issaquena, Mississippi, US",
  "Itawamba, Mississippi, US",
  "Jackson, Mississippi, US",
  "Jasper, Mississippi, US",
  "Jefferson, Mississippi, US",
  "Jefferson Davis, Mississippi, US",
  "Jones, Mississippi, US",
  "Kemper, Mississippi, US",
  "Lafayette, Mississippi, US",
  "Lamar, Mississippi, US",
  "Lauderdale, Mississippi, US",
  "Lawrence, Mississippi, US",
  "Leake, Mississippi, US",
  "Lee, Mississippi, US",
  "Leflore, Mississippi, US",
  "Lincoln, Mississippi, US",
  "Lowndes, Mississippi, US",
  "Madison, Mississippi, US",
  "Marion, Mississippi, US",
  "Marshall, Mississippi, US",
  "Monroe, Mississippi, US",
  "Montgomery, Mississippi, US",
  "Neshoba, Mississippi, US",
  "Newton, Mississippi, US",
  "Noxubee, Mississippi, US",
  "Oktibbeha, Mississippi, US",
  "Panola, Mississippi, US",
  "Pearl River, Mississippi, US",
  "Perry, Mississippi, US",
  "Pike, Mississippi, US",
  "Pontotoc, Mississippi, US",
  "Prentiss, Mississippi, US",
  "Quitman, Mississippi, US",
  "Rankin, Mississippi, US",
  "Scott, Mississippi, US",
  "Sharkey, Mississippi, US",
  "Simpson, Mississippi, US",
  "Smith, Mississippi, US",
  "Stone, Mississippi, US",
  "Sunflower, Mississippi, US",
  "Tallahatchie, Mississippi, US",
  "Tate, Mississippi, US",
  "Tippah, Mississippi, US",
  "Tishomingo, Mississippi, US",
  "Tunica, Mississippi, US",
  "Union, Mississippi, US",
  "Walthall, Mississippi, US",
  "Warren, Mississippi, US",
  "Washington, Mississippi, US",
  "Wayne, Mississippi, US",
  "Webster, Mississippi, US",
  "Wilkinson, Mississippi, US",
  "Winston, Mississippi, US",
  "Yalobusha, Mississippi, US",
  "Yazoo, Mississippi, US",
  "Adair, Missouri, US",
  "Andrew, Missouri, US",
  "Atchison, Missouri, US",
  "Audrain, Missouri, US",
  "Barry, Missouri, US",
  "Barton, Missouri, US",
  "Bates, Missouri, US",
  "Benton, Missouri, US",
  "Bollinger, Missouri, US",
  "Boone, Missouri, US",
  "Buchanan, Missouri, US",
  "Butler, Missouri, US",
  "Caldwell, Missouri, US",
  "Callaway, Missouri, US",
  "Camden, Missouri, US",
  "Cape Girardeau, Missouri, US",
  "Carroll, Missouri, US",
  "Carter, Missouri, US",
  "Cass, Missouri, US",
  "Cedar, Missouri, US",
  "Chariton, Missouri, US",
  "Christian, Missouri, US",
  "Clark, Missouri, US",
  "Clay, Missouri, US",
  "Clinton, Missouri, US",
  "Cole, Missouri, US",
  "Cooper, Missouri, US",
  "Crawford, Missouri, US",
  "Dade, Missouri, US",
  "Dallas, Missouri, US",
  "Daviess, Missouri, US",
  "DeKalb, Missouri, US",
  "Dent, Missouri, US",
  "Douglas, Missouri, US",
  "Dunklin, Missouri, US",
  "Franklin, Missouri, US",
  "Gasconade, Missouri, US",
  "Gentry, Missouri, US",
  "Greene, Missouri, US",
  "Grundy, Missouri, US",
  "Harrison, Missouri, US",
  "Henry, Missouri, US",
  "Hickory, Missouri, US",
  "Holt, Missouri, US",
  "Howard, Missouri, US",
  "Howell, Missouri, US",
  "Iron, Missouri, US",
  "Jackson, Missouri, US",
  "Jasper,Missouri,US",
  "Jefferson, Missouri, US",
  "Johnson, Missouri, US",
  "Knox, Missouri, US",
  "Laclede, Missouri, US",
  "Lafayette, Missouri, US",
  "Lawrence, Missouri, US",
  "Lewis, Missouri, US",
  "Lincoln, Missouri, US",
  "Linn, Missouri, US",
  "Livingston, Missouri, US",
  "McDonald, Missouri, US",
  "Macon, Missouri, US",
  "Madison, Missouri, US",
  "Maries, Missouri, US",
  "Marion, Missouri, US",
  "Mercer, Missouri, US",
  "Miller, Missouri, US",
  "Mississippi, Missouri, US",
  "Moniteau, Missouri, US",
  "Monroe, Missouri, US",
  "Montgomery, Missouri, US",
  "Morgan, Missouri, US",
  "New Madrid, Missouri, US",
  "Newton, Missouri, US",
  "Nodaway, Missouri, US",
  "Oregon, Missouri, US",
  "Osage, Missouri, US",
  "Ozark, Missouri, US",
  "Pemiscot, Missouri, US",
  "Perry, Missouri, US",
  "Pettis, Missouri, US",
  "Phelps, Missouri, US",
  "Pike, Missouri, US",
  "Platte, Missouri, US",
  "Polk, Missouri, US",
  "Pulaski, Missouri, US",
  "Putnam, Missouri, US",
  "Ralls, Missouri, US",
  "Randolph, Missouri, US",
  "Ray, Missouri, US",
  "Reynolds, Missouri, US",
  "Ripley, Missouri, US",
  "St. Charles, Missouri, US",
  "St. Clair, Missouri, US",
  "Ste. Genevieve, Missouri, US",
  "St. Francois, Missouri, US",
  "St. Louis, Missouri, US",
  "Saline, Missouri, US",
  "Schuyler, Missouri, US",
  "Scotland, Missouri, US",
  "Scott, Missouri, US",
  "Shannon, Missouri, US",
  "Shelby, Missouri, US",
  "Stoddard, Missouri, US",
  "Stone, Missouri, US",
  "Sullivan, Missouri, US",
  "Taney, Missouri, US",
  "Texas, Missouri, US",
  "Vernon, Missouri, US",
  "Warren, Missouri, US",
  "Washington, Missouri, US",
  "Wayne, Missouri, US",
  "Webster, Missouri, US",
  "Worth, Missouri, US",
  "Wright, Missouri, US",
  "St. Louis City, Missouri, US",
  "Beaverhead, Montana, US",
  "Big Horn, Montana, US",
  "Blaine, Montana, US",
  "Broadwater, Montana, US",
  "Carbon, Montana, US",
  "Carter, Montana, US",
  "Cascade, Montana, US",
  "Chouteau, Montana, US",
  "Custer, Montana, US",
  "Daniels, Montana, US",
  "Dawson, Montana, US",
  "Deer Lodge, Montana, US",
  "Fallon, Montana, US",
  "Fergus, Montana, US",
  "Flathead, Montana, US",
  "Gallatin, Montana, US",
  "Garfield, Montana, US",
  "Glacier, Montana, US",
  "Golden Valley, Montana, US",
  "Granite, Montana, US",
  "Hill, Montana, US",
  "Jefferson, Montana, US",
  "Judith Basin, Montana, US",
  "Lake, Montana, US",
  // "Lewis and Clark, Montana, US",
  "Liberty, Montana, US",
  "Lincoln, Montana, US",
  "McCone, Montana, US",
  "Madison, Montana, US",
  "Meagher, Montana, US",
  "Mineral, Montana, US",
  "Missoula, Montana, US",
  "Musselshell, Montana, US",
  "Park, Montana, US",
  "Petroleum, Montana, US",
  "Phillips, Montana, US",
  "Pondera, Montana, US",
  "Powder River, Montana, US",
  "Powell, Montana, US",
  "Prairie, Montana, US",
  "Ravalli, Montana, US",
  "Richland, Montana, US",
  "Roosevelt, Montana, US",
  "Rosebud, Montana, US",
  "Sanders, Montana, US",
  "Sheridan, Montana, US",
  "Silver Bow, Montana, US",
  "Stillwater, Montana, US",
  "Sweet Grass, Montana, US",
  "Teton, Montana, US",
  "Toole, Montana, US",
  "Treasure, Montana, US",
  "Valley, Montana, US",
  "Wheatland, Montana, US",
  "Wibaux, Montana, US",
  "Yellowstone, Montana, US",
  "Adams, Nebraska, US",
  "Antelope, Nebraska, US",
  "Arthur, Nebraska, US",
  "Banner, Nebraska, US",
  "Blaine, Nebraska, US",
  "Boone, Nebraska, US",
  "Box Butte, Nebraska, US",
  "Boyd, Nebraska, US",
  "Brown, Nebraska, US",
  "Buffalo, Nebraska, US",
  "Burt, Nebraska, US",
  "Butler, Nebraska, US",
  "Cass, Nebraska, US",
  "Cedar, Nebraska, US",
  "Chase, Nebraska, US",
  "Cherry, Nebraska, US",
  "Cheyenne, Nebraska, US",
  "Clay, Nebraska, US",
  "Colfax, Nebraska, US",
  "Cuming, Nebraska, US",
  "Custer, Nebraska, US",
  "Dakota, Nebraska, US",
  "Dawes, Nebraska, US",
  "Dawson, Nebraska, US",
  "Deuel, Nebraska, US",
  "Dixon, Nebraska, US",
  "Dodge, Nebraska, US",
  "Douglas, Nebraska, US",
  "Dundy, Nebraska, US",
  "Fillmore, Nebraska, US",
  "Franklin, Nebraska, US",
  "Frontier, Nebraska, US",
  "Furnas, Nebraska, US",
  "Gage, Nebraska, US",
  "Garden, Nebraska, US",
  "Garfield, Nebraska, US",
  "Gosper, Nebraska, US",
  "Grant, Nebraska, US",
  "Greeley, Nebraska, US",
  "Hall, Nebraska, US",
  "Hamilton, Nebraska, US",
  "Harlan, Nebraska, US",
  "Hayes, Nebraska, US",
  "Hitchcock, Nebraska, US",
  "Holt, Nebraska, US",
  "Hooker, Nebraska, US",
  "Howard, Nebraska, US",
  "Jefferson, Nebraska, US",
  "Johnson, Nebraska, US",
  "Kearney, Nebraska, US",
  "Keith, Nebraska, US",
  "Keya Paha, Nebraska, US",
  "Kimball, Nebraska, US",
  "Knox, Nebraska, US",
  "Lancaster, Nebraska, US",
  "Lincoln, Nebraska, US",
  "Logan, Nebraska, US",
  "Loup, Nebraska, US",
  "McPherson, Nebraska, US",
  "Madison, Nebraska, US",
  "Merrick, Nebraska, US",
  "Morrill, Nebraska, US",
  "Nance, Nebraska, US",
  "Nemaha, Nebraska, US",
  "Nuckolls, Nebraska, US",
  "Otoe, Nebraska, US",
  "Pawnee, Nebraska, US",
  "Perkins, Nebraska, US",
  "Phelps, Nebraska, US",
  "Pierce, Nebraska, US",
  "Platte, Nebraska, US",
  "Polk, Nebraska, US",
  "Red Willow, Nebraska, US",
  "Richardson, Nebraska, US",
  "Rock, Nebraska, US",
  "Saline, Nebraska, US",
  "Sarpy, Nebraska, US",
  "Saunders, Nebraska, US",
  "Scotts Bluff, Nebraska, US",
  "Seward, Nebraska, US",
  "Sheridan, Nebraska, US",
  "Sherman, Nebraska, US",
  "Sioux, Nebraska, US",
  "Stanton, Nebraska, US",
  "Thayer, Nebraska, US",
  "Thomas, Nebraska, US",
  "Thurston, Nebraska, US",
  "Valley, Nebraska, US",
  "Washington, Nebraska, US",
  "Wayne, Nebraska, US",
  "Webster, Nebraska, US",
  "Wheeler, Nebraska, US",
  "York, Nebraska, US",
  "Churchill, Nevada, US",
  "Clark, Nevada, US",
  "Douglas, Nevada, US",
  "Elko, Nevada, US",
  "Esmeralda, Nevada, US",
  "Eureka, Nevada, US",
  "Humboldt, Nevada, US",
  "Lander, Nevada, US",
  "Lincoln, Nevada, US",
  "Lyon, Nevada, US",
  "Mineral, Nevada, US",
  "Nye, Nevada, US",
  "Pershing, Nevada, US",
  "Storey, Nevada, US",
  "Washoe, Nevada, US",
  "White Pine, Nevada, US",
  "Carson City, Nevada, US",
  "Belknap, New Hampshire, US",
  "Carroll, New Hampshire, US",
  "Cheshire, New Hampshire, US",
  "Coos, New Hampshire, US",
  "Grafton, New Hampshire, US",
  "Hillsborough, New Hampshire, US",
  "Merrimack, New Hampshire, US",
  "Rockingham, New Hampshire, US",
  "Strafford, New Hampshire, US",
  "Sullivan, New Hampshire, US",
  "Atlantic, New Jersey, US",
  "Bergen, New Jersey, US",
  "Burlington, New Jersey, US",
  "Camden, New Jersey, US",
  "Cape May, New Jersey, US",
  "Cumberland, New Jersey, US",
  "Essex, New Jersey, US",
  "Gloucester, New Jersey, US",
  "Hudson, New Jersey, US",
  "Hunterdon, New Jersey, US",
  "Mercer, New Jersey, US",
  "Middlesex, New Jersey, US",
  "Monmouth, New Jersey, US",
  "Morris, New Jersey, US",
  "Ocean, New Jersey, US",
  "Passaic, New Jersey, US",
  "Salem, New Jersey, US",
  "Somerset, New Jersey, US",
  "Sussex, New Jersey, US",
  "Union, New Jersey, US",
  "Warren, New Jersey, US",
  "Bernalillo, New Mexico, US",
  "Catron, New Mexico, US",
  "Chaves, New Mexico, US",
  "Cibola, New Mexico, US",
  "Colfax, New Mexico, US",
  "Curry, New Mexico, US",
  "De Baca, New Mexico, US",
  "Dona Ana, New Mexico, US",
  "Eddy, New Mexico, US",
  "Grant, New Mexico, US",
  "Guadalupe, New Mexico, US",
  "Harding, New Mexico, US",
  "Hidalgo, New Mexico, US",
  "Lea, New Mexico, US",
  "Lincoln, New Mexico, US",
  "Los Alamos, New Mexico, US",
  "Luna, New Mexico, US",
  "McKinley, New Mexico, US",
  "Mora, New Mexico, US",
  "Otero, New Mexico, US",
  "Quay, New Mexico, US",
  "Rio Arriba, New Mexico, US",
  "Roosevelt, New Mexico, US",
  "Sandoval, New Mexico, US",
  "San Juan, New Mexico, US",
  "San Miguel, New Mexico, US",
  "Santa Fe, New Mexico, US",
  "Sierra, New Mexico, US",
  "Socorro, New Mexico, US",
  "Taos, New Mexico, US",
  "Torrance, New Mexico, US",
  "Union, New Mexico, US",
  "Valencia, New Mexico, US",
  "Albany, New York, US",
  "Allegany, New York, US",
  // "Bronx, New York, US",
  "Broome, New York, US",
  "Cattaraugus, New York, US",
  "Cayuga, New York, US",
  "Chautauqua, New York, US",
  "Chemung, New York, US",
  "Chenango, New York, US",
  "Clinton, New York, US",
  "Columbia, New York, US",
  "Cortland, New York, US",
  "Delaware, New York, US",
  "Dutchess, New York, US",
  "Erie, New York, US",
  "Essex, New York, US",
  "Franklin, New York, US",
  "Fulton, New York, US",
  "Genesee, New York, US",
  "Greene, New York, US",
  "Hamilton, New York, US",
  "Herkimer, New York, US",
  "Jefferson, New York, US",
  "Kings, New York, US",
  "Lewis, New York, US",
  "Livingston, New York, US",
  "Madison, New York, US",
  "Monroe, New York, US",
  "Montgomery, New York, US",
  "Nassau, New York, US",
  "New York City, New York, US",
  "Niagara, New York, US",
  "Oneida, New York, US",
  "Onondaga, New York, US",
  "Ontario, New York, US",
  "Orange, New York, US",
  "Orleans, New York, US",
  "Oswego, New York, US",
  "Otsego, New York, US",
  "Putnam, New York, US",
  "Queens, New York, US",
  "Rensselaer, New York, US",
  "Richmond, New York, US",
  "Rockland, New York, US",
  "St. Lawrence, New York, US",
  "Saratoga, New York, US",
  "Schenectady, New York, US",
  "Schoharie, New York, US",
  "Schuyler, New York, US",
  "Seneca, New York, US",
  "Steuben, New York, US",
  "Suffolk, New York, US",
  "Sullivan, New York, US",
  "Tioga, New York, US",
  "Tompkins, New York, US",
  "Ulster, New York, US",
  "Warren, New York, US",
  "Washington, New York, US",
  "Wayne, New York, US",
  "Westchester, New York, US",
  "Wyoming, New York, US",
  "Yates, New York, US",
  "Alamance, North Carolina, US",
  "Alexander,North Carolina,US",
  "Alleghany, North Carolina, US",
  "Anson, North Carolina, US",
  "Ashe, North Carolina, US",
  "Avery, North Carolina, US",
  "Beaufort, North Carolina, US",
  "Bertie, North Carolina, US",
  "Bladen, North Carolina, US",
  "Brunswick, North Carolina, US",
  "Buncombe, North Carolina, US",
  "Burke, North Carolina, US",
  "Cabarrus, North Carolina, US",
  "Caldwell, North Carolina, US",
  "Camden, North Carolina, US",
  "Carteret, North Carolina, US",
  "Caswell, North Carolina, US",
  "Catawba, North Carolina, US",
  "Chatham, North Carolina, US",
  "Cherokee, North Carolina, US",
  "Chowan, North Carolina, US",
  "Clay, North Carolina, US",
  "Cleveland, North Carolina, US",
  "Columbus, North Carolina, US",
  "Craven, North Carolina, US",
  "Cumberland, North Carolina, US",
  "Currituck, North Carolina, US",
  "Dare, North Carolina, US",
  "Davidson, North Carolina, US",
  "Davie, North Carolina, US",
  "Duplin, North Carolina, US",
  "Durham, North Carolina, US",
  "Edgecombe, North Carolina, US",
  "Forsyth, North Carolina, US",
  "Franklin, North Carolina, US",
  "Gaston, North Carolina, US",
  "Gates, North Carolina, US",
  "Graham, North Carolina, US",
  "Granville, North Carolina, US",
  "Greene, North Carolina, US",
  "Guilford, North Carolina, US",
  "Halifax, North Carolina, US",
  "Harnett, North Carolina, US",
  "Haywood, North Carolina, US",
  "Henderson, North Carolina, US",
  "Hertford, North Carolina, US",
  "Hoke, North Carolina, US",
  "Hyde, North Carolina, US",
  "Iredell, North Carolina, US",
  "Jackson, North Carolina, US",
  "Johnston, North Carolina, US",
  "Jones, North Carolina, US",
  "Lee, North Carolina, US",
  "Lenoir, North Carolina, US",
  "Lincoln, North Carolina, US",
  "McDowell, North Carolina, US",
  "Macon, North Carolina, US",
  "Madison, North Carolina, US",
  "Martin, North Carolina, US",
  "Mecklenburg, North Carolina, US",
  "Mitchell, North Carolina, US",
  "Montgomery, North Carolina, US",
  "Moore, North Carolina, US",
  "Nash, North Carolina, US",
  "New Hanover, North Carolina, US",
  "Northampton, North Carolina, US",
  "Onslow, North Carolina, US",
  "Orange, North Carolina, US",
  "Pamlico, North Carolina, US",
  "Pasquotank, North Carolina, US",
  "Pender, North Carolina, US",
  "Perquimans, North Carolina, US",
  "Person, North Carolina, US",
  "Pitt, North Carolina, US",
  "Polk, North Carolina, US",
  "Randolph, North Carolina, US",
  "Richmond, North Carolina, US",
  "Robeson, North Carolina, US",
  "Rockingham, North Carolina, US",
  "Rowan, North Carolina, US",
  "Rutherford, North Carolina, US",
  "Sampson, North Carolina, US",
  "Scotland, North Carolina, US",
  "Stanly, North Carolina, US",
  "Stokes, North Carolina, US",
  "Surry, North Carolina, US",
  "Swain, North Carolina, US",
  "Transylvania, North Carolina, US",
  "Tyrrell, North Carolina, US",
  "Union, North Carolina, US",
  "Vance, North Carolina, US",
  "Wake, North Carolina, US",
  "Warren, North Carolina, US",
  "Washington, North Carolina, US",
  "Watauga, North Carolina, US",
  "Wayne, North Carolina, US",
  "Wilkes, North Carolina, US",
  "Wilson, North Carolina, US",
  "Yadkin, North Carolina, US",
  "Yancey, North Carolina, US",
  "Adams, North Dakota, US",
  "Barnes, North Dakota, US",
  "Benson, North Dakota, US",
  "Billings, North Dakota, US",
  "Bottineau, North Dakota, US",
  "Bowman, North Dakota, US",
  "Burke, North Dakota, US",
  "Burleigh, North Dakota, US",
  "Cass, North Dakota, US",
  "Cavalier, North Dakota, US",
  "Dickey, North Dakota, US",
  "Divide, North Dakota, US",
  "Dunn, North Dakota, US",
  "Eddy, North Dakota, US",
  "Emmons, North Dakota, US",
  "Foster, North Dakota, US",
  "Golden Valley, North Dakota, US",
  "Grand Forks, North Dakota, US",
  "Grant, North Dakota, US",
  "Griggs, North Dakota, US",
  "Hettinger, North Dakota, US",
  "Kidder, North Dakota, US",
  "LaMoure, North Dakota, US",
  "Logan, North Dakota, US",
  "McHenry, North Dakota, US",
  "McIntosh, North Dakota, US",
  "McKenzie, North Dakota, US",
  "McLean, North Dakota, US",
  "Mercer, North Dakota, US",
  "Morton, North Dakota, US",
  "Mountrail, North Dakota, US",
  "Nelson, North Dakota, US",
  "Oliver, North Dakota, US",
  "Pembina, North Dakota, US",
  "Pierce, North Dakota, US",
  "Ramsey, North Dakota, US",
  "Ransom, North Dakota, US",
  "Renville, North Dakota, US",
  "Richland, North Dakota, US",
  "Rolette, North Dakota, US",
  "Sargent, North Dakota, US",
  "Sheridan, North Dakota, US",
  "Sioux, North Dakota, US",
  "Slope, North Dakota, US",
  "Stark, North Dakota, US",
  "Steele, North Dakota, US",
  "Stutsman, North Dakota, US",
  "Towner, North Dakota, US",
  "Traill, North Dakota, US",
  "Walsh, North Dakota, US",
  "Ward, North Dakota, US",
  "Wells, North Dakota, US",
  "Williams, North Dakota, US",
  "Adams, Ohio, US",
  "Allen, Ohio, US",
  "Ashland, Ohio, US",
  "Ashtabula, Ohio, US",
  "Athens, Ohio, US",
  "Auglaize, Ohio, US",
  "Belmont, Ohio, US",
  "Brown, Ohio, US",
  "Butler, Ohio, US",
  "Carroll, Ohio, US",
  "Champaign, Ohio, US",
  "Clark, Ohio, US",
  "Clermont, Ohio, US",
  "Clinton, Ohio, US",
  "Columbiana, Ohio, US",
  "Coshocton, Ohio, US",
  "Crawford, Ohio, US",
  "Cuyahoga, Ohio, US",
  "Darke, Ohio, US",
  "Defiance, Ohio, US",
  "Delaware, Ohio, US",
  "Erie, Ohio, US",
  "Fairfield, Ohio, US",
  "Fayette, Ohio, US",
  "Franklin, Ohio, US",
  "Fulton, Ohio, US",
  "Gallia, Ohio, US",
  "Geauga, Ohio, US",
  "Greene, Ohio, US",
  "Guernsey, Ohio, US",
  "Hamilton, Ohio, US",
  "Hancock, Ohio, US",
  "Hardin, Ohio, US",
  "Harrison, Ohio, US",
  "Henry, Ohio, US",
  "Highland, Ohio, US",
  "Hocking, Ohio, US",
  "Holmes, Ohio, US",
  "Huron, Ohio, US",
  "Jackson, Ohio, US",
  "Jefferson, Ohio, US",
  "Knox, Ohio, US",
  "Lake, Ohio, US",
  "Lawrence, Ohio, US",
  "Licking, Ohio, US",
  "Logan, Ohio, US",
  "Lorain, Ohio, US",
  "Lucas, Ohio, US",
  "Madison, Ohio, US",
  "Mahoning, Ohio, US",
  "Marion, Ohio, US",
  "Medina, Ohio, US",
  "Meigs, Ohio, US",
  "Mercer, Ohio, US",
  "Miami, Ohio, US",
  "Monroe, Ohio, US",
  "Montgomery, Ohio, US",
  "Morgan, Ohio, US",
  "Morrow, Ohio, US",
  "Muskingum, Ohio, US",
  "Noble, Ohio, US",
  "Ottawa, Ohio, US",
  "Paulding, Ohio, US",
  "Perry, Ohio, US",
  "Pickaway, Ohio, US",
  "Pike, Ohio, US",
  "Portage, Ohio, US",
  "Preble, Ohio, US",
  "Putnam, Ohio, US",
  "Richland, Ohio, US",
  "Ross, Ohio, US",
  "Sandusky, Ohio, US",
  "Scioto, Ohio, US",
  "Seneca, Ohio, US",
  "Shelby, Ohio, US",
  "Stark, Ohio, US",
  "Summit, Ohio, US",
  "Trumbull, Ohio, US",
  "Tuscarawas, Ohio, US",
  "Union, Ohio, US",
  "Van Wert, Ohio, US",
  "Vinton, Ohio, US",
  "Warren, Ohio, US",
  "Washington, Ohio, US",
  "Wayne, Ohio, US",
  "Williams, Ohio, US",
  "Wood, Ohio, US",
  "Wyandot, Ohio, US",
  "Adair, Oklahoma, US",
  "Alfalfa, Oklahoma, US",
  "Atoka, Oklahoma, US",
  "Beaver, Oklahoma, US",
  "Beckham, Oklahoma, US",
  "Blaine, Oklahoma, US",
  "Bryan, Oklahoma, US",
  "Caddo, Oklahoma, US",
  "Canadian, Oklahoma, US",
  "Carter, Oklahoma, US",
  "Cherokee, Oklahoma, US",
  "Choctaw, Oklahoma, US",
  "Cimarron, Oklahoma, US",
  "Cleveland, Oklahoma, US",
  "Coal, Oklahoma, US",
  "Comanche, Oklahoma, US",
  "Cotton, Oklahoma, US",
  "Craig, Oklahoma, US",
  "Creek, Oklahoma, US",
  "Custer, Oklahoma, US",
  "Delaware, Oklahoma, US",
  "Dewey, Oklahoma, US",
  "Ellis, Oklahoma, US",
  "Garfield, Oklahoma, US",
  "Garvin, Oklahoma, US",
  "Grady, Oklahoma, US",
  "Grant, Oklahoma, US",
  "Greer, Oklahoma, US",
  "Harmon, Oklahoma, US",
  "Harper, Oklahoma, US",
  "Haskell, Oklahoma, US",
  "Hughes, Oklahoma, US",
  "Jackson, Oklahoma, US",
  "Jefferson, Oklahoma, US",
  "Johnston, Oklahoma, US",
  "Kay, Oklahoma, US",
  "Kingfisher, Oklahoma, US",
  "Kiowa, Oklahoma, US",
  "Latimer, Oklahoma, US",
  "Le Flore, Oklahoma, US",
  "Lincoln, Oklahoma, US",
  "Logan, Oklahoma, US",
  "Love, Oklahoma, US",
  "McClain, Oklahoma, US",
  "McCurtain, Oklahoma, US",
  "McIntosh, Oklahoma, US",
  "Major, Oklahoma, US",
  "Marshall, Oklahoma, US",
  "Mayes, Oklahoma, US",
  "Murray, Oklahoma, US",
  "Muskogee, Oklahoma, US",
  "Noble, Oklahoma, US",
  "Nowata, Oklahoma, US",
  "Okfuskee, Oklahoma, US",
  "Oklahoma, Oklahoma, US",
  "Okmulgee, Oklahoma, US",
  "Osage, Oklahoma, US",
  "Ottawa, Oklahoma, US",
  "Pawnee, Oklahoma, US",
  "Payne, Oklahoma, US",
  "Pittsburg, Oklahoma, US",
  "Pontotoc, Oklahoma, US",
  "Pottawatomie, Oklahoma, US",
  "Pushmataha, Oklahoma, US",
  "Roger Mills, Oklahoma, US",
  "Rogers, Oklahoma, US",
  "Seminole, Oklahoma, US",
  "Sequoyah, Oklahoma, US",
  "Stephens, Oklahoma, US",
  "Texas, Oklahoma, US",
  "Tillman, Oklahoma, US",
  "Tulsa, Oklahoma, US",
  "Wagoner, Oklahoma, US",
  "Washington, Oklahoma, US",
  "Washita, Oklahoma, US",
  "Woods, Oklahoma, US",
  "Woodward, Oklahoma, US",
  "Baker, Oregon, US",
  "Benton, Oregon, US",
  "Clackamas, Oregon, US",
  "Clatsop, Oregon, US",
  "Columbia, Oregon, US",
  "Coos, Oregon, US",
  "Crook, Oregon, US",
  "Curry, Oregon, US",
  "Deschutes, Oregon, US",
  "Douglas, Oregon, US",
  "Gilliam, Oregon, US",
  "Grant, Oregon, US",
  "Harney, Oregon, US",
  "Hood River, Oregon, US",
  "Jackson, Oregon, US",
  "Jefferson, Oregon, US",
  "Josephine, Oregon, US",
  "Klamath, Oregon, US",
  "Lake, Oregon, US",
  "Lane, Oregon, US",
  "Lincoln, Oregon, US",
  "Linn, Oregon, US",
  "Malheur, Oregon, US",
  "Marion, Oregon, US",
  "Morrow, Oregon, US",
  "Multnomah, Oregon, US",
  "Polk, Oregon, US",
  "Sherman, Oregon, US",
  "Tillamook, Oregon, US",
  "Umatilla, Oregon, US",
  "Union, Oregon, US",
  "Wallowa, Oregon, US",
  "Wasco, Oregon, US",
  "Washington, Oregon, US",
  "Wheeler, Oregon, US",
  "Yamhill, Oregon, US",
  "Adams, Pennsylvania, US",
  "Allegheny, Pennsylvania, US",
  "Armstrong, Pennsylvania, US",
  "Beaver, Pennsylvania, US",
  "Bedford, Pennsylvania, US",
  "Berks, Pennsylvania, US",
  "Blair, Pennsylvania, US",
  "Bradford, Pennsylvania, US",
  "Bucks, Pennsylvania, US",
  "Butler, Pennsylvania, US",
  "Cambria, Pennsylvania, US",
  "Cameron, Pennsylvania, US",
  "Carbon, Pennsylvania, US",
  "Centre, Pennsylvania, US",
  "Chester, Pennsylvania, US",
  "Clarion, Pennsylvania, US",
  "Clearfield, Pennsylvania, US",
  "Clinton, Pennsylvania, US",
  "Columbia, Pennsylvania, US",
  "Crawford, Pennsylvania, US",
  "Cumberland, Pennsylvania, US",
  "Dauphin, Pennsylvania, US",
  "Delaware, Pennsylvania, US",
  "Elk, Pennsylvania, US",
  "Erie, Pennsylvania, US",
  "Fayette, Pennsylvania, US",
  "Forest, Pennsylvania, US",
  "Franklin, Pennsylvania, US",
  "Fulton, Pennsylvania, US",
  "Greene, Pennsylvania, US",
  "Huntingdon, Pennsylvania, US",
  "Indiana, Pennsylvania, US",
  "Jefferson, Pennsylvania, US",
  "Juniata, Pennsylvania, US",
  "Lackawanna, Pennsylvania, US",
  "Lancaster, Pennsylvania, US",
  "Lawrence, Pennsylvania, US",
  "Lebanon, Pennsylvania, US",
  "Lehigh, Pennsylvania, US",
  "Luzerne, Pennsylvania, US",
  "Lycoming, Pennsylvania, US",
  "McKean,Pennsylvania,US",
  "Mercer, Pennsylvania, US",
  "Mifflin, Pennsylvania, US",
  "Monroe, Pennsylvania, US",
  "Montgomery, Pennsylvania, US",
  "Montour, Pennsylvania, US",
  "Northampton, Pennsylvania, US",
  "Northumberland, Pennsylvania, US",
  "Perry, Pennsylvania, US",
  "Philadelphia, Pennsylvania, US",
  "Pike, Pennsylvania, US",
  "Potter, Pennsylvania, US",
  "Schuylkill, Pennsylvania, US",
  "Snyder, Pennsylvania, US",
  "Somerset, Pennsylvania, US",
  "Sullivan, Pennsylvania, US",
  "Susquehanna, Pennsylvania, US",
  "Tioga, Pennsylvania, US",
  "Union, Pennsylvania, US",
  "Venango, Pennsylvania, US",
  "Warren, Pennsylvania, US",
  "Washington, Pennsylvania, US",
  "Wayne, Pennsylvania, US",
  "Westmoreland, Pennsylvania, US",
  "Wyoming, Pennsylvania, US",
  "York, Pennsylvania, US",
  "Bristol, Rhode Island, US",
  "Kent, Rhode Island, US",
  "Newport, Rhode Island, US",
  "Providence, Rhode Island, US",
  "Washington, Rhode Island, US",
  "Abbeville, South Carolina, US",
  "Aiken, South Carolina, US",
  "Allendale, South Carolina, US",
  "Anderson, South Carolina, US",
  "Bamberg, South Carolina, US",
  "Barnwell, South Carolina, US",
  "Beaufort, South Carolina, US",
  "Berkeley, South Carolina, US",
  "Calhoun, South Carolina, US",
  "Charleston, South Carolina, US",
  "Cherokee, South Carolina, US",
  "Chester, South Carolina, US",
  "Chesterfield, South Carolina, US",
  "Clarendon, South Carolina, US",
  "Colleton, South Carolina, US",
  "Darlington, South Carolina, US",
  "Dillon, South Carolina, US",
  "Dorchester, South Carolina, US",
  "Edgefield, South Carolina, US",
  "Fairfield, South Carolina, US",
  "Florence, South Carolina, US",
  "Georgetown, South Carolina, US",
  "Greenville, South Carolina, US",
  "Greenwood, South Carolina, US",
  "Hampton, South Carolina, US",
  "Horry, South Carolina, US",
  "Jasper, South Carolina, US",
  "Kershaw, South Carolina, US",
  "Lancaster, South Carolina, US",
  "Laurens, South Carolina, US",
  "Lee, South Carolina, US",
  "Lexington, South Carolina, US",
  "McCormick, South Carolina, US",
  "Marion, South Carolina, US",
  "Marlboro, South Carolina, US",
  "Newberry, South Carolina, US",
  "Oconee, South Carolina, US",
  "Orangeburg, South Carolina, US",
  "Pickens, South Carolina, US",
  "Richland, South Carolina, US",
  "Saluda, South Carolina, US",
  "Spartanburg, South Carolina, US",
  "Sumter, South Carolina, US",
  "Union, South Carolina, US",
  "Williamsburg, South Carolina, US",
  "York, South Carolina, US",
  "Aurora, South Dakota, US",
  "Beadle, South Dakota, US",
  "Bennett, South Dakota, US",
  "Bon Homme, South Dakota, US",
  "Brookings, South Dakota, US",
  "Brown, South Dakota, US",
  "Brule, South Dakota, US",
  "Buffalo, South Dakota, US",
  "Butte, South Dakota, US",
  "Campbell, South Dakota, US",
  "Charles Mix, South Dakota, US",
  "Clark, South Dakota, US",
  "Clay, South Dakota, US",
  "Codington, South Dakota, US",
  "Corson, South Dakota, US",
  "Custer, South Dakota, US",
  "Davison, South Dakota, US",
  "Day, South Dakota, US",
  "Deuel, South Dakota, US",
  "Dewey, South Dakota, US",
  "Douglas, South Dakota, US",
  "Edmunds, South Dakota, US",
  "Fall River, South Dakota, US",
  "Faulk, South Dakota, US",
  "Grant, South Dakota, US",
  "Gregory, South Dakota, US",
  "Haakon, South Dakota, US",
  "Hamlin, South Dakota, US",
  "Hand, South Dakota, US",
  "Hanson, South Dakota, US",
  "Harding, South Dakota, US",
  "Hughes, South Dakota, US",
  "Hutchinson, South Dakota, US",
  "Hyde, South Dakota, US",
  "Jackson, South Dakota, US",
  "Jerauld, South Dakota, US",
  "Jones, South Dakota, US",
  "Kingsbury, South Dakota, US",
  "Lake, South Dakota, US",
  "Lawrence, South Dakota, US",
  "Lincoln, South Dakota, US",
  "Lyman, South Dakota, US",
  "McCook, South Dakota, US",
  "McPherson, South Dakota, US",
  "Marshall, South Dakota, US",
  "Meade, South Dakota, US",
  "Mellette, South Dakota, US",
  "Miner, South Dakota, US",
  "Minnehaha, South Dakota, US",
  "Moody, South Dakota, US",
  "Oglala Lakota, South Dakota, US",
  "Pennington, South Dakota, US",
  "Perkins, South Dakota, US",
  "Potter, South Dakota, US",
  "Roberts, South Dakota, US",
  "Sanborn, South Dakota, US",
  "Spink, South Dakota, US",
  "Stanley, South Dakota, US",
  "Sully, South Dakota, US",
  "Todd, South Dakota, US",
  "Tripp, South Dakota, US",
  "Turner, South Dakota, US",
  "Union, South Dakota, US",
  "Walworth, South Dakota, US",
  "Yankton, South Dakota, US",
  "Ziebach, South Dakota, US",
  "Anderson, Tennessee, US",
  "Bedford, Tennessee, US",
  "Benton, Tennessee, US",
  "Bledsoe, Tennessee, US",
  "Blount, Tennessee, US",
  "Bradley, Tennessee, US",
  "Campbell, Tennessee, US",
  "Cannon, Tennessee, US",
  "Carroll, Tennessee, US",
  "Carter, Tennessee, US",
  "Cheatham, Tennessee, US",
  "Chester, Tennessee, US",
  "Claiborne, Tennessee, US",
  "Clay, Tennessee, US",
  "Cocke, Tennessee, US",
  "Coffee, Tennessee, US",
  "Crockett, Tennessee, US",
  "Cumberland, Tennessee, US",
  "Davidson, Tennessee, US",
  "Decatur, Tennessee, US",
  "DeKalb,Tennessee,US",
  "Dickson, Tennessee, US",
  "Dyer, Tennessee, US",
  "Fayette, Tennessee, US",
  "Fentress, Tennessee, US",
  "Franklin, Tennessee, US",
  "Gibson, Tennessee, US",
  "Giles, Tennessee, US",
  "Grainger, Tennessee, US",
  "Greene, Tennessee, US",
  "Grundy, Tennessee, US",
  "Hamblen, Tennessee, US",
  "Hamilton, Tennessee, US",
  "Hancock, Tennessee, US",
  "Hardeman, Tennessee, US",
  "Hardin, Tennessee, US",
  "Hawkins, Tennessee, US",
  "Haywood, Tennessee, US",
  "Henderson, Tennessee, US",
  "Henry, Tennessee, US",
  "Hickman, Tennessee, US",
  "Houston, Tennessee, US",
  "Humphreys, Tennessee, US",
  "Jackson, Tennessee, US",
  "Jefferson, Tennessee, US",
  "Johnson, Tennessee, US",
  "Knox, Tennessee, US",
  "Lake, Tennessee, US",
  "Lauderdale, Tennessee, US",
  "Lawrence, Tennessee, US",
  "Lewis, Tennessee, US",
  "Lincoln, Tennessee, US",
  "Loudon, Tennessee, US",
  "McMinn, Tennessee, US",
  "McNairy, Tennessee, US",
  "Macon, Tennessee, US",
  "Madison, Tennessee, US",
  "Marion, Tennessee, US",
  "Marshall, Tennessee, US",
  "Maury, Tennessee, US",
  "Meigs, Tennessee, US",
  "Monroe, Tennessee, US",
  "Montgomery, Tennessee, US",
  "Moore, Tennessee, US",
  "Morgan, Tennessee, US",
  "Obion, Tennessee, US",
  "Overton, Tennessee, US",
  "Perry, Tennessee, US",
  "Pickett, Tennessee, US",
  "Polk, Tennessee, US",
  "Putnam, Tennessee, US",
  "Rhea, Tennessee, US",
  "Roane, Tennessee, US",
  "Robertson, Tennessee, US",
  "Rutherford, Tennessee, US",
  "Scott, Tennessee, US",
  "Sequatchie, Tennessee, US",
  "Sevier, Tennessee, US",
  "Shelby, Tennessee, US",
  "Smith, Tennessee, US",
  "Stewart, Tennessee, US",
  "Sullivan, Tennessee, US",
  "Sumner, Tennessee, US",
  "Tipton, Tennessee, US",
  "Trousdale, Tennessee, US",
  "Unicoi, Tennessee, US",
  "Union, Tennessee, US",
  "Van Buren, Tennessee, US",
  "Warren, Tennessee, US",
  "Washington, Tennessee, US",
  "Wayne, Tennessee, US",
  "Weakley, Tennessee, US",
  "White, Tennessee, US",
  "Williamson, Tennessee, US",
  "Wilson, Tennessee, US",
  "Anderson, Texas, US",
  "Andrews, Texas, US",
  "Angelina, Texas, US",
  "Aransas, Texas, US",
  "Archer, Texas, US",
  "Armstrong, Texas, US",
  "Atascosa, Texas, US",
  "Austin, Texas, US",
  "Bailey, Texas, US",
  "Bandera, Texas, US",
  "Bastrop, Texas, US",
  "Baylor, Texas, US",
  "Bee, Texas, US",
  "Bell, Texas, US",
  "Bexar, Texas, US",
  "Blanco, Texas, US",
  "Borden, Texas, US",
  "Bosque, Texas, US",
  "Bowie, Texas, US",
  "Brazoria, Texas, US",
  "Brazos, Texas, US",
  "Brewster, Texas, US",
  "Briscoe, Texas, US",
  "Brooks, Texas, US",
  "Brown, Texas, US",
  "Burleson, Texas, US",
  "Burnet, Texas, US",
  "Caldwell, Texas, US",
  "Calhoun, Texas, US",
  "Callahan, Texas, US",
  "Cameron, Texas, US",
  "Camp, Texas, US",
  "Carson, Texas, US",
  "Cass, Texas, US",
  "Castro, Texas, US",
  "Chambers, Texas, US",
  "Cherokee, Texas, US",
  "Childress, Texas, US",
  "Clay, Texas, US",
  "Cochran, Texas, US",
  "Coke, Texas, US",
  "Coleman, Texas, US",
  "Collin, Texas, US",
  "Collingsworth, Texas, US",
  "Colorado, Texas, US",
  "Comal, Texas, US",
  "Comanche, Texas, US",
  "Concho, Texas, US",
  "Cooke, Texas, US",
  "Coryell, Texas, US",
  "Cottle, Texas, US",
  "Crane, Texas, US",
  "Crockett, Texas, US",
  "Crosby, Texas, US",
  "Culberson, Texas, US",
  "Dallam, Texas, US",
  "Dallas, Texas, US",
  "Dawson, Texas, US",
  "Deaf Smith, Texas, US",
  "Delta, Texas, US",
  "Denton, Texas, US",
  "DeWitt, Texas, US",
  "Dickens, Texas, US",
  "Dimmit, Texas, US",
  "Donley, Texas, US",
  "Duval, Texas, US",
  "Eastland, Texas, US",
  "Ector, Texas, US",
  "Edwards, Texas, US",
  "Ellis, Texas, US",
  "El Paso, Texas, US",
  "Erath, Texas, US",
  "Falls, Texas, US",
  "Fannin, Texas, US",
  "Fayette, Texas, US",
  "Fisher, Texas, US",
  "Floyd, Texas, US",
  "Foard, Texas, US",
  "Fort Bend, Texas, US",
  "Franklin, Texas, US",
  "Freestone, Texas, US",
  "Frio, Texas, US",
  "Gaines, Texas, US",
  "Galveston, Texas, US",
  "Garza, Texas, US",
  "Gillespie, Texas, US",
  "Glasscock, Texas, US",
  "Goliad, Texas, US",
  "Gonzales, Texas, US",
  "Gray, Texas, US",
  "Grayson, Texas, US",
  "Gregg, Texas, US",
  "Grimes, Texas, US",
  "Guadalupe, Texas, US",
  "Hale, Texas, US",
  "Hall, Texas, US",
  "Hamilton, Texas, US",
  "Hansford, Texas, US",
  "Hardeman, Texas, US",
  "Hardin, Texas, US",
  "Harris, Texas, US",
  "Harrison, Texas, US",
  "Hartley, Texas, US",
  "Haskell, Texas, US",
  "Hays, Texas, US",
  "Hemphill, Texas, US",
  "Henderson, Texas, US",
  "Hidalgo, Texas, US",
  "Hill, Texas, US",
  "Hockley, Texas, US",
  "Hood, Texas, US",
  "Hopkins, Texas, US",
  "Houston, Texas, US",
  "Howard, Texas, US",
  "Hudspeth, Texas, US",
  "Hunt, Texas, US",
  "Hutchinson, Texas, US",
  "Irion, Texas, US",
  "Jack, Texas, US",
  "Jackson, Texas, US",
  "Jasper, Texas, US",
  "Jeff Davis, Texas, US",
  "Jefferson, Texas, US",
  "Jim Hogg, Texas, US",
  "Jim Wells, Texas, US",
  "Johnson, Texas, US",
  "Jones, Texas, US",
  "Karnes, Texas, US",
  "Kaufman, Texas, US",
  "Kendall, Texas, US",
  "Kenedy, Texas, US",
  "Kent, Texas, US",
  "Kerr, Texas, US",
  "Kimble, Texas, US",
  "King, Texas, US",
  "Kinney, Texas, US",
  "Kleberg, Texas, US",
  "Knox, Texas, US",
  "Lamar, Texas, US",
  "Lamb, Texas, US",
  "Lampasas, Texas, US",
  "La Salle, Texas, US",
  "Lavaca, Texas, US",
  "Lee, Texas, US",
  "Leon, Texas, US",
  "Liberty, Texas, US",
  "Limestone, Texas, US",
  "Lipscomb, Texas, US",
  "Live Oak, Texas, US",
  "Llano, Texas, US",
  "Loving, Texas, US",
  "Lubbock, Texas, US",
  "Lynn, Texas, US",
  "McCulloch, Texas, US",
  "McLennan, Texas, US",
  "McMullen, Texas, US",
  "Madison, Texas, US",
  "Marion, Texas, US",
  "Martin, Texas, US",
  "Mason, Texas, US",
  "Matagorda, Texas, US",
  "Maverick, Texas, US",
  "Medina, Texas, US",
  "Menard, Texas, US",
  "Midland, Texas, US",
  "Milam, Texas, US",
  "Mills, Texas, US",
  "Mitchell, Texas, US",
  "Montague, Texas, US",
  "Montgomery, Texas, US",
  "Moore, Texas, US",
  "Morris, Texas, US",
  "Motley, Texas, US",
  "Nacogdoches, Texas, US",
  "Navarro, Texas, US",
  "Newton, Texas, US",
  "Nolan, Texas, US",
  "Nueces, Texas, US",
  "Ochiltree, Texas, US",
  "Oldham, Texas, US",
  "Orange, Texas, US",
  "Palo Pinto, Texas, US",
  "Panola, Texas, US",
  "Parker, Texas, US",
  "Parmer, Texas, US",
  "Pecos, Texas, US",
  "Polk, Texas, US",
  "Potter, Texas, US",
  "Presidio, Texas, US",
  "Rains, Texas, US",
  "Randall, Texas, US",
  "Reagan, Texas, US",
  "Real, Texas, US",
  "Red River, Texas, US",
  "Reeves, Texas, US",
  "Refugio, Texas, US",
  "Roberts, Texas, US",
  "Robertson, Texas, US",
  "Rockwall, Texas, US",
  "Runnels, Texas, US",
  "Rusk, Texas, US",
  "Sabine, Texas, US",
  "San Augustine, Texas, US",
  "San Jacinto, Texas, US",
  "San Patricio, Texas, US",
  "San Saba, Texas, US",
  "Schleicher, Texas, US",
  "Scurry, Texas, US",
  "Shackelford, Texas, US",
  "Shelby, Texas, US",
  "Sherman, Texas, US",
  "Smith, Texas, US",
  "Somervell, Texas, US",
  "Starr, Texas, US",
  "Stephens, Texas, US",
  "Sterling, Texas, US",
  "Stonewall, Texas, US",
  "Sutton, Texas, US",
  "Swisher, Texas, US",
  "Tarrant, Texas, US",
  "Taylor, Texas, US",
  "Terrell, Texas, US",
  "Terry, Texas, US",
  "Throckmorton, Texas, US",
  "Titus, Texas, US",
  "Tom Green, Texas, US",
  "Travis, Texas, US",
  "Trinity, Texas, US",
  "Tyler, Texas, US",
  "Upshur, Texas, US",
  "Upton, Texas, US",
  "Uvalde, Texas, US",
  "Val Verde, Texas, US",
  "Van Zandt, Texas, US",
  "Victoria, Texas, US",
  "Walker, Texas, US",
  "Waller, Texas, US",
  "Ward, Texas, US",
  "Washington, Texas, US",
  "Webb, Texas, US",
  "Wharton, Texas, US",
  "Wheeler, Texas, US",
  "Wichita, Texas, US",
  "Wilbarger, Texas, US",
  "Willacy, Texas, US",
  "Williamson, Texas, US",
  "Wilson, Texas, US",
  "Winkler, Texas, US",
  "Wise, Texas, US",
  "Wood, Texas, US",
  "Yoakum, Texas, US",
  "Young, Texas, US",
  "Zapata, Texas, US",
  "Zavala, Texas, US",
  "Beaver, Utah, US",
  "Box Elder, Utah, US",
  "Cache, Utah, US",
  "Carbon, Utah, US",
  "Daggett, Utah, US",
  "Davis, Utah, US",
  "Duchesne, Utah, US",
  "Emery, Utah, US",
  "Garfield, Utah, US",
  "Grand, Utah, US",
  "Iron, Utah, US",
  "Juab, Utah, US",
  "Kane, Utah, US",
  "Millard, Utah, US",
  "Morgan, Utah, US",
  "Piute, Utah, US",
  "Rich, Utah, US",
  "Salt Lake, Utah, US",
  "San Juan, Utah, US",
  "Sanpete, Utah, US",
  "Sevier, Utah, US",
  "Summit, Utah, US",
  "Tooele, Utah, US",
  "Uintah, Utah, US",
  "Utah, Utah, US",
  "Wasatch, Utah, US",
  "Washington,Utah,US",
  "Wayne, Utah, US",
  "Weber,Utah,US",
  "Addison, Vermont, US",
  "Bennington, Vermont, US",
  "Caledonia, Vermont, US",
  "Chittenden, Vermont, US",
  "Essex, Vermont, US",
  "Franklin, Vermont, US",
  "Grand Isle, Vermont, US",
  "Lamoille, Vermont, US",
  "Orange, Vermont, US",
  "Orleans, Vermont, US",
  "Rutland, Vermont, US",
  "Washington, Vermont, US",
  "Windham, Vermont, US",
  "Windsor, Vermont, US",
  "Accomack, Virginia, US",
  "Albemarle, Virginia, US",
  "Alleghany, Virginia, US",
  "Amelia, Virginia, US",
  "Amherst, Virginia, US",
  "Appomattox, Virginia, US",
  "Arlington, Virginia, US",
  "Augusta, Virginia, US",
  "Bath, Virginia, US",
  "Bedford, Virginia, US",
  "Bland, Virginia, US",
  "Botetourt, Virginia, US",
  "Brunswick, Virginia, US",
  "Buchanan, Virginia, US",
  "Buckingham, Virginia, US",
  "Campbell, Virginia, US",
  "Caroline, Virginia, US",
  "Carroll, Virginia, US",
  "Charles City, Virginia, US",
  "Charlotte, Virginia, US",
  "Chesterfield, Virginia, US",
  "Clarke, Virginia, US",
  "Craig, Virginia, US",
  "Culpeper, Virginia, US",
  "Cumberland, Virginia, US",
  "Dickenson, Virginia, US",
  "Dinwiddie, Virginia, US",
  "Essex, Virginia, US",
  "Fairfax, Virginia, US",
  "Fauquier, Virginia, US",
  "Floyd, Virginia, US",
  "Fluvanna, Virginia, US",
  "Franklin, Virginia, US",
  "Frederick, Virginia, US",
  "Giles, Virginia, US",
  "Gloucester, Virginia, US",
  "Goochland, Virginia, US",
  "Grayson, Virginia, US",
  "Greene, Virginia, US",
  "Greensville, Virginia, US",
  "Halifax, Virginia, US",
  "Hanover, Virginia, US",
  "Henrico, Virginia, US",
  "Henry, Virginia, US",
  "Highland, Virginia, US",
  "Isle of Wight, Virginia, US",
  "James City, Virginia, US",
  "King and Queen, Virginia, US",
  "King George, Virginia, US",
  "King William, Virginia, US",
  "Lancaster, Virginia, US",
  "Lee, Virginia, US",
  "Loudoun, Virginia, US",
  "Louisa, Virginia, US",
  "Lunenburg, Virginia, US",
  "Madison, Virginia, US",
  "Mathews, Virginia, US",
  "Mecklenburg, Virginia, US",
  "Middlesex, Virginia, US",
  "Montgomery, Virginia, US",
  "Nelson, Virginia, US",
  "New Kent, Virginia, US",
  "Northampton, Virginia, US",
  "Northumberland, Virginia, US",
  "Nottoway, Virginia, US",
  "Orange, Virginia, US",
  "Page, Virginia, US",
  "Patrick, Virginia, US",
  "Pittsylvania, Virginia, US",
  "Powhatan, Virginia, US",
  "Prince Edward, Virginia, US",
  "Prince George, Virginia, US",
  "Prince William, Virginia, US",
  "Pulaski, Virginia, US",
  "Rappahannock, Virginia, US",
  "Richmond, Virginia, US",
  "Roanoke, Virginia, US",
  "Rockbridge, Virginia, US",
  "Rockingham, Virginia, US",
  "Russell, Virginia, US",
  "Scott, Virginia, US",
  "Shenandoah, Virginia, US",
  "Smyth, Virginia, US",
  "Southampton, Virginia, US",
  "Spotsylvania, Virginia, US",
  "Stafford, Virginia, US",
  "Surry, Virginia, US",
  "Sussex, Virginia, US",
  "Tazewell, Virginia, US",
  "Warren, Virginia, US",
  "Washington, Virginia, US",
  "Westmoreland, Virginia, US",
  "Wise, Virginia, US",
  "Wythe, Virginia, US",
  "York, Virginia, US",
  "Alexandria, Virginia, US",
  "Bristol, Virginia, US",
  "Buena Vista, Virginia, US",
  "Charlottesville, Virginia, US",
  "Chesapeake, Virginia, US",
  "Colonial Heights, Virginia, US",
  "Covington, Virginia, US",
  "Danville, Virginia, US",
  "Emporia, Virginia, US",
  "Fairfax City, Virginia, US",
  "Falls Church, Virginia, US",
  "Franklin City, Virginia, US",
  "Fredericksburg, Virginia, US",
  "Galax, Virginia, US",
  "Hampton, Virginia, US",
  "Harrisonburg, Virginia, US",
  "Hopewell, Virginia, US",
  "Lexington, Virginia, US",
  "Lynchburg, Virginia, US",
  "Manassas,Virginia,US",
  "Manassas Park, Virginia, US",
  "Martinsville, Virginia, US",
  "Newport News, Virginia, US",
  "Norfolk, Virginia, US",
  "Norton, Virginia, US",
  "Petersburg, Virginia, US",
  "Poquoson, Virginia, US",
  "Portsmouth, Virginia, US",
  "Radford, Virginia, US",
  "Richmond City, Virginia, US",
  "Roanoke City, Virginia, US",
  "Salem, Virginia, US",
  "Staunton, Virginia, US",
  "Suffolk, Virginia, US",
  "Virginia Beach, Virginia, US",
  "Waynesboro, Virginia, US",
  "Williamsburg, Virginia, US",
  "Winchester, Virginia, US",
  "Adams, Washington, US",
  "Asotin, Washington, US",
  "Benton, Washington, US",
  "Chelan, Washington, US",
  "Clallam, Washington, US",
  "Clark, Washington, US",
  "Columbia, Washington, US",
  "Cowlitz, Washington, US",
  "Douglas, Washington, US",
  "Ferry, Washington, US",
  "Franklin, Washington, US",
  "Garfield, Washington, US",
  "Grant, Washington, US",
  "Grays Harbor, Washington, US",
  "Island, Washington, US",
  "Jefferson, Washington, US",
  "King, Washington, US",
  "Kitsap, Washington, US",
  "Kittitas, Washington, US",
  "Klickitat, Washington, US",
  "Lewis, Washington, US",
  "Lincoln, Washington, US",
  "Mason, Washington, US",
  "Okanogan, Washington, US",
  "Pacific, Washington, US",
  "Pend Oreille, Washington, US",
  "Pierce, Washington, US",
  "San Juan, Washington, US",
  "Skagit, Washington, US",
  "Skamania, Washington, US",
  "Snohomish, Washington, US",
  "Spokane, Washington, US",
  "Stevens, Washington, US",
  "Thurston, Washington, US",
  "Wahkiakum, Washington, US",
  "Walla Walla, Washington, US",
  "Whatcom, Washington, US",
  "Whitman, Washington, US",
  "Yakima, Washington, US",
  "Barbour, West Virginia, US",
  "Berkeley, West Virginia, US",
  "Boone, West Virginia, US",
  "Braxton, West Virginia, US",
  "Brooke, West Virginia, US",
  "Cabell, West Virginia, US",
  "Calhoun, West Virginia, US",
  "Clay, West Virginia, US",
  "Doddridge, West Virginia, US",
  "Fayette, West Virginia, US",
  "Gilmer, West Virginia, US",
  "Grant, West Virginia, US",
  "Greenbrier, West Virginia, US",
  "Hampshire, West Virginia, US",
  "Hancock, West Virginia, US",
  "Hardy, West Virginia, US",
  "Harrison, West Virginia, US",
  "Jackson, West Virginia, US",
  "Jefferson, West Virginia, US",
  "Kanawha, West Virginia, US",
  "Lewis, West Virginia, US",
  "Lincoln, West Virginia, US",
  "Logan, West Virginia, US",
  "McDowell, West Virginia, US",
  "Marion, West Virginia, US",
  "Marshall, West Virginia, US",
  "Mason, West Virginia, US",
  "Mercer, West Virginia, US",
  "Mineral, West Virginia, US",
  "Mingo, West Virginia, US",
  "Monongalia, West Virginia, US",
  "Monroe, West Virginia, US",
  "Morgan, West Virginia, US",
  "Nicholas, West Virginia, US",
  "Ohio, West Virginia, US",
  "Pendleton, West Virginia, US",
  "Pleasants, West Virginia, US",
  "Pocahontas, West Virginia, US",
  "Preston, West Virginia, US",
  "Putnam, West Virginia, US",
  "Raleigh, West Virginia, US",
  "Randolph, West Virginia, US",
  "Ritchie, West Virginia, US",
  "Roane, West Virginia, US",
  "Summers, West Virginia, US",
  "Taylor, West Virginia, US",
  "Tucker, West Virginia, US",
  "Tyler, West Virginia, US",
  "Upshur, West Virginia, US",
  "Wayne, West Virginia, US",
  "Webster, West Virginia, US",
  "Wetzel, West Virginia, US",
  "Wirt, West Virginia, US",
  "Wood, West Virginia, US",
  "Wyoming, West Virginia, US",
  "Adams, Wisconsin, US",
  "Ashland, Wisconsin, US",
  "Barron, Wisconsin, US",
  "Bayfield, Wisconsin, US",
  "Brown, Wisconsin, US",
  "Buffalo, Wisconsin, US",
  "Burnett, Wisconsin, US",
  "Calumet, Wisconsin, US",
  "Chippewa, Wisconsin, US",
  "Clark, Wisconsin, US",
  "Columbia, Wisconsin, US",
  "Crawford, Wisconsin, US",
  "Dane, Wisconsin, US",
  "Dodge, Wisconsin, US",
  "Door, Wisconsin, US",
  "Douglas, Wisconsin, US",
  "Dunn, Wisconsin, US",
  "Eau Claire, Wisconsin, US",
  "Florence, Wisconsin, US",
  "Fond du Lac, Wisconsin, US",
  "Forest, Wisconsin, US",
  "Grant, Wisconsin, US",
  "Green, Wisconsin, US",
  "Green Lake, Wisconsin, US",
  "Iowa, Wisconsin, US",
  "Iron, Wisconsin, US",
  "Jackson, Wisconsin, US",
  "Jefferson, Wisconsin, US",
  "Juneau, Wisconsin, US",
  "Kenosha, Wisconsin, US",
  "Kewaunee, Wisconsin, US",
  "La Crosse, Wisconsin, US",
  "Lafayette, Wisconsin, US",
  "Langlade, Wisconsin, US",
  "Lincoln, Wisconsin, US",
  "Manitowoc, Wisconsin, US",
  "Marathon, Wisconsin, US",
  "Marinette, Wisconsin, US",
  "Marquette, Wisconsin, US",
  "Menominee, Wisconsin, US",
  "Milwaukee, Wisconsin, US",
  "Monroe, Wisconsin, US",
  "Oconto, Wisconsin, US",
  "Oneida, Wisconsin, US",
  "Outagamie, Wisconsin, US",
  "Ozaukee, Wisconsin, US",
  "Pepin, Wisconsin, US",
  "Pierce, Wisconsin, US",
  "Polk, Wisconsin, US",
  "Portage, Wisconsin, US",
  "Price, Wisconsin, US",
  "Racine, Wisconsin, US",
  "Richland, Wisconsin, US",
  "Rock, Wisconsin, US",
  "Rusk, Wisconsin, US",
  "St. Croix, Wisconsin, US",
  "Sauk, Wisconsin, US",
  "Sawyer, Wisconsin, US",
  "Shawano, Wisconsin, US",
  "Sheboygan, Wisconsin, US",
  "Taylor, Wisconsin, US",
  "Trempealeau, Wisconsin, US",
  "Vernon, Wisconsin, US",
  "Vilas, Wisconsin, US",
  "Walworth, Wisconsin, US",
  "Washburn, Wisconsin, US",
  "Washington, Wisconsin, US",
  "Waukesha, Wisconsin, US",
  "Waupaca, Wisconsin, US",
  "Waushara, Wisconsin, US",
  "Winnebago, Wisconsin, US",
  "Wood, Wisconsin, US",
  "Albany, Wyoming, US",
  "Big Horn, Wyoming, US",
  "Campbell, Wyoming, US",
  "Carbon, Wyoming, US",
  "Converse, Wyoming, US",
  "Crook, Wyoming, US",
  "Fremont, Wyoming, US",
  "Goshen, Wyoming, US",
  "Hot Springs, Wyoming, US",
  "Johnson, Wyoming, US",
  "Laramie, Wyoming, US",
  "Lincoln, Wyoming, US",
  "Natrona, Wyoming, US",
  "Niobrara, Wyoming, US",
  "Park, Wyoming, US",
  "Platte, Wyoming, US",
  "Sheridan, Wyoming, US",
  "Sublette, Wyoming, US",
  "Sweetwater, Wyoming, US",
  "Teton, Wyoming, US",
  "Uinta, Wyoming, US",
  "Washakie, Wyoming, US",
  "Weston, Wyoming, US",
  // "Out of AL, Alabama, US",
  // "Out of AK, Alaska, US",
  // "Out of AZ, Arizona, US",
  // "Out of AR, Arkansas, US",
  // "Out of CA, California, US",
  // "Out of CO, Colorado, US",
  // "Out of CT, Connecticut, US",
  // "Out of DE, Delaware, US",
  // "Out of DC, District of Columbia, US",
  // "Out of FL, Florida, US",
  // "Out of GA, Georgia, US",
  // "Out of HI, Hawaii, US",
  // "Out of ID, Idaho, US",
  // "Out of IL, Illinois, US",
  // "Out of IN, Indiana, US",
  // "Out of IA, Iowa, US",
  // "Out of KS, Kansas, US",
  // "Out of KY, Kentucky, US",
  // "Out of LA, Louisiana, US",
  // "Out of ME, Maine, US",
  // "Out of MD, Maryland, US",
  // "Out of MA, Massachusetts, US",
  // "Out of MI, Michigan, US",
  // "Out of MN, Minnesota, US",
  // "Out of MS, Mississippi, US",
  // "Out of MO, Missouri, US",
  // "Out of MT, Montana, US",
  // "Out of NE, Nebraska, US",
  // "Out of NV, Nevada, US",
  // "Out of NH, New Hampshire, US",
  // "Out of NJ, New Jersey, US",
  // "Out of NM, New Mexico, US",
  // "Out of NY, New York, US",
  // "Out of NC, North Carolina, US",
  // "Out of ND, North Dakota, US",
  // "Out of OH, Ohio, US",
  // "Out of OK, Oklahoma, US",
  // "Out of OR, Oregon, US",
  // "Out of PA, Pennsylvania, US",
  // "Out of RI, Rhode Island, US",
  // "Out of SC, South Carolina, US",
  // "Out of SD, South Dakota, US",
  // "Out of TN, Tennessee, US",
  // "Out of TX, Texas, US",
  // "Out of UT, Utah, US",
  // "Out of VT, Vermont, US",
  // "Out of VA, Virginia, US",
  // "Out of WA, Washington, US",
  // "Out of WV, West Virginia, US",
  // "Out of WI, Wisconsin, US",
  // "Out of WY, Wyoming, US",
  // "Diamond Princess, US",
  // "Unassigned, Alabama, US",
  // "Unassigned, Alaska, US",
  // "Unassigned, Arizona, US",
  // "Unassigned, Arkansas, US",
  // "Unassigned, California, US",
  // "Unassigned, Colorado, US",
  // "Unassigned, Connecticut, US",
  // "Unassigned, Delaware, US",
  // "Unassigned, District of Columbia, US",
  // "Unassigned, Florida, US",
  // "Unassigned, Georgia, US",
  // "Unassigned, Hawaii, US",
  // "Unassigned, Idaho, US",
  // "Unassigned, Illinois, US",
  // "Unassigned, Indiana, US",
  // "Unassigned, Iowa, US",
  // "Unassigned, Kansas, US",
  // "Unassigned, Kentucky, US",
  // "Unassigned, Louisiana, US",
  // "Unassigned, Maine, US",
  // "Unassigned, Maryland, US",
  // "Unassigned, Massachusetts, US",
  // "Unassigned, Michigan, US",
  // "Unassigned, Minnesota, US",
  // "Unassigned, Mississippi, US",
  // "Unassigned, Missouri, US",
  // "Unassigned, Montana, US",
  // "Unassigned, Nebraska, US",
  // "Unassigned, Nevada, US",
  // "Unassigned, New Hampshire, US",
  // "Unassigned, New Jersey, US",
  // "Unassigned, New Mexico, US",
  // "Unassigned, New York, US",
  // "Unassigned, North Carolina, US",
  // "Unassigned, North Dakota, US",
  // "Unassigned, Ohio, US",
  // "Unassigned, Oklahoma, US",
  // "Unassigned, Oregon, US",
  // "Unassigned, Pennsylvania, US",
  // "Unassigned, Rhode Island, US",
  // "Unassigned, South Carolina, US",
  // "Unassigned, South Dakota, US",
  // "Unassigned, Tennessee, US",
  // "Unassigned, Texas, US",
  // "Unassigned, Utah, US",
  // "Unassigned, Vermont, US",
  // "Unassigned, Virginia, US",
  // "Unassigned, Washington, US",
  // "Unassigned, West Virginia, US",
  // "Unassigned, Wisconsin, US",
  // "Unassigned, Wyoming, US",
  // "Grand Princess, US",
  "Dukes and Nantucket,Massachusetts,US",
  "Kansas City,Missouri,US",
  "Michigan Department of Corrections (MDOC), Michigan, US",
  "Federal Correctional Institution (FCI), Michigan, US",
  "Bear River, Utah, US",
  "Central Utah, Utah, US",
  "Southeast Utah, Utah, US",
  "Southwest Utah, Utah, US",
  "TriCounty, Utah, US",
];

const mostPopulated = [
  "Los Angeles, Los Angeles, California",
  "New York City, New York City, New York",
  "Chicago, Cook, Illinois",
  "Houston, Harris, Texas",
  "Phoenix, Maricopa, Arizona",
  "San Diego, San Diego, California",
  "Santa Ana, Orange, California",
  // "Miami, Miami-Dade, Florida",
  "Dallas, Dallas, Texas",
  "Riverside, Riverside, California",
  "Seattle, King, Washington",
  "Las Vegas, Clark, Nevada",
  "Detroit, Wayne, Michigan",
];

const USSearchForm = () => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [cityState, setCityState] = useState(cityNames);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // console.log(cityState.length);

  const cleanTerm = _.startCase(searchTerm.trim().toLocaleLowerCase());

  const results = !cleanTerm || cleanTerm.length < 3 ? [] : cities.filter((city) => city.includes(cleanTerm));

  return (
    <div className="mt-1">
      {/*{cities.length}*/}
      <Card body className="py-2 pb-3 px-1 py-md-3 px-md-2">
        <h6 className="opacity-3">United States County Search</h6>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <Input
            className="text-center mt-2"
            type="text"
            placeholder="enter county or state"
            value={searchTerm}
            onChange={handleChange}
          />
        </Col>
        {cleanTerm.length > 2 && results.length < 1 && (
          <div>
            <div className="text-black-50">county/region not found, please double check your spelling</div>
          </div>
        )}
        <div style={{ overflow: "auto", maxHeight: "200px" }}>
          {results.map((item) => (
            <Button
              key={item}
              className="country-btn mt-0 mt-sm-1 mb-1 line-h-1"
              tag={Link}
              to={`/t/covid19/data/us/l?city=${hyphen(item.split(",")[0])}&state=${hyphen(item.split(",")[1].trim())}`}
            >
              {/*{item.substring(0, item.length-4)}*/}
              {item.split(",")[0]}{" "}
              <div>
                <small className="opacity-3">{item.split(",")[1].trim()}</small>
              </div>
            </Button>
          ))}
        </div>
      </Card>

      <Card body className="py-2 pb-3 px-1 py-md-3 px-md-2 mt-3">
        <div>Most Populated Cities</div>
        <Row className="px-2">
          {mostPopulated.map((item, i) => (
            <Col xs="6" className="p-1" key={i + "zz00zz"}>
              <Button
                block
                key={item + "-pop"}
                // className="country-btn mt-0 mt-sm-1 mb-1 line-h-1"
                className="country-btn m-auto line-h-1"
                tag={Link}
                to={`/t/covid19/data/us/l?city=${hyphen(item.split(",")[1].trim())}&state=${hyphen(
                  item.split(",")[2].trim()
                )}`}
              >
                {/*{item.substring(0, item.length-4)}*/}
                {item.split(",")[0]}{" "}
                <div>
                  <small className="opacity-3">
                    {item.split(",")[1].trim() === "New York City" ? (
                      "NYC (All Boroughs)"
                    ) : (
                      <>{item.split(",")[1].trim()} County</>
                    )}
                    , {item.split(",")[2].trim()}
                  </small>
                </div>
              </Button>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default USSearchForm;

// const citiesOG = [
//   "Autauga",
//   "Baldwin",
//   "Barbour",
//   "Bibb",
//   "Blount",
//   "Bullock",
//   "Butler",
//   "Calhoun",
//   "Chambers",
//   "Cherokee",
//   "Chilton",
//   "Choctaw",
//   "Clarke",
//   "Clay",
//   "Cleburne",
//   "Coffee",
//   "Colbert",
//   "Conecuh",
//   "Coosa",
//   "Covington",
//   "Crenshaw",
//   "Cullman",
//   "Dale",
//   "Dallas",
//   "DeKalb",
//   "Elmore",
//   "Escambia",
//   "Etowah",
//   "Fayette",
//   "Franklin",
//   "Geneva",
//   "Greene",
//   "Hale",
//   "Henry",
//   "Houston",
//   "Jackson",
//   "Jefferson",
//   "Lamar",
//   "Lauderdale",
//   "Lawrence",
//   "Lee",
//   "Limestone",
//   "Lowndes",
//   "Macon",
//   "Madison",
//   "Marengo",
//   "Marion",
//   "Marshall",
//   "Mobile",
//   "Monroe",
//   "Montgomery",
//   "Morgan",
//   "Perry",
//   "Pickens",
//   "Pike",
//   "Randolph",
//   "Russell",
//   "St. Clair",
//   "Shelby",
//   "Sumter",
//   "Talladega",
//   "Tallapoosa",
//   "Tuscaloosa",
//   "Walker",
//   "Washington",
//   "Wilcox",
//   "Winston",
//   "Aleutians East",
//   "Aleutians West",
//   "Anchorage",
//   "Bethel",
//   "Bristol Bay",
//   "Denali",
//   "Dillingham",
//   "Fairbanks North Star",
//   "Haines",
//   "Hoonah-Angoon",
//   "Juneau",
//   "Kenai Peninsula",
//   "Ketchikan Gateway",
//   "Kodiak Island",
//   "Kusilvak",
//   "Lake and Peninsula",
//   "Matanuska-Susitna",
//   "Nome",
//   "North Slope",
//   "Northwest Arctic",
//   "Petersburg",
//   "Prince of Wales-Hyder",
//   "Sitka",
//   "Skagway",
//   "Southeast Fairbanks",
//   "Valdez-Cordova",
//   "Wrangell",
//   "Yakutat",
//   "Yukon-Koyukuk",
//   "Apache",
//   "Cochise",
//   "Coconino",
//   "Gila",
//   "Graham",
//   "Greenlee",
//   "La Paz",
//   "Maricopa",
//   "Mohave",
//   "Navajo",
//   "Pima",
//   "Pinal",
//   "Santa Cruz",
//   "Yavapai",
//   "Yuma",
//   "Arkansas",
//   "Ashley",
//   "Baxter",
//   "Benton",
//   "Boone",
//   "Bradley",
//   "",
//   "Carroll",
//   "Chicot",
//   "Clark",
//   "",
//   "",
//   "Cleveland",
//   "Columbia",
//   "Conway",
//   "Craighead",
//   "Crawford",
//   "Crittenden",
//   "Cross",
//   "",
//   "Desha",
//   "Drew",
//   "Faulkner",
//   "",
//   "Fulton",
//   "Garland",
//   "Grant",
//   "",
//   "Hempstead",
//   "Hot Spring",
//   "Howard",
//   "Independence",
//   "Izard",
//   "",
//   "",
//   "Johnson",
//   "Lafayette",
//   "",
//   "",
//   "Lincoln",
//   "Little River",
//   "Logan",
//   "Lonoke",
//   "",
//   "",
//   "Miller",
//   "Mississippi",
//   "",
//   "",
//   "Nevada",
//   "Newton",
//   "Ouachita",
//   "",
//   "Phillips",
//   "",
//   "Poinsett",
//   "Polk",
//   "Pope",
//   "Prairie",
//   "Pulaski",
//   "",
//   "St. Francis",
//   "Saline",
//   "Scott",
//   "Searcy",
//   "Sebastian",
//   "Sevier",
//   "Sharp",
//   "Stone",
//   "Union",
//   "Van Buren",
//   "",
//   "White",
//   "Woodruff",
//   "Yell",
//   "Alameda",
//   "Alpine",
//   "Amador",
//   "Butte",
//   "Calaveras",
//   "Colusa",
//   "Contra Costa",
//   "Del Norte",
//   "El Dorado",
//   "Fresno",
//   "Glenn",
//   "Humboldt",
//   "Imperial",
//   "Inyo",
//   "Kern",
//   "Kings",
//   "Lake",
//   "Lassen",
//   "Los Angeles",
//   "Madera",
//   "Marin",
//   "Mariposa",
//   "Mendocino",
//   "Merced",
//   "Modoc",
//   "Mono",
//   "Monterey",
//   "Napa",
//   "",
//   "Orange",
//   "Placer",
//   "Plumas",
//   "Riverside",
//   "Sacramento",
//   "San Benito",
//   "San Bernardino",
//   "San Diego",
//   "San Francisco",
//   "San Joaquin",
//   "San Luis Obispo",
//   "San Mateo",
//   "Santa Barbara",
//   "Santa Clara",
//   "",
//   "Shasta",
//   "Sierra",
//   "Siskiyou",
//   "Solano",
//   "Sonoma",
//   "Stanislaus",
//   "Sutter",
//   "Tehama",
//   "Trinity",
//   "Tulare",
//   "Tuolumne",
//   "Ventura",
//   "Yolo",
//   "Yuba",
//   "Adams",
//   "Alamosa",
//   "Arapahoe",
//   "Archuleta",
//   "Baca",
//   "Bent",
//   "Boulder",
//   "Broomfield",
//   "Chaffee",
//   "Cheyenne",
//   "Clear Creek",
//   "Conejos",
//   "Costilla",
//   "Crowley",
//   "Custer",
//   "Delta",
//   "Denver",
//   "Dolores",
//   "Douglas",
//   "Eagle",
//   "Elbert",
//   "El Paso",
//   "Fremont",
//   "Garfield",
//   "Gilpin",
//   "Grand",
//   "Gunnison",
//   "Hinsdale",
//   "Huerfano",
//   "",
//   "",
//   "Kiowa",
//   "Kit Carson",
//   "",
//   "La Plata",
//   "Larimer",
//   "Las Animas",
//   "",
//   "",
//   "Mesa",
//   "Mineral",
//   "Moffat",
//   "Montezuma",
//   "Montrose",
//   "",
//   "Otero",
//   "Ouray",
//   "Park",
//   "",
//   "Pitkin",
//   "Prowers",
//   "Pueblo",
//   "Rio Blanco",
//   "Rio Grande",
//   "Routt",
//   "Saguache",
//   "San Juan",
//   "San Miguel",
//   "Sedgwick",
//   "Summit",
//   "Teller",
//   "",
//   "Weld",
//   "",
//   "Fairfield",
//   "Hartford",
//   "Litchfield",
//   "Middlesex",
//   "New Haven",
//   "New London",
//   "Tolland",
//   "Windham",
//   "Kent",
//   "New Castle",
//   "Sussex",
//   "District of Columbia",
//   "Alachua",
//   "Baker",
//   "Bay",
//   "Bradford",
//   "Brevard",
//   "Broward",
//   "",
//   "Charlotte",
//   "Citrus",
//   "",
//   "Collier",
//   "",
//   "DeSoto",
//   "Dixie",
//   "Duval",
//   "",
//   "Flagler",
//   "",
//   "Gadsden",
//   "Gilchrist",
//   "Glades",
//   "Gulf",
//   "Hamilton",
//   "Hardee",
//   "Hendry",
//   "Hernando",
//   "Highlands",
//   "Hillsborough",
//   "Holmes",
//   "Indian River",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Leon",
//   "Levy",
//   "Liberty",
//   "",
//   "Manatee",
//   "",
//   "Martin",
//   "Miami-Dade",
//   "",
//   "Nassau",
//   "Okaloosa",
//   "Okeechobee",
//   "",
//   "Osceola",
//   "Palm Beach",
//   "Pasco",
//   "Pinellas",
//   "",
//   "Putnam",
//   "St. Johns",
//   "St. Lucie",
//   "Santa Rosa",
//   "Sarasota",
//   "Seminole",
//   "",
//   "Suwannee",
//   "Taylor",
//   "",
//   "Volusia",
//   "Wakulla",
//   "Walton",
//   "",
//   "Appling",
//   "Atkinson",
//   "Bacon",
//   "",
//   "",
//   "Banks",
//   "Barrow",
//   "Bartow",
//   "Ben Hill",
//   "Berrien",
//   "",
//   "Bleckley",
//   "Brantley",
//   "Brooks",
//   "Bryan",
//   "Bulloch",
//   "Burke",
//   "Butts",
//   "",
//   "Camden",
//   "Candler",
//   "",
//   "Catoosa",
//   "Charlton",
//   "Chatham",
//   "Chattahoochee",
//   "Chattooga",
//   "",
//   "",
//   "",
//   "Clayton",
//   "Clinch",
//   "Cobb",
//   "",
//   "Colquitt",
//   "",
//   "Cook",
//   "Coweta",
//   "",
//   "Crisp",
//   "Dade",
//   "Dawson",
//   "Decatur",
//   "",
//   "Dodge",
//   "Dooly",
//   "Dougherty",
//   "",
//   "Early",
//   "Echols",
//   "Effingham",
//   "",
//   "Emanuel",
//   "Evans",
//   "Fannin",
//   "",
//   "Floyd",
//   "Forsyth",
//   "",
//   "",
//   "Gilmer",
//   "Glascock",
//   "Glynn",
//   "Gordon",
//   "Grady",
//   "",
//   "Gwinnett",
//   "Habersham",
//   "Hall",
//   "Hancock",
//   "Haralson",
//   "Harris",
//   "Hart",
//   "Heard",
//   "",
//   "",
//   "Irwin",
//   "",
//   "Jasper",
//   "Jeff Davis",
//   "",
//   "Jenkins",
//   "",
//   "Jones",
//   "",
//   "Lanier",
//   "Laurens",
//   "",
//   "",
//   "",
//   "Long",
//   "",
//   "Lumpkin",
//   "McDuffie",
//   "McIntosh",
//   "",
//   "",
//   "",
//   "Meriwether",
//   "",
//   "Mitchell",
//   "",
//   "",
//   "",
//   "Murray",
//   "Muscogee",
//   "",
//   "Oconee",
//   "Oglethorpe",
//   "Paulding",
//   "Peach",
//   "",
//   "Pierce",
//   "",
//   "",
//   "",
//   "",
//   "Quitman",
//   "Rabun",
//   "",
//   "Richmond",
//   "Rockdale",
//   "Schley",
//   "Screven",
//   "",
//   "Spalding",
//   "Stephens",
//   "Stewart",
//   "",
//   "Talbot",
//   "Taliaferro",
//   "Tattnall",
//   "",
//   "Telfair",
//   "Terrell",
//   "Thomas",
//   "Tift",
//   "Toombs",
//   "Towns",
//   "Treutlen",
//   "Troup",
//   "Turner",
//   "Twiggs",
//   "",
//   "Upson",
//   "",
//   "",
//   "Ware",
//   "Warren",
//   "",
//   "Wayne",
//   "Webster",
//   "Wheeler",
//   "",
//   "Whitfield",
//   "",
//   "Wilkes",
//   "Wilkinson",
//   "Worth",
//   "Hawaii",
//   "Honolulu",
//   "Kalawao",
//   "Kauai",
//   "Maui",
//   "Ada",
//   "",
//   "Bannock",
//   "Bear Lake",
//   "Benewah",
//   "Bingham",
//   "Blaine",
//   "Boise",
//   "Bonner",
//   "Bonneville",
//   "Boundary",
//   "",
//   "Camas",
//   "Canyon",
//   "Caribou",
//   "Cassia",
//   "",
//   "Clearwater",
//   "",
//   "",
//   "",
//   "",
//   "Gem",
//   "Gooding",
//   "Idaho",
//   "",
//   "Jerome",
//   "Kootenai",
//   "Latah",
//   "Lemhi",
//   "Lewis",
//   "",
//   "",
//   "Minidoka",
//   "Nez Perce",
//   "Oneida",
//   "Owyhee",
//   "Payette",
//   "Power",
//   "Shoshone",
//   "Teton",
//   "Twin Falls",
//   "Valley",
//   "",
//   "",
//   "Alexander",
//   "Bond",
//   "",
//   "Brown",
//   "Bureau",
//   "",
//   "",
//   "Cass",
//   "Champaign",
//   "Christian",
//   "",
//   "",
//   "Clinton",
//   "Coles",
//   "",
//   "",
//   "Cumberland",
//   "",
//   "De Witt",
//   "",
//   "DuPage",
//   "Edgar",
//   "Edwards",
//   "",
//   "",
//   "Ford",
//   "",
//   "",
//   "Gallatin",
//   "",
//   "Grundy",
//   "",
//   "",
//   "Hardin",
//   "Henderson",
//   "",
//   "Iroquois",
//   "",
//   "",
//   "",
//   "Jersey",
//   "Jo Daviess",
//   "",
//   "Kane",
//   "Kankakee",
//   "Kendall",
//   "Knox",
//   "",
//   "LaSalle",
//   "",
//   "",
//   "Livingston",
//   "",
//   "McDonough",
//   "McHenry",
//   "McLean",
//   "",
//   "Macoupin",
//   "",
//   "",
//   "",
//   "Mason",
//   "Massac",
//   "Menard",
//   "Mercer",
//   "",
//   "",
//   "",
//   "Moultrie",
//   "Ogle",
//   "Peoria",
//   "",
//   "Piatt",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Richland",
//   "Rock Island",
//   "",
//   "",
//   "Sangamon",
//   "Schuyler",
//   "",
//   "",
//   "Stark",
//   "Stephenson",
//   "Tazewell",
//   "",
//   "Vermilion",
//   "Wabash",
//   "",
//   "",
//   "",
//   "",
//   "Whiteside",
//   "Will",
//   "Williamson",
//   "Winnebago",
//   "Woodford",
//   "",
//   "Allen",
//   "Bartholomew",
//   "",
//   "Blackford",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Daviess",
//   "Dearborn",
//   "",
//   "",
//   "Delaware",
//   "Dubois",
//   "Elkhart",
//   "",
//   "",
//   "Fountain",
//   "",
//   "",
//   "Gibson",
//   "",
//   "",
//   "",
//   "",
//   "Harrison",
//   "Hendricks",
//   "",
//   "",
//   "Huntington",
//   "",
//   "",
//   "Jay",
//   "",
//   "Jennings",
//   "",
//   "",
//   "Kosciusko",
//   "LaGrange",
//   "",
//   "LaPorte",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Miami",
//   "",
//   "",
//   "",
//   "",
//   "Noble",
//   "Ohio",
//   "",
//   "Owen",
//   "Parke",
//   "",
//   "",
//   "Porter",
//   "Posey",
//   "",
//   "",
//   "",
//   "Ripley",
//   "Rush",
//   "St. Joseph",
//   "",
//   "",
//   "Spencer",
//   "Starke",
//   "Steuben",
//   "Sullivan",
//   "Switzerland",
//   "Tippecanoe",
//   "Tipton",
//   "",
//   "Vanderburgh",
//   "Vermillion",
//   "Vigo",
//   "",
//   "",
//   "Warrick",
//   "",
//   "",
//   "Wells",
//   "",
//   "Whitley",
//   "Adair",
//   "",
//   "Allamakee",
//   "Appanoose",
//   "Audubon",
//   "",
//   "Black Hawk",
//   "",
//   "Bremer",
//   "Buchanan",
//   "Buena Vista",
//   "",
//   "",
//   "",
//   "",
//   "Cedar",
//   "Cerro Gordo",
//   "",
//   "Chickasaw",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Davis",
//   "",
//   "",
//   "Des Moines",
//   "Dickinson",
//   "Dubuque",
//   "Emmet",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Guthrie",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Ida",
//   "Iowa",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Keokuk",
//   "Kossuth",
//   "",
//   "Linn",
//   "Louisa",
//   "Lucas",
//   "Lyon",
//   "",
//   "Mahaska",
//   "",
//   "",
//   "Mills",
//   "",
//   "Monona",
//   "",
//   "",
//   "Muscatine",
//   "O'Brien",
//   "",
//   "Page",
//   "Palo Alto",
//   "Plymouth",
//   "Pocahontas",
//   "",
//   "Pottawattamie",
//   "Poweshiek",
//   "Ringgold",
//   "Sac",
//   "",
//   "",
//   "Sioux",
//   "Story",
//   "Tama",
//   "",
//   "",
//   "",
//   "Wapello",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Winneshiek",
//   "Woodbury",
//   "",
//   "Wright",
//   "",
//   "Anderson",
//   "Atchison",
//   "Barber",
//   "Barton",
//   "Bourbon",
//   "",
//   "",
//   "Chase",
//   "Chautauqua",
//   "",
//   "",
//   "",
//   "",
//   "Cloud",
//   "Coffey",
//   "Comanche",
//   "Cowley",
//   "",
//   "",
//   "",
//   "Doniphan",
//   "",
//   "",
//   "Elk",
//   "Ellis",
//   "Ellsworth",
//   "Finney",
//   "",
//   "",
//   "Geary",
//   "Gove",
//   "",
//   "",
//   "Gray",
//   "Greeley",
//   "Greenwood",
//   "",
//   "Harper",
//   "Harvey",
//   "Haskell",
//   "Hodgeman",
//   "",
//   "",
//   "Jewell",
//   "",
//   "Kearny",
//   "Kingman",
//   "",
//   "Labette",
//   "Lane",
//   "Leavenworth",
//   "",
//   "",
//   "",
//   "",
//   "McPherson",
//   "",
//   "",
//   "Meade",
//   "",
//   "",
//   "",
//   "Morris",
//   "Morton",
//   "Nemaha",
//   "Neosho",
//   "Ness",
//   "Norton",
//   "Osage",
//   "Osborne",
//   "Ottawa",
//   "Pawnee",
//   "",
//   "Pottawatomie",
//   "Pratt",
//   "Rawlins",
//   "Reno",
//   "Republic",
//   "Rice",
//   "Riley",
//   "Rooks",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Seward",
//   "Shawnee",
//   "Sheridan",
//   "Sherman",
//   "Smith",
//   "Stafford",
//   "Stanton",
//   "Stevens",
//   "Sumner",
//   "",
//   "Trego",
//   "Wabaunsee",
//   "Wallace",
//   "",
//   "Wichita",
//   "Wilson",
//   "Woodson",
//   "Wyandotte",
//   "",
//   "",
//   "",
//   "Ballard",
//   "Barren",
//   "Bath",
//   "Bell",
//   "",
//   "",
//   "Boyd",
//   "Boyle",
//   "Bracken",
//   "Breathitt",
//   "Breckinridge",
//   "Bullitt",
//   "",
//   "Caldwell",
//   "Calloway",
//   "Campbell",
//   "Carlisle",
//   "",
//   "Carter",
//   "Casey",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Edmonson",
//   "Elliott",
//   "Estill",
//   "",
//   "Fleming",
//   "",
//   "",
//   "",
//   "",
//   "Garrard",
//   "",
//   "Graves",
//   "Grayson",
//   "Green",
//   "Greenup",
//   "",
//   "",
//   "Harlan",
//   "",
//   "",
//   "",
//   "",
//   "Hickman",
//   "Hopkins",
//   "",
//   "",
//   "Jessamine",
//   "",
//   "Kenton",
//   "Knott",
//   "",
//   "Larue",
//   "Laurel",
//   "",
//   "",
//   "Leslie",
//   "Letcher",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "McCracken",
//   "McCreary",
//   "",
//   "",
//   "Magoffin",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Menifee",
//   "",
//   "Metcalfe",
//   "",
//   "",
//   "",
//   "Muhlenberg",
//   "Nelson",
//   "Nicholas",
//   "",
//   "Oldham",
//   "",
//   "Owsley",
//   "Pendleton",
//   "",
//   "",
//   "Powell",
//   "",
//   "Robertson",
//   "Rockcastle",
//   "Rowan",
//   "",
//   "",
//   "",
//   "Simpson",
//   "",
//   "",
//   "Todd",
//   "Trigg",
//   "Trimble",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Wolfe",
//   "",
//   "Acadia",
//   "",
//   "Ascension",
//   "Assumption",
//   "Avoyelles",
//   "Beauregard",
//   "Bienville",
//   "Bossier",
//   "Caddo",
//   "Calcasieu",
//   "",
//   "Cameron",
//   "Catahoula",
//   "Claiborne",
//   "Concordia",
//   "De Soto",
//   "East Baton Rouge",
//   "East Carroll",
//   "East Feliciana",
//   "Evangeline",
//   "",
//   "",
//   "Iberia",
//   "Iberville",
//   "",
//   "",
//   "Jefferson Davis",
//   "",
//   "Lafourche",
//   "",
//   "",
//   "",
//   "",
//   "Morehouse",
//   "Natchitoches",
//   "Orleans",
//   "",
//   "Plaquemines",
//   "Pointe Coupee",
//   "Rapides",
//   "Red River",
//   "",
//   "Sabine",
//   "St. Bernard",
//   "St. Charles",
//   "St. Helena",
//   "St. James",
//   "St. John the Baptist",
//   "St. Landry",
//   "St. Martin",
//   "St. Mary",
//   "St. Tammany",
//   "Tangipahoa",
//   "Tensas",
//   "Terrebonne",
//   "",
//   "",
//   "Vernon",
//   "",
//   "",
//   "West Baton Rouge",
//   "West Carroll",
//   "West Feliciana",
//   "Winn",
//   "Androscoggin",
//   "Aroostook",
//   "",
//   "",
//   "",
//   "Kennebec",
//   "",
//   "",
//   "Oxford",
//   "Penobscot",
//   "Piscataquis",
//   "Sagadahoc",
//   "Somerset",
//   "Waldo",
//   "",
//   "York",
//   "Allegany",
//   "Anne Arundel",
//   "Baltimore",
//   "Calvert",
//   "Caroline",
//   "",
//   "Cecil",
//   "Charles",
//   "Dorchester",
//   "Frederick",
//   "Garrett",
//   "Harford",
//   "",
//   "",
//   "",
//   "Prince George's",
//   "Queen Anne's",
//   "St. Mary's",
//   "",
//   "",
//   "",
//   "Wicomico",
//   "Worcester",
//   "Baltimore City",
//   "Barnstable",
//   "Berkshire",
//   "Bristol",
//   "Dukes",
//   "Essex",
//   "",
//   "Hampden",
//   "Hampshire",
//   "",
//   "Nantucket",
//   "Norfolk",
//   "",
//   "Suffolk",
//   "",
//   "Alcona",
//   "Alger",
//   "Allegan",
//   "Alpena",
//   "Antrim",
//   "Arenac",
//   "Baraga",
//   "Barry",
//   "",
//   "Benzie",
//   "",
//   "Branch",
//   "",
//   "",
//   "Charlevoix",
//   "Cheboygan",
//   "Chippewa",
//   "Clare",
//   "",
//   "",
//   "",
//   "",
//   "Eaton",
//   "",
//   "Genesee",
//   "Gladwin",
//   "Gogebic",
//   "Grand Traverse",
//   "Gratiot",
//   "Hillsdale",
//   "Houghton",
//   "Huron",
//   "Ingham",
//   "Ionia",
//   "Iosco",
//   "Iron",
//   "Isabella",
//   "",
//   "Kalamazoo",
//   "Kalkaska",
//   "",
//   "Keweenaw",
//   "",
//   "Lapeer",
//   "Leelanau",
//   "Lenawee",
//   "",
//   "Luce",
//   "Mackinac",
//   "Macomb",
//   "Manistee",
//   "Marquette",
//   "",
//   "Mecosta",
//   "Menominee",
//   "Midland",
//   "Missaukee",
//   "",
//   "Montcalm",
//   "Montmorency",
//   "Muskegon",
//   "Newaygo",
//   "Oakland",
//   "Oceana",
//   "Ogemaw",
//   "Ontonagon",
//   "",
//   "Oscoda",
//   "Otsego",
//   "",
//   "Presque Isle",
//   "Roscommon",
//   "Saginaw",
//   "",
//   "",
//   "Sanilac",
//   "Schoolcraft",
//   "Shiawassee",
//   "Tuscola",
//   "",
//   "Washtenaw",
//   "",
//   "Wexford",
//   "Aitkin",
//   "Anoka",
//   "Becker",
//   "Beltrami",
//   "",
//   "Big Stone",
//   "Blue Earth",
//   "",
//   "Carlton",
//   "Carver",
//   "",
//   "",
//   "Chisago",
//   "",
//   "",
//   "",
//   "Cottonwood",
//   "Crow Wing",
//   "Dakota",
//   "",
//   "",
//   "Faribault",
//   "Fillmore",
//   "Freeborn",
//   "Goodhue",
//   "",
//   "Hennepin",
//   "",
//   "Hubbard",
//   "Isanti",
//   "Itasca",
//   "",
//   "Kanabec",
//   "Kandiyohi",
//   "Kittson",
//   "Koochiching",
//   "Lac qui Parle",
//   "",
//   "Lake of the Woods",
//   "Le Sueur",
//   "",
//   "",
//   "McLeod",
//   "Mahnomen",
//   "",
//   "",
//   "Meeker",
//   "Mille Lacs",
//   "Morrison",
//   "Mower",
//   "",
//   "Nicollet",
//   "Nobles",
//   "Norman",
//   "Olmsted",
//   "Otter Tail",
//   "Pennington",
//   "Pine",
//   "Pipestone",
//   "",
//   "",
//   "Ramsey",
//   "Red Lake",
//   "Redwood",
//   "Renville",
//   "",
//   "Rock",
//   "Roseau",
//   "St. Louis",
//   "",
//   "Sherburne",
//   "Sibley",
//   "Stearns",
//   "Steele",
//   "",
//   "Swift",
//   "",
//   "Traverse",
//   "Wabasha",
//   "Wadena",
//   "Waseca",
//   "",
//   "Watonwan",
//   "Wilkin",
//   "Winona",
//   "",
//   "Yellow Medicine",
//   "",
//   "Alcorn",
//   "Amite",
//   "Attala",
//   "",
//   "Bolivar",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Coahoma",
//   "Copiah",
//   "",
//   "",
//   "Forrest",
//   "",
//   "George",
//   "",
//   "Grenada",
//   "",
//   "",
//   "Hinds",
//   "",
//   "Humphreys",
//   "Issaquena",
//   "Itawamba",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Kemper",
//   "",
//   "",
//   "",
//   "",
//   "Leake",
//   "",
//   "Leflore",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Neshoba",
//   "",
//   "Noxubee",
//   "Oktibbeha",
//   "Panola",
//   "Pearl River",
//   "",
//   "",
//   "Pontotoc",
//   "Prentiss",
//   "",
//   "Rankin",
//   "",
//   "Sharkey",
//   "",
//   "",
//   "",
//   "Sunflower",
//   "Tallahatchie",
//   "Tate",
//   "Tippah",
//   "Tishomingo",
//   "Tunica",
//   "",
//   "Walthall",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Yalobusha",
//   "Yazoo",
//   "",
//   "Andrew",
//   "",
//   "Audrain",
//   "",
//   "",
//   "Bates",
//   "",
//   "Bollinger",
//   "",
//   "",
//   "",
//   "",
//   "Callaway",
//   "",
//   "Cape Girardeau",
//   "",
//   "",
//   "",
//   "",
//   "Chariton",
//   "",
//   "",
//   "",
//   "",
//   "Cole",
//   "Cooper",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Dent",
//   "",
//   "Dunklin",
//   "",
//   "Gasconade",
//   "Gentry",
//   "",
//   "",
//   "",
//   "",
//   "Hickory",
//   "Holt",
//   "",
//   "Howell",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Laclede",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "McDonald",
//   "",
//   "",
//   "Maries",
//   "",
//   "",
//   "",
//   "",
//   "Moniteau",
//   "",
//   "",
//   "",
//   "New Madrid",
//   "",
//   "Nodaway",
//   "Oregon",
//   "",
//   "Ozark",
//   "Pemiscot",
//   "",
//   "Pettis",
//   "Phelps",
//   "",
//   "Platte",
//   "",
//   "",
//   "",
//   "Ralls",
//   "",
//   "Ray",
//   "Reynolds",
//   "",
//   "",
//   "",
//   "Ste. Genevieve",
//   "St. Francois",
//   "",
//   "",
//   "",
//   "Scotland",
//   "",
//   "Shannon",
//   "",
//   "Stoddard",
//   "",
//   "",
//   "Taney",
//   "Texas",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "St. Louis City",
//   "Beaverhead",
//   "Big Horn",
//   "",
//   "Broadwater",
//   "Carbon",
//   "",
//   "Cascade",
//   "Chouteau",
//   "",
//   "Daniels",
//   "",
//   "Deer Lodge",
//   "Fallon",
//   "Fergus",
//   "Flathead",
//   "",
//   "",
//   "Glacier",
//   "Golden Valley",
//   "Granite",
//   "Hill",
//   "",
//   "Judith Basin",
//   "",
//   "Lewis and Clark",
//   "",
//   "",
//   "McCone",
//   "",
//   "Meagher",
//   "",
//   "Missoula",
//   "Musselshell",
//   "",
//   "Petroleum",
//   "",
//   "Pondera",
//   "Powder River",
//   "",
//   "",
//   "Ravalli",
//   "",
//   "Roosevelt",
//   "Rosebud",
//   "Sanders",
//   "",
//   "Silver Bow",
//   "Stillwater",
//   "Sweet Grass",
//   "",
//   "Toole",
//   "Treasure",
//   "",
//   "Wheatland",
//   "Wibaux",
//   "Yellowstone",
//   "",
//   "Antelope",
//   "Arthur",
//   "Banner",
//   "",
//   "",
//   "Box Butte",
//   "",
//   "",
//   "Buffalo",
//   "Burt",
//   "",
//   "",
//   "",
//   "",
//   "Cherry",
//   "",
//   "",
//   "Colfax",
//   "Cuming",
//   "",
//   "",
//   "Dawes",
//   "",
//   "Deuel",
//   "Dixon",
//   "",
//   "",
//   "Dundy",
//   "",
//   "",
//   "Frontier",
//   "Furnas",
//   "Gage",
//   "Garden",
//   "",
//   "Gosper",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Hayes",
//   "Hitchcock",
//   "",
//   "Hooker",
//   "",
//   "",
//   "",
//   "Kearney",
//   "Keith",
//   "Keya Paha",
//   "Kimball",
//   "",
//   "Lancaster",
//   "",
//   "",
//   "Loup",
//   "",
//   "",
//   "Merrick",
//   "Morrill",
//   "Nance",
//   "",
//   "Nuckolls",
//   "Otoe",
//   "",
//   "Perkins",
//   "",
//   "",
//   "",
//   "",
//   "Red Willow",
//   "Richardson",
//   "",
//   "",
//   "Sarpy",
//   "Saunders",
//   "Scotts Bluff",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Thayer",
//   "",
//   "Thurston",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Churchill",
//   "",
//   "",
//   "Elko",
//   "Esmeralda",
//   "Eureka",
//   "",
//   "Lander",
//   "",
//   "",
//   "",
//   "Nye",
//   "Pershing",
//   "Storey",
//   "Washoe",
//   "White Pine",
//   "Carson City",
//   "Belknap",
//   "",
//   "Cheshire",
//   "Coos",
//   "Grafton",
//   "",
//   "Merrimack",
//   "Rockingham",
//   "Strafford",
//   "",
//   "Atlantic",
//   "Bergen",
//   "Burlington",
//   "",
//   "Cape May",
//   "",
//   "",
//   "Gloucester",
//   "Hudson",
//   "Hunterdon",
//   "",
//   "",
//   "Monmouth",
//   "",
//   "Ocean",
//   "Passaic",
//   "Salem",
//   "",
//   "",
//   "",
//   "",
//   "Bernalillo",
//   "Catron",
//   "Chaves",
//   "Cibola",
//   "",
//   "Curry",
//   "De Baca",
//   "Dona Ana",
//   "Eddy",
//   "",
//   "Guadalupe",
//   "Harding",
//   "Hidalgo",
//   "Lea",
//   "",
//   "Los Alamos",
//   "Luna",
//   "McKinley",
//   "Mora",
//   "",
//   "Quay",
//   "Rio Arriba",
//   "",
//   "Sandoval",
//   "",
//   "",
//   "Santa Fe",
//   "",
//   "Socorro",
//   "Taos",
//   "Torrance",
//   "",
//   "Valencia",
//   "Albany",
//   "",
//   "Bronx",
//   "Broome",
//   "Cattaraugus",
//   "Cayuga",
//   "",
//   "Chemung",
//   "Chenango",
//   "",
//   "",
//   "Cortland",
//   "",
//   "Dutchess",
//   "Erie",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Herkimer",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "New York",
//   "Niagara",
//   "",
//   "Onondaga",
//   "Ontario",
//   "",
//   "",
//   "Oswego",
//   "",
//   "",
//   "Queens",
//   "Rensselaer",
//   "",
//   "Rockland",
//   "St. Lawrence",
//   "Saratoga",
//   "Schenectady",
//   "Schoharie",
//   "",
//   "Seneca",
//   "",
//   "",
//   "",
//   "Tioga",
//   "Tompkins",
//   "Ulster",
//   "",
//   "",
//   "",
//   "Westchester",
//   "Wyoming",
//   "Yates",
//   "Alamance",
//   "",
//   "Alleghany",
//   "Anson",
//   "Ashe",
//   "Avery",
//   "Beaufort",
//   "Bertie",
//   "Bladen",
//   "Brunswick",
//   "Buncombe",
//   "",
//   "Cabarrus",
//   "",
//   "",
//   "Carteret",
//   "Caswell",
//   "Catawba",
//   "",
//   "",
//   "Chowan",
//   "",
//   "",
//   "Columbus",
//   "Craven",
//   "",
//   "Currituck",
//   "Dare",
//   "Davidson",
//   "Davie",
//   "Duplin",
//   "Durham",
//   "Edgecombe",
//   "",
//   "",
//   "Gaston",
//   "Gates",
//   "",
//   "Granville",
//   "",
//   "Guilford",
//   "Halifax",
//   "Harnett",
//   "Haywood",
//   "",
//   "Hertford",
//   "Hoke",
//   "Hyde",
//   "Iredell",
//   "",
//   "Johnston",
//   "",
//   "",
//   "Lenoir",
//   "",
//   "McDowell",
//   "",
//   "",
//   "",
//   "Mecklenburg",
//   "",
//   "",
//   "Moore",
//   "Nash",
//   "New Hanover",
//   "Northampton",
//   "Onslow",
//   "",
//   "Pamlico",
//   "Pasquotank",
//   "Pender",
//   "Perquimans",
//   "Person",
//   "Pitt",
//   "",
//   "",
//   "",
//   "Robeson",
//   "",
//   "",
//   "Rutherford",
//   "Sampson",
//   "",
//   "Stanly",
//   "Stokes",
//   "Surry",
//   "Swain",
//   "Transylvania",
//   "Tyrrell",
//   "",
//   "Vance",
//   "Wake",
//   "",
//   "",
//   "Watauga",
//   "",
//   "",
//   "",
//   "Yadkin",
//   "Yancey",
//   "",
//   "Barnes",
//   "Benson",
//   "Billings",
//   "Bottineau",
//   "Bowman",
//   "",
//   "Burleigh",
//   "",
//   "Cavalier",
//   "Dickey",
//   "Divide",
//   "Dunn",
//   "",
//   "Emmons",
//   "Foster",
//   "",
//   "Grand Forks",
//   "",
//   "Griggs",
//   "Hettinger",
//   "Kidder",
//   "LaMoure",
//   "",
//   "",
//   "",
//   "McKenzie",
//   "",
//   "",
//   "",
//   "Mountrail",
//   "",
//   "Oliver",
//   "Pembina",
//   "",
//   "",
//   "Ransom",
//   "",
//   "",
//   "Rolette",
//   "Sargent",
//   "",
//   "",
//   "Slope",
//   "",
//   "",
//   "Stutsman",
//   "Towner",
//   "Traill",
//   "Walsh",
//   "Ward",
//   "",
//   "Williams",
//   "",
//   "",
//   "Ashland",
//   "Ashtabula",
//   "Athens",
//   "Auglaize",
//   "Belmont",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Clermont",
//   "",
//   "Columbiana",
//   "Coshocton",
//   "",
//   "Cuyahoga",
//   "Darke",
//   "Defiance",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Gallia",
//   "Geauga",
//   "",
//   "Guernsey",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Highland",
//   "Hocking",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Licking",
//   "",
//   "Lorain",
//   "",
//   "",
//   "Mahoning",
//   "",
//   "Medina",
//   "Meigs",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Morrow",
//   "Muskingum",
//   "",
//   "",
//   "",
//   "",
//   "Pickaway",
//   "",
//   "Portage",
//   "Preble",
//   "",
//   "",
//   "Ross",
//   "Sandusky",
//   "Scioto",
//   "",
//   "",
//   "",
//   "",
//   "Trumbull",
//   "Tuscarawas",
//   "",
//   "Van Wert",
//   "Vinton",
//   "",
//   "",
//   "",
//   "",
//   "Wood",
//   "Wyandot",
//   "",
//   "Alfalfa",
//   "Atoka",
//   "Beaver",
//   "Beckham",
//   "",
//   "",
//   "",
//   "Canadian",
//   "",
//   "",
//   "",
//   "Cimarron",
//   "",
//   "Coal",
//   "",
//   "Cotton",
//   "Craig",
//   "Creek",
//   "",
//   "",
//   "Dewey",
//   "",
//   "",
//   "Garvin",
//   "",
//   "",
//   "Greer",
//   "Harmon",
//   "",
//   "",
//   "Hughes",
//   "",
//   "",
//   "",
//   "Kay",
//   "Kingfisher",
//   "",
//   "Latimer",
//   "Le Flore",
//   "",
//   "",
//   "Love",
//   "McClain",
//   "McCurtain",
//   "",
//   "Major",
//   "",
//   "Mayes",
//   "",
//   "Muskogee",
//   "",
//   "Nowata",
//   "Okfuskee",
//   "Oklahoma",
//   "Okmulgee",
//   "",
//   "",
//   "",
//   "Payne",
//   "Pittsburg",
//   "",
//   "",
//   "Pushmataha",
//   "Roger Mills",
//   "Rogers",
//   "",
//   "Sequoyah",
//   "",
//   "",
//   "Tillman",
//   "Tulsa",
//   "Wagoner",
//   "",
//   "Washita",
//   "Woods",
//   "Woodward",
//   "",
//   "",
//   "Clackamas",
//   "Clatsop",
//   "",
//   "",
//   "Crook",
//   "",
//   "Deschutes",
//   "",
//   "Gilliam",
//   "",
//   "Harney",
//   "Hood River",
//   "",
//   "",
//   "Josephine",
//   "Klamath",
//   "",
//   "",
//   "",
//   "",
//   "Malheur",
//   "",
//   "",
//   "Multnomah",
//   "",
//   "",
//   "Tillamook",
//   "Umatilla",
//   "",
//   "Wallowa",
//   "Wasco",
//   "",
//   "",
//   "Yamhill",
//   "",
//   "Allegheny",
//   "Armstrong",
//   "",
//   "Bedford",
//   "Berks",
//   "Blair",
//   "",
//   "Bucks",
//   "",
//   "Cambria",
//   "",
//   "",
//   "Centre",
//   "Chester",
//   "Clarion",
//   "Clearfield",
//   "",
//   "",
//   "",
//   "",
//   "Dauphin",
//   "",
//   "",
//   "",
//   "",
//   "Forest",
//   "",
//   "",
//   "",
//   "Huntingdon",
//   "Indiana",
//   "",
//   "Juniata",
//   "Lackawanna",
//   "",
//   "",
//   "Lebanon",
//   "Lehigh",
//   "Luzerne",
//   "Lycoming",
//   "McKean",
//   "",
//   "Mifflin",
//   "",
//   "",
//   "Montour",
//   "",
//   "Northumberland",
//   "",
//   "Philadelphia",
//   "",
//   "Potter",
//   "Schuylkill",
//   "Snyder",
//   "",
//   "",
//   "Susquehanna",
//   "",
//   "",
//   "Venango",
//   "",
//   "",
//   "",
//   "Westmoreland",
//   "",
//   "",
//   "",
//   "",
//   "Newport",
//   "Providence",
//   "",
//   "Abbeville",
//   "Aiken",
//   "Allendale",
//   "",
//   "Bamberg",
//   "Barnwell",
//   "",
//   "Berkeley",
//   "",
//   "Charleston",
//   "",
//   "",
//   "Chesterfield",
//   "Clarendon",
//   "Colleton",
//   "Darlington",
//   "Dillon",
//   "",
//   "Edgefield",
//   "",
//   "Florence",
//   "Georgetown",
//   "Greenville",
//   "",
//   "Hampton",
//   "Horry",
//   "",
//   "Kershaw",
//   "",
//   "",
//   "",
//   "Lexington",
//   "McCormick",
//   "",
//   "Marlboro",
//   "Newberry",
//   "",
//   "Orangeburg",
//   "",
//   "",
//   "Saluda",
//   "Spartanburg",
//   "",
//   "",
//   "Williamsburg",
//   "",
//   "Aurora",
//   "Beadle",
//   "Bennett",
//   "Bon Homme",
//   "Brookings",
//   "",
//   "Brule",
//   "",
//   "",
//   "",
//   "Charles Mix",
//   "",
//   "",
//   "Codington",
//   "Corson",
//   "",
//   "Davison",
//   "Day",
//   "",
//   "",
//   "",
//   "Edmunds",
//   "Fall River",
//   "Faulk",
//   "",
//   "Gregory",
//   "Haakon",
//   "Hamlin",
//   "Hand",
//   "Hanson",
//   "",
//   "",
//   "Hutchinson",
//   "",
//   "",
//   "Jerauld",
//   "",
//   "Kingsbury",
//   "",
//   "",
//   "",
//   "Lyman",
//   "McCook",
//   "",
//   "",
//   "",
//   "Mellette",
//   "Miner",
//   "Minnehaha",
//   "Moody",
//   "Oglala Lakota",
//   "",
//   "",
//   "",
//   "Roberts",
//   "Sanborn",
//   "Spink",
//   "Stanley",
//   "Sully",
//   "",
//   "Tripp",
//   "",
//   "",
//   "Walworth",
//   "Yankton",
//   "Ziebach",
//   "",
//   "",
//   "",
//   "Bledsoe",
//   "",
//   "",
//   "",
//   "Cannon",
//   "",
//   "",
//   "Cheatham",
//   "",
//   "",
//   "",
//   "Cocke",
//   "",
//   "Crockett",
//   "",
//   "",
//   "",
//   "",
//   "Dickson",
//   "Dyer",
//   "",
//   "Fentress",
//   "",
//   "",
//   "Giles",
//   "Grainger",
//   "",
//   "",
//   "Hamblen",
//   "",
//   "",
//   "Hardeman",
//   "",
//   "Hawkins",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Loudon",
//   "McMinn",
//   "McNairy",
//   "",
//   "",
//   "",
//   "",
//   "Maury",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Obion",
//   "Overton",
//   "",
//   "Pickett",
//   "",
//   "",
//   "Rhea",
//   "Roane",
//   "",
//   "",
//   "",
//   "Sequatchie",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Trousdale",
//   "Unicoi",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Weakley",
//   "",
//   "",
//   "",
//   "",
//   "Andrews",
//   "Angelina",
//   "Aransas",
//   "Archer",
//   "",
//   "Atascosa",
//   "Austin",
//   "Bailey",
//   "Bandera",
//   "Bastrop",
//   "Baylor",
//   "Bee",
//   "",
//   "Bexar",
//   "Blanco",
//   "Borden",
//   "Bosque",
//   "Bowie",
//   "Brazoria",
//   "Brazos",
//   "Brewster",
//   "Briscoe",
//   "",
//   "",
//   "Burleson",
//   "Burnet",
//   "",
//   "",
//   "Callahan",
//   "",
//   "Camp",
//   "Carson",
//   "",
//   "Castro",
//   "",
//   "",
//   "Childress",
//   "",
//   "Cochran",
//   "Coke",
//   "Coleman",
//   "Collin",
//   "Collingsworth",
//   "Colorado",
//   "Comal",
//   "",
//   "Concho",
//   "Cooke",
//   "Coryell",
//   "Cottle",
//   "Crane",
//   "",
//   "Crosby",
//   "Culberson",
//   "Dallam",
//   "",
//   "",
//   "Deaf Smith",
//   "",
//   "Denton",
//   "DeWitt",
//   "Dickens",
//   "Dimmit",
//   "Donley",
//   "",
//   "Eastland",
//   "Ector",
//   "",
//   "",
//   "",
//   "Erath",
//   "Falls",
//   "",
//   "",
//   "Fisher",
//   "",
//   "Foard",
//   "Fort Bend",
//   "",
//   "Freestone",
//   "Frio",
//   "Gaines",
//   "Galveston",
//   "Garza",
//   "Gillespie",
//   "Glasscock",
//   "Goliad",
//   "Gonzales",
//   "",
//   "",
//   "Gregg",
//   "Grimes",
//   "",
//   "",
//   "",
//   "",
//   "Hansford",
//   "",
//   "",
//   "",
//   "",
//   "Hartley",
//   "",
//   "Hays",
//   "Hemphill",
//   "",
//   "",
//   "",
//   "Hockley",
//   "Hood",
//   "",
//   "",
//   "",
//   "Hudspeth",
//   "Hunt",
//   "",
//   "Irion",
//   "Jack",
//   "",
//   "",
//   "",
//   "",
//   "Jim Hogg",
//   "Jim Wells",
//   "",
//   "",
//   "Karnes",
//   "Kaufman",
//   "",
//   "Kenedy",
//   "",
//   "Kerr",
//   "Kimble",
//   "King",
//   "Kinney",
//   "Kleberg",
//   "",
//   "",
//   "Lamb",
//   "Lampasas",
//   "La Salle",
//   "Lavaca",
//   "",
//   "",
//   "",
//   "",
//   "Lipscomb",
//   "Live Oak",
//   "Llano",
//   "Loving",
//   "Lubbock",
//   "Lynn",
//   "McCulloch",
//   "McLennan",
//   "McMullen",
//   "",
//   "",
//   "",
//   "",
//   "Matagorda",
//   "Maverick",
//   "",
//   "",
//   "",
//   "Milam",
//   "",
//   "",
//   "Montague",
//   "",
//   "",
//   "",
//   "Motley",
//   "Nacogdoches",
//   "Navarro",
//   "",
//   "Nolan",
//   "Nueces",
//   "Ochiltree",
//   "",
//   "",
//   "Palo Pinto",
//   "",
//   "Parker",
//   "Parmer",
//   "Pecos",
//   "",
//   "",
//   "Presidio",
//   "Rains",
//   "Randall",
//   "Reagan",
//   "Real",
//   "",
//   "Reeves",
//   "Refugio",
//   "",
//   "",
//   "Rockwall",
//   "Runnels",
//   "Rusk",
//   "",
//   "San Augustine",
//   "San Jacinto",
//   "San Patricio",
//   "San Saba",
//   "Schleicher",
//   "Scurry",
//   "Shackelford",
//   "",
//   "",
//   "",
//   "Somervell",
//   "Starr",
//   "",
//   "Sterling",
//   "Stonewall",
//   "Sutton",
//   "Swisher",
//   "Tarrant",
//   "",
//   "",
//   "Terry",
//   "Throckmorton",
//   "Titus",
//   "Tom Green",
//   "Travis",
//   "",
//   "Tyler",
//   "Upshur",
//   "Upton",
//   "Uvalde",
//   "Val Verde",
//   "Van Zandt",
//   "Victoria",
//   "",
//   "Waller",
//   "",
//   "",
//   "Webb",
//   "Wharton",
//   "",
//   "",
//   "Wilbarger",
//   "Willacy",
//   "",
//   "",
//   "Winkler",
//   "Wise",
//   "",
//   "Yoakum",
//   "Young",
//   "Zapata",
//   "Zavala",
//   "",
//   "Box Elder",
//   "Cache",
//   "",
//   "Daggett",
//   "",
//   "Duchesne",
//   "Emery",
//   "",
//   "",
//   "",
//   "Juab",
//   "",
//   "Millard",
//   "",
//   "Piute",
//   "Rich",
//   "Salt Lake",
//   "",
//   "Sanpete",
//   "",
//   "",
//   "Tooele",
//   "Uintah",
//   "Utah",
//   "Wasatch",
//   "",
//   "",
//   "Weber",
//   "Addison",
//   "Bennington",
//   "Caledonia",
//   "Chittenden",
//   "",
//   "",
//   "Grand Isle",
//   "Lamoille",
//   "",
//   "",
//   "Rutland",
//   "",
//   "",
//   "Windsor",
//   "Accomack",
//   "Albemarle",
//   "",
//   "Amelia",
//   "Amherst",
//   "Appomattox",
//   "Arlington",
//   "Augusta",
//   "",
//   "",
//   "Bland",
//   "Botetourt",
//   "",
//   "",
//   "Buckingham",
//   "",
//   "",
//   "",
//   "Charles City",
//   "",
//   "",
//   "",
//   "",
//   "Culpeper",
//   "",
//   "Dickenson",
//   "Dinwiddie",
//   "",
//   "Fairfax",
//   "Fauquier",
//   "",
//   "Fluvanna",
//   "",
//   "",
//   "",
//   "",
//   "Goochland",
//   "",
//   "",
//   "Greensville",
//   "",
//   "Hanover",
//   "Henrico",
//   "",
//   "",
//   "Isle of Wight",
//   "James City",
//   "King and Queen",
//   "King George",
//   "King William",
//   "",
//   "",
//   "Loudoun",
//   "",
//   "Lunenburg",
//   "",
//   "Mathews",
//   "",
//   "",
//   "",
//   "",
//   "New Kent",
//   "",
//   "",
//   "Nottoway",
//   "",
//   "",
//   "Patrick",
//   "Pittsylvania",
//   "Powhatan",
//   "Prince Edward",
//   "Prince George",
//   "Prince William",
//   "",
//   "Rappahannock",
//   "",
//   "Roanoke",
//   "Rockbridge",
//   "",
//   "",
//   "",
//   "Shenandoah",
//   "Smyth",
//   "Southampton",
//   "Spotsylvania",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Wythe",
//   "",
//   "Alexandria",
//   "",
//   "",
//   "Charlottesville",
//   "Chesapeake",
//   "Colonial Heights",
//   "",
//   "Danville",
//   "Emporia",
//   "Fairfax City",
//   "Falls Church",
//   "Franklin City",
//   "Fredericksburg",
//   "Galax",
//   "",
//   "Harrisonburg",
//   "Hopewell",
//   "",
//   "Lynchburg",
//   "Manassas",
//   "Manassas Park",
//   "Martinsville",
//   "Newport News",
//   "",
//   "",
//   "",
//   "Poquoson",
//   "Portsmouth",
//   "Radford",
//   "Richmond City",
//   "Roanoke City",
//   "",
//   "Staunton",
//   "",
//   "Virginia Beach",
//   "Waynesboro",
//   "",
//   "Winchester",
//   "",
//   "Asotin",
//   "",
//   "Chelan",
//   "Clallam",
//   "",
//   "",
//   "Cowlitz",
//   "",
//   "Ferry",
//   "",
//   "",
//   "",
//   "Grays Harbor",
//   "Island",
//   "",
//   "",
//   "Kitsap",
//   "Kittitas",
//   "Klickitat",
//   "",
//   "",
//   "",
//   "Okanogan",
//   "Pacific",
//   "Pend Oreille",
//   "",
//   "",
//   "Skagit",
//   "Skamania",
//   "Snohomish",
//   "Spokane",
//   "",
//   "",
//   "Wahkiakum",
//   "Walla Walla",
//   "Whatcom",
//   "Whitman",
//   "Yakima",
//   "",
//   "",
//   "",
//   "Braxton",
//   "Brooke",
//   "Cabell",
//   "",
//   "",
//   "Doddridge",
//   "",
//   "",
//   "",
//   "Greenbrier",
//   "",
//   "",
//   "Hardy",
//   "",
//   "",
//   "",
//   "Kanawha",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Mingo",
//   "Monongalia",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Pleasants",
//   "",
//   "Preston",
//   "",
//   "Raleigh",
//   "",
//   "Ritchie",
//   "",
//   "Summers",
//   "",
//   "Tucker",
//   "",
//   "",
//   "",
//   "",
//   "Wetzel",
//   "Wirt",
//   "",
//   "",
//   "",
//   "",
//   "Barron",
//   "Bayfield",
//   "",
//   "",
//   "Burnett",
//   "Calumet",
//   "",
//   "",
//   "",
//   "",
//   "Dane",
//   "",
//   "Door",
//   "",
//   "",
//   "Eau Claire",
//   "",
//   "Fond du Lac",
//   "",
//   "",
//   "",
//   "Green Lake",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Kenosha",
//   "Kewaunee",
//   "La Crosse",
//   "",
//   "Langlade",
//   "",
//   "Manitowoc",
//   "Marathon",
//   "Marinette",
//   "",
//   "",
//   "Milwaukee",
//   "",
//   "Oconto",
//   "",
//   "Outagamie",
//   "Ozaukee",
//   "Pepin",
//   "",
//   "",
//   "",
//   "Price",
//   "Racine",
//   "",
//   "",
//   "",
//   "St. Croix",
//   "Sauk",
//   "Sawyer",
//   "Shawano",
//   "Sheboygan",
//   "",
//   "Trempealeau",
//   "",
//   "Vilas",
//   "",
//   "Washburn",
//   "",
//   "Waukesha",
//   "Waupaca",
//   "Waushara",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "",
//   "Converse",
//   "",
//   "",
//   "Goshen",
//   "Hot Springs",
//   "",
//   "Laramie",
//   "",
//   "Natrona",
//   "Niobrara",
//   "",
//   "",
//   "",
//   "Sublette",
//   "Sweetwater",
//   "",
//   "Uinta",
//   "Washakie",
//   "Weston",
//   "Out of AL",
//   "Out of AK",
//   "Out of AZ",
//   "Out of AR",
//   "Out of CA",
//   "Out of CO",
//   "Out of CT",
//   "Out of DE",
//   "Out of DC",
//   "Out of FL",
//   "Out of GA",
//   "Out of HI",
//   "Out of ID",
//   "Out of IL",
//   "Out of IN",
//   "Out of IA",
//   "Out of KS",
//   "Out of KY",
//   "Out of LA",
//   "Out of ME",
//   "Out of MD",
//   "Out of MA",
//   "Out of MI",
//   "Out of MN",
//   "Out of MS",
//   "Out of MO",
//   "Out of MT",
//   "Out of NE",
//   "Out of NV",
//   "Out of NH",
//   "Out of NJ",
//   "Out of NM",
//   "Out of NY",
//   "Out of NC",
//   "Out of ND",
//   "Out of OH",
//   "Out of OK",
//   "Out of OR",
//   "Out of PA",
//   "Out of RI",
//   "Out of SC",
//   "Out of SD",
//   "Out of TN",
//   "Out of TX",
//   "Out of UT",
//   "Out of VT",
//   "Out of VA",
//   "Out of WA",
//   "Out of WV",
//   "Out of WI",
//   "Out of WY",
//   "Dukes and Nantucket",
//   "Kansas City",
//   "Michigan Department of Corrections (MDOC)",
//   "Federal Correctional Institution (FCI)",
//   "Bear River",
//   "Central Utah",
//   "Southeast Utah",
//   "Southwest Utah",
//   "TriCounty"
// ];
