import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Form, Col } from "reactstrap";
import UpdateCoronaStats from "./UpdateCoronaStats";
// import {Link} from "react-router-dom";

const DeleteCases = () => {
  const { register, handleSubmit } = useForm(); // initialise the hook
  const [response, setResponse] = useState(null);

  const onSubmit = async data => {
    const resObj = await UpdateCoronaStats.deleteOlderCases(
      data.start,
      data.end
    );
    setResponse(resObj);
  };

  return (
    <Card body>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <b>Delete Country Cases By Hours</b>
        <Col sm={{ size: 6, offset: 3, order: 2 }}>
          <input
            className="form-control"
            name="start"
            type="number"
            placeholder="start hours"
            ref={register({ required: true })}
          />
          <input
            className="form-control"
            name="end"
            type="number"
            placeholder="end hours"
            ref={register({ required: true })}
          />
          <input type="submit" className="btn btn-dark mt-1" />
        </Col>
      </Form>
      {response && (
        <Card body>
          <h2>Response</h2>
          {response.deletedCount && (
            <h4>Deleted {JSON.stringify(response.deletedCount)} case(s)</h4>
          )}
          {JSON.stringify(response)}
        </Card>
      )}
      {/*<div><Button onClick={() => UpdateCoronaStats.updateUnconditionally()}>Update Unconditonally</Button></div>*/}
      {/*<div className="mt-5"><Button><Link to="/update/admin/jhu">JHU Admin</Link></Button></div>*/}
    </Card>
  );
};

export default DeleteCases;
