// import React from "react";
import moment from "moment";
import CalculateDate from "../../Utilities/CalculateDate";
// import { request } from '@esri/arcgis-rest-request';
import axios from "axios";
import _ from "lodash";

class UpdateCoronaStats {
  static updateData = async dateAdded => {
    // const hourAgo = CalculateDate.time(-1, "hour");
    const thirtyMinsAgo = CalculateDate.time(-30, "minutes");
    const isSameOrBefore = moment(dateAdded).isSameOrBefore(thirtyMinsAgo);

    if (isSameOrBefore) {
      axios
        .get(`/api/corona/update`)
        .then(res => {
          return null;
        })
        .catch(err => {
          console.log(
            "updateData in isSameOrBefore UpdateCoronaStats has error: " + JSON.stringify(err)
          );
          return null;
        });
    } else {
      return null;
    }
  };

  static updateUnconditionally = async () => {
    axios
      .get(`/api/corona/update`)
      .then(res => {
        // console.log("RESPONSE in .then of updateTest: " + JSON.stringify(res.data));
        return null;
      })
      .catch(err => {
        console.log(
          "updateUnconditionally in UpdateCoronaStats has error: " + JSON.stringify(err)
        );
        return null;
      });

    // const hourAgo = CalculateDate.time(-30, "minutes");
    // const url = `https://services1.arcgis.com/0MSEUqKaxRlEPj5g/ArcGIS/rest/services/ncov_cases/FeatureServer/2/query?where=confirmed%3E1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=standard&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=true&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=`;
    //
    // request(url)
    //   .then(response => {
    //     console.log(response) // WebMap JSON
    //   });

    // if (moment(dateAdded).isSameOrBefore(hourAgo)) {
    //
    // } else {
    //   return null;
    // }
  };

  static deleteOlderCases = async (start, end) => {

    let x = await axios
      .post(`/api/corona/remove-old`,  {start, end})
      .then(res => {
        // console.log("RESPONSE in .then of deleteOlderCases: " + JSON.stringify(res.data));
        return res.data;
      })
      .catch(err => {
        console.log(
          "deleteOlderCases in UpdateCoronaStats has error: " +
            JSON.stringify(err)
        );
        return null;
      });

    // let data = x.data;

    if (x) {
      return x;
    } else {
      return "no results or deleted many =]";
    }
  };

  // Candidate for deletion (after separating GlobalData
  static calculateGlobal = (resData, date) => {
    const allCases = resData;
    const dateAdded = date;

    const globalCases = allCases.reduce((acc, c) => acc + c.confirmed, 0);
    const globalRecovered = allCases.reduce((acc, c) => acc + c.recovered, 0);
    const globalDeaths = allCases.reduce((acc, c) => acc + c.deaths, 0);
    const globalActive = globalCases - (globalDeaths + globalRecovered);
    const globalClosedCases = globalRecovered + globalDeaths;
    const globalClosedDeathRisk = _.round(
      (globalDeaths / (globalDeaths + globalRecovered)) * 100
    );
    const globalDeathRisk = _.round(
      (globalDeaths / (globalCases)) * 100
    );
    const globalRecoveredPercent = _.round(
      (globalRecovered / (globalCases)) * 100
    );

    const global = {
      cases: globalCases,
      recovered: globalRecovered,
      deaths: globalDeaths,
      active: globalActive,
      closedCases: globalClosedCases,
      closedDeathRisk: globalClosedDeathRisk,
      dateAdded: dateAdded,
      globalDeathRisk: globalDeathRisk,
      globalRecoveredPercent: globalRecoveredPercent
    };

    return global;
  };
}

export default UpdateCoronaStats;



// const hourAgo = CalculateDate.time(-1, "hour");
// let x;
// let z;
//
// if (moment(dateAdded).isSameOrBefore(hourAgo)) {
//   x = await axios
//     .get(`/api/corona/update`)
//     .then(resUp => {
//       const allCases = resUp.data[0].allCases;
//       const dateAdded = resUp.data[0].dateAdded;
//
//       const sorted = allCases.sort(
//         (a, b) => (a.confirmed > b.confirmed ? -1 : 1)
//       );
//       const filtered = sorted.filter(c => c.confirmed > 2000);
//
//       const calculatedGlobal = this.calculateGlobal(
//         allCases,
//         dateAdded
//       );
//
//       return {calculatedGlobal, filtered, sorted};
//     })
//     .catch(err => {
//       return null;
//     });
//   return x;
// } else {
//   z = await axios
//     .get(`/api/corona/cases`)
//     .then(resOld => {
//       const allCases = resOld.data[0].allCases;
//       const dateAdded = resOld.data[0].dateAdded;
//
//       const sorted = allCases.sort(
//         (a, b) => (a.confirmed > b.confirmed ? -1 : 1)
//       );
//       const filtered = sorted.filter(c => c.confirmed > 2000);
//
//       const calculatedGlobal = this.calculateGlobal(
//         allCases,
//         dateAdded
//       );
//
//       return {calculatedGlobal, filtered, sorted};
//     })
//     .catch(err => {
//       return null;
//     });
//
//   return z;
// }
//
// if (x) {
//   return x;
// } else {
//   return z;
// }