import React from "react";
// useEffect, useState
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "reactstrap";
import axios from "axios";
import DeleteTopicButton from "./DeleteTopicButton";

const UpdateSubtopic = ({ subtopic, groupId, setTopic, setWasUpdated }) => {
  const { register, watch, handleSubmit, control, formState } = useForm({
    defaultValues: {
      title: subtopic.title,
      summary: subtopic.summary,
      text: subtopic.text,
      claims: subtopic.claims,
      citations: subtopic.citations,
      image: subtopic.image,
      importance: subtopic.importance ? subtopic.importance : 100
    }
  });
  const { fields, append, remove } = useFieldArray({ control, name: "claims" });
  const {
    fields: textFields,
    append: textAppend,
    remove: textRemove
  } = useFieldArray({ control, name: "text" });
  const {
    fields: citationFields,
    append: citationAppend,
    remove: citationRemove
  } = useFieldArray({ control, name: "citations" });

  // const [response, setResponse] = useState(null);
  // const [newResponse, setNewResponse] = useState(null);
  // const [showForm, setShowForm] = useState(false);
  const addImage = watch("addImage");

  // useEffect to clear response message
  // useEffect(
  //   () => {
  //     const timer = setTimeout(() => {
  //       setResponse(null);
  //     }, 10000);
  //     // console.log("in USEEEEE " + response);
  //     return () => clearTimeout(timer);
  //   },
  //   [response]
  // );

  // const sendSetTopic = joe => {
  //   console.log("sendSetTopic =] ran");
  //   const timer = setTimeout(() => {
  //     setTopic(joe);
  //   }, 2000);
  // };

  const onSubmit = async data => {
    delete data.addImage;
    data.title.trim().length < 1 && delete data.title;
    // console.log(data);

    const joe = await axios
      .put(`/api/topics/${groupId}/subtopic/${subtopic._id}`, data)
      .then(res => {
        // setResponse("successful =]");
        return res.data;
      })
      .catch(err => {
        // setResponse(err);
        console.log("wtf " + err);
        return err;
      });

    // success came from server side so didn't put it on frontend
    // joe.success && setTopic(joe.updatedTopic);
    if (joe.success) {
      setTopic(joe.updatedTopic);
      setWasUpdated("successfully updated =]");
    }

    // existingTopic
    //   ? console.log("it exists")
    //   : axios
    //       .post(`/api/topics/group/${groupId}`, data)
    //       .then(res => setResponse(res.data))
    //       .catch(err => {
    //         setResponse(err);
    //         console.log("wtf " + err);
    //       });
  };

  const calcRows = text => {
    if (!text) {
      return 5;
    } else {
      return text.toString().length / 33;
    }
  };

  // ------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------ //
  return (
    <div className="gentle-border">
      <div>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            {/*Edit*/}
            {subtopic._id && (
              <span>
                <b> {subtopic._id}</b>
              </span>
            )}
          </div>

          {/*-------------*/}
          {/*UPDATE CLAIMS*/}
          {/*-------------*/}
          <div>
            <div className="col-form-label">Claims</div>
            <ul className="list-group">
              {fields.map((item, index) => (
                <li className="list-group-item px-2" key={item.id}>
                  <input
                    className="form-control w-85"
                    placeholder="claim"
                    name={`claims[${index}].claim`}
                    ref={register({ required: true })}
                  />
                  <input
                    className="form-control w-85"
                    placeholder="rating value (true, false, mixed)"
                    name={`claims[${index}].rating`}
                    ref={register({ required: true })}
                  />
                  <input
                    className="form-control w-85 float-left"
                    placeholder="rating text"
                    name={`claims[${index}].ratingText`}
                    ref={register({ required: true })}
                  />
                  <button
                    className="btn btn-danger float-right"
                    onClick={() => remove(index)}
                  >
                    x
                  </button>
                </li>
              ))}
            </ul>

            <section>
              <button
                className="btn btn-light btn-block p-1 mb-3"
                type="button"
                onClick={() => append({ name: "claims" })}
              >
                Add Claim
              </button>
            </section>
          </div>
          <hr className="border-dark" />

          {/*{groupId && <div>{groupId}</div>}*/}
          {/*<Row>*/}
          {/*  <Col md="6" className="p-0">*/}
          {/*-----------------*/}
          {/*--Simple Inputs--*/}
          {/*-----------------*/}
          <div>
            <div className="col-form-label">Summary</div>
            <textarea
              style={{ overflow: "auto" }}
              rows={calcRows(subtopic.summary)}
              className="form-control"
              name="summary"
              placeholder="summary"
              ref={register({ required: true })}
            />
          </div>

          <hr className="border-dark" />

          {/*----------------------*/}
          {/*Append Text Paragraphs*/}
          {/*----------------------*/}
          {/*<div>*/}
          <div className="col-form-label">Paragraphs</div>

          <ul className="list-group">
            {textFields.map((textItem, index) => (
              <li className="list-group-item px-2" key={textItem.id}>
                <textarea
                  className="form-control w-85 float-left mb-1"
                  rows={calcRows(textItem.value)}
                  placeholder="text paragraph"
                  name={`text[${index}]`}
                  ref={register({ required: true })}
                />
                <button
                  className="btn btn-danger float-right"
                  onClick={() => textRemove(index)}
                >
                  x
                </button>
              </li>
            ))}
          </ul>
          <section>
            <button
              className="btn btn-light btn-block p-1 mb-3"
              type="button"
              onClick={() => textAppend({ name: "text" })}
            >
              Add Paragraph
            </button>
          </section>
          {/*</div>*/}

          <hr className="border-dark" />

          {/*---------------------*/}
          {/*------CITATIONS------*/}
          {/*---------------------*/}
          <div>
            <div className="col-form-label">Citations</div>
            <ul className="list-group">
              {citationFields.map((citationItem, indexC) => (
                <li className="list-group-item px-2" key={citationItem.id}>
                  <input
                    className="form-control w-50 float-left mb-1"
                    placeholder="number"
                    type="number"
                    name={`citations[${indexC}].number`}
                    ref={register({ required: true })}
                  />
                  <input
                    className="form-control w-85 float-left mb-1"
                    placeholder="link"
                    name={`citations[${indexC}].link`}
                    ref={register({ required: true })}
                  />
                  <input
                    className="form-control w-85 float-left mb-1"
                    placeholder="citation"
                    name={`citations[${indexC}].citation`}
                    ref={register}
                  />
                  {/*<a href={`citations[${indexC}].link`} target="_blank" rel="noopener noreferrer">*/}
                  {/*  <span>link</span>)}*/}
                  {/*</a>*/}
                  <button
                    className="btn btn-danger float-right"
                    onClick={() => citationRemove(indexC)}
                  >
                    x
                  </button>
                </li>
              ))}
            </ul>
            <section>
              <button
                className="btn btn-light btn-block p-1 mb-3"
                type="button"
                onClick={() => citationAppend({ name: "citations" })}
              >
                Add Citations
              </button>
            </section>
          </div>
          <hr className="border-dark" />

          {/*----------------------------------------*/}
          {/*----------------------------------------*/}
          {/*-Add Title / Image / Importance Section-*/}
          {/*----------------------------------------*/}
          {/*----------------------------------------*/}
          <div className="list-group-item px-2" style={{ opacity: "0.5" }}>
            <div className="col-form-label">Misc</div>
            <input
              className="form-control"
              name="title"
              placeholder="subtopic title"
              ref={register}
            />
            <input
              className="form-control"
              name="importance"
              type="number"
              placeholder="importance"
              ref={register}
            />
            <div>
              Add Subtopic Image?{" "}
              <input name="addImage" type="checkbox" ref={register} />
            </div>

            {addImage && (
              <input
                className="form-control"
                name="image"
                placeholder="subtopic image"
                ref={register}
              />
            )}
          </div>

          <input
            type="submit"
            value="Update Subtopic"
            disabled={!formState.dirty || formState.isSubmitting}
            className="btn btn-primary mt-1"
          />
        </Form>

        <div className="mt-4">
          <DeleteTopicButton
            setTopic={setTopic}
            idToDelete={subtopic._id}
            topicType="subtopic"
          />
        </div>

        {/*{response && (*/}
        {/*  <Card className="gentle-outline-x">*/}
        {/*    <h3>Response</h3>*/}
        {/*    {JSON.stringify(response)}*/}
        {/*  </Card>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default UpdateSubtopic;
