import React from "react";
// useState, useEffect, useRef
import {
  Row,
  Col,
  Card,
  Spinner,
  Badge,
  // Button,
  // CardGroup,
  // CardBody,
  // CardColumns,
  // CardDeck
} from "reactstrap";
import _ from "lodash";
import moment from "moment";
import ThreeBoxDataRow from "./ThreeBoxDataRow";
import classNames from "classnames";
// import * as ss from 'simple-statistics';

const DetailedCasesDash = ({
  todayTotal,
  yesterdayTotal,
  ereYesterdayTotal,
  peakDate,
  sixtyDayLow,
  sixtyDayPeakDate,
  allDates,
  cityData,
}) => {
  // const [hasError, setErrors] = useState(false);
  // const [todayTotal, setLocal] = useState({});
  // const _isMounted = useRef(true);
  // useEffect(() => {
  //   // let isCancelled = false;
  //
  //   async function fetchData() {
  //     axios
  //       .get(`/api/test/pup`)
  //       .then(res => {
  //         const todayTotalCases = res.data;
  //         // console.log("todayTotalCases " + JSON.stringify(todayTotalCases));
  //
  //         if (_isMounted.current) {
  //           setGlobal(todayTotalCases);
  //         }
  //       })
  //       .catch(err => {
  //         UpdateCoronaStats.updateUnconditionally();
  //         setErrors(err);
  //         console.log("In catch error of CoronaDash: " + JSON.stringify(err));
  //         return null;
  //       });
  //   }
  //
  //   fetchData();
  //
  //   // console.log("in stats useEffects");
  //   return () => {
  //     // isCancelled = true;
  //     _isMounted.current = false;
  //   };
  // }, []);

  // const timeSinceReset = () => {
  //   const fivePmToday = moment()
  //     .startOf("day")
  //     .add(17, "h");
  //   const fivePmYday = moment()
  //     .startOf("day")
  //     .subtract(1, "days")
  //     .add(17, "h");
  //
  //   const isBeforeFivePmToday = moment().isBefore(fivePmToday, "minutes");
  //
  //   if (isBeforeFivePmToday) {
  //     return moment(fivePmYday).format("MMMM Do YYYY, ha");
  //   } else {
  //     return moment(fivePmToday).format("MMMM Do YYYY, ha");
  //   }
  // };

  const getDifference = (dateArr, a, b) => {
    return getConfirmed(dateArr, a).total - getConfirmed(dateArr, b).total;
  };

  const getConfirmed = (dateArr, first, second, third) => {
    if (Array.isArray(dateArr)) {
      const total = first ? dateArr[dateArr.length - first].confirmed : 0;
      const oldTotal = second ? dateArr[dateArr.length - second].confirmed : 0;
      const oldestTotal = third ? dateArr[dateArr.length - third].confirmed : 0;
      return { total, oldTotal, oldestTotal };
    } else {
      console.log("dateArr is not an array: " + dateArr);
      return null;
    }
  };

  const calcChange = ({ total, oldTotal, oldestTotal }) => {
    const first = total - oldTotal;
    const last = oldTotal - oldestTotal;
    const changePercentage = _.round(((first - last) / last) * 100, 1);
    if (changePercentage <= -100 || isNaN(changePercentage) || !isFinite(changePercentage || total === 0)) {
      // console.log("nope, its null");
      return 0;
    } else {
      // console.log("yup, here's the percentage: " + JSON.stringify(changePercentage));
      return changePercentage;
    }
  };

  // testing statistics library
  // const jam = (dates, peakDate) => {
  //   const arrayOfNew = dates.map(e => e.new)
  //   const joe = ss.linearRegression([[0, peakDate.new], [1, arrayOfNew[arrayOfNew.length-1]]]);
  //   return joe;
  // }

  const calcRealRecovered = (total, deaths, pastTwoWeeks) => {
    return total - deaths - pastTwoWeeks;
  };

  return (
    <div style={{ textAlign: "center" }}>
      {todayTotal && allDates && (
        <div>
          {todayTotal.confirmed ? (
            <div style={{ marginBottom: "12px" }}>
              <div>
                {/*<h3>{JSON.stringify(jam(allDates, peakDate))}</h3>*/}

                <div className="pl-0 pr-0 mx-2">
                  {/*<Row>*/}
                  {/*  <Col md="12" className="rounded-8 px-1 px-md-2 mb-2">*/}
                  {/*    <Badge color="light" className="d-block">some words here</Badge>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}

                  <Row>
                    {/*------------------*/}
                    {/*----LEFT SIDE----*/}
                    {/*------------------*/}
                    {/*<Col md="6" className="rounded-8 px-1 px-md-2" style={{ backgroundColor: "#fafafa" }}>*/}
                    <Col md="6" className="rounded-8 px-1 px-md-2">
                      {/*//--------------CONFIRMED-------------//*/}
                      <Row className="no-gutters align-items-center rounded-8 bg-gray-1">
                        <Col md="12" xs="12">
                          <div className="py-0 p-md-1">
                            <div className="mb-n3">Confirmed</div>
                            <div style={{ fontSize: "2.7em" }}>
                              <b> {todayTotal.confirmed.toLocaleString("en")} </b>
                            </div>
                          </div>
                        </Col>

                        {/*-----------DEATHS-------------*/}
                        {!cityData && (
                          <Col md="6" xs="6" className="px-0 px-md-1" style={{ color: "rgb(181,91,89)" }}>
                            <div className="py-1 p-md-1">
                              <div className="mb-n2">Deaths</div>
                              <div style={{ fontSize: "1.5em" }}>
                                <b>{todayTotal.deaths ? todayTotal.deaths.toLocaleString("en") : "-"}</b>
                              </div>
                              <div className="mt-n2" style={{ fontSize: "11px" }}>
                                {_.round((todayTotal.deaths / todayTotal.confirmed) * 100)}%
                              </div>
                            </div>
                          </Col>
                        )}

                        {/*---------------RECOVERED---------------*/}
                        {!cityData && (
                          <Col md="6" xs="6" className="px-0 px-md-1" style={{ color: "#1da000" }}>
                            <div className="py-1 p-md-1">
                              <div className="mb-n2">Recovered</div>
                              <div style={{ fontSize: "1.5em" }} className="pt-0">
                                <b> {todayTotal.recovered ? todayTotal.recovered.toLocaleString("en") : "-"}</b>
                              </div>
                              <div className="mt-n2" style={{ fontSize: "11px" }}>
                                <div>{_.round((todayTotal.recovered / todayTotal.confirmed) * 100)}%</div>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>

                      {/*-----TESTING ESTIMATED RECOVERED--------*/}
                      {calcRealRecovered(todayTotal.confirmed, todayTotal.deaths, getDifference(allDates, 1, 15)) >=
                        todayTotal.recovered && (
                        <Row className="mt-2 mb-2 no-gutters align-items-center rounded-8 bg-gray-1">
                          <Col sm="12">
                            <div className="mb-n2 mt-1 font-sm">Estimated Actual Recovered</div>
                            <div style={{ fontSize: "1.9em" }} className="pt-0 font-weight-bold text-success">
                              {calcRealRecovered(
                                todayTotal.confirmed,
                                todayTotal.deaths,
                                getDifference(allDates, 1, 15)
                              ).toLocaleString()}
                            </div>
                          </Col>
                        </Row>
                      )}

                      {/*//--------------PEAK DATE-------------//*/}
                      <Row
                        className="mt-2 mb-2 no-gutters align-items-center justify-content-center rounded-8"
                        style={{ backgroundColor: "rgba(255, 221, 117, 1)" }}
                      >
                        {peakDate && (
                          <Col md="12" xs="6" className="pt-0 py-1 p-md-1 px-0">
                            {!!peakDate.new && (
                              <div className="px-0">
                                <div style={{ marginBottom: "-12px" }}>Overall Peak Date</div>
                                {/*<span className="badge mx-1 opacity-3 mt-2">*/}
                                <span
                                  className={classNames("badge", "mx-1", "opacity-3", {
                                    "badge-danger mt-2 opacity-5": moment()
                                      .utc()
                                      .subtract(15, "days")
                                      .isBefore(moment(peakDate.date, "MM/DD/YY").utc().endOf("day")),
                                  })}
                                >
                                  {moment(peakDate.date, "MM/DD/YY").utc().endOf("day").fromNow()}

                                  {/*{moment()*/}
                                  {/*  .utc()*/}
                                  {/*  .subtract(8, "days")*/}
                                  {/*  .isBefore(*/}
                                  {/*    moment(peakDate.date, "MM/DD/YY")*/}
                                  {/*      .utc()*/}
                                  {/*      .endOf("day")*/}
                                  {/*  ) && <div>hi</div>}*/}
                                </span>
                                <div className="d-flex justify-content-center" style={{ fontSize: "1.5em" }}>
                                  <Badge color="light mx-1">{moment(peakDate.date, "MM/DD/YY").format("MMM DD")}</Badge>
                                  <Badge color="light mx-1">{peakDate.new.toLocaleString()}</Badge>
                                </div>
                                {/*<div className="opacity-2" style={{ fontSize: "11px" }}>*/}
                                {/*  Date With Most Daily Cases*/}

                                {/*</div>*/}
                              </div>
                            )}
                          </Col>
                        )}

                        {sixtyDayLow && (
                          <Col md="12" xs="6" className="pt-0 py-1 p-md-1 px-0">
                            {!!sixtyDayLow.new && (
                              <div className="px-0">
                                <div style={{ marginBottom: "-12px" }}>60-Day Low</div>
                                <span className="badge mx-1 opacity-3">
                                  {moment(sixtyDayLow.date, "MM/DD/YY").utc().endOf("day").fromNow()}
                                </span>
                                <div className="d-flex justify-content-center" style={{ fontSize: "1.5em" }}>
                                  <Badge color="light mx-1">
                                    {moment(sixtyDayLow.date, "MM/DD/YY").format("MMM DD")}
                                  </Badge>
                                  <Badge color="light mx-1">{sixtyDayLow.new.toLocaleString()}</Badge>
                                </div>
                                {/*<div className="opacity-2" style={{ fontSize: "11px" }}>*/}
                                {/*  Least Cases In Past 90-Days*/}
                                {/*</div>*/}
                              </div>
                            )}
                          </Col>
                        )}

                        {sixtyDayPeakDate && (
                          <Col md="12" xs="6" className="pt-0 py-1 p-md-1 px-0">
                            {sixtyDayPeakDate.new !== peakDate.new && !!sixtyDayPeakDate.new && (
                              <div className="px-0">
                                <div style={{ marginBottom: "-12px" }}>60-Day Peak</div>
                                <span className="badge mx-1 opacity-3">
                                  {moment(sixtyDayPeakDate.date, "MM/DD/YY").utc().endOf("day").fromNow()}
                                </span>
                                <div className="d-flex justify-content-center" style={{ fontSize: "1.5em" }}>
                                  <Badge color="light mx-1">
                                    {moment(sixtyDayPeakDate.date, "MM/DD/YY").format("MMM DD")}
                                  </Badge>
                                  <Badge color="light mx-1">{sixtyDayPeakDate.new.toLocaleString()}</Badge>
                                </div>
                                {/*<div className="opacity-2" style={{ fontSize: "11px" }}>*/}
                                {/*  Most Cases In Past 90-Days*/}
                                {/*</div>*/}
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>
                    </Col>

                    {/*------------------*/}
                    {/*----RIGHT SIDE----*/}
                    {/*------------------*/}

                    {/*// --- CHANGE SINCE YESTERDAY --- //*/}
                    <Col md="6" className="rounded-8 px-1 px-md-2">
                      {!calcChange({
                        total: todayTotal.confirmed,
                        oldTotal: yesterdayTotal.confirmed,
                        oldestTotal: ereYesterdayTotal.confirmed,
                      }) ? (
                        <div>{!cityData && <div className="opacity-2 rectangle-gray">data for today pending</div>}</div>
                      ) : (
                        <div>
                          {/*TESTING NEW CALCCHANGE*/}
                          <ThreeBoxDataRow
                            changePercent={calcChange({
                              total: todayTotal.confirmed,
                              oldTotal: yesterdayTotal.confirmed,
                              oldestTotal: ereYesterdayTotal.confirmed,
                            })}
                            changeAmount={todayTotal.confirmed - yesterdayTotal.confirmed}
                            timespanText="so far today"
                            boxOneText="New Cases Today"
                            boxTwoText="Versus Yesterday"
                          />
                        </div>
                      )}

                      {/*TESTING YESTERDAY*/}
                      {!!calcChange(getConfirmed(allDates, 1, 2, 3)) &&
                        getConfirmed(allDates, 1).total - getConfirmed(allDates, 2).total > 50 &&
                        cityData && (
                          <div className="mb-2">
                            <ThreeBoxDataRow
                              changePercent={calcChange(getConfirmed(allDates, 1, 2, 3))}
                              changeAmount={getConfirmed(allDates, 1).total - getConfirmed(allDates, 2).total}
                              timespanText="yesterday vs the prior day"
                              boxOneText="Yesterday's Cases"
                              boxTwoText="Yesterday vs Prior Day"
                              // days="1"
                              // showTrend="true"
                            />
                          </div>
                        )}

                      {/*TESTING WEEKLY*/}
                      {!!calcChange(getConfirmed(allDates, 1, 8, 15)) && (
                        <div className={classNames({ "mt-2": !cityData })}>
                          <ThreeBoxDataRow
                            changePercent={calcChange(getConfirmed(allDates, 1, 8, 15))}
                            changeAmount={getConfirmed(allDates, 1).total - getConfirmed(allDates, 8).total}
                            timespanText="these past 7 days"
                            boxOneText="7-Day Average"
                            boxTwoText="7-Day Change"
                            days="7"
                            showTrend="true"
                          />
                        </div>
                      )}

                      {/*TWO WEEK COMPARISON*/}
                      {!!calcChange(getConfirmed(allDates, 1, 15, 29)) && (
                        <div className="mt-2">
                          <ThreeBoxDataRow
                            changePercent={calcChange(getConfirmed(allDates, 1, 15, 29))}
                            changeAmount={getConfirmed(allDates, 1).total - getConfirmed(allDates, 15).total}
                            timespanText="these past 14 days"
                            boxOneText="14-Day Average"
                            boxTwoText="14-Day Change"
                            showTrend="true"
                            days="14"
                          />
                        </div>
                      )}

                      {/*MONTHLY COMPARISON*/}
                      {!!calcChange(getConfirmed(allDates, 1, 31, 61)) && (
                        <div className="mt-2">
                          <ThreeBoxDataRow
                            changePercent={calcChange(getConfirmed(allDates, 1, 31, 61))}
                            changeAmount={getConfirmed(allDates, 1).total - getConfirmed(allDates, 31).total}
                            timespanText="these past 30 days"
                            boxOneText="30-Day Average"
                            boxTwoText="30-Day Change"
                            showTrend="true"
                            days="30"
                          />
                        </div>
                      )}

                      {/*60 DAY COMPARISON*/}
                      {/*{!!calcChange(getConfirmed(allDates, 1, 61, 121)) && (*/}
                      {/*  <div className="mt-2">*/}
                      {/*    <ThreeBoxDataRow*/}
                      {/*      changePercent={calcChange(getConfirmed(allDates, 1, 61, 121))}*/}
                      {/*      changeAmount={getConfirmed(allDates, 1).total - getConfirmed(allDates, 61).total}*/}
                      {/*      timespanText="these past 30 days"*/}
                      {/*      boxOneText="60-Day Average"*/}
                      {/*      boxTwoText="60-Day Change"*/}
                      {/*      showTrend="true"*/}
                      {/*      days="60"*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*)}*/}

                      {/*PUT REST OF LOGIC HERE FOR WEEKLY CALCULATIONS*/}
                      {/*PUT REST OF LOGIC HERE FOR WEEKLY CALCULATIONS*/}
                      {/*PUT REST OF LOGIC HERE FOR WEEKLY CALCULATIONS*/}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          ) : (
            <Card className="loading-card">
              <h6>REGIONAL DATA LOADING</h6>
              <Spinner className="mx-auto opacity-1" color="secondary" />
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailedCasesDash;
