import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Spinner, Row, Col } from "reactstrap";
import axios from "axios";
import AddSubtopicForm from "./AddSubtopicForm";
import AddTopicGroup from "./AddTopicGroup";
import UpdateSubtopic from "./UpdateSubtopic";
import GoBack from "../../Utilities/GoBack";
import DeleteTopicButton from "./DeleteTopicButton";
import UpdateParentTopic from "./UpdateParentTopic";
import UpdateTopicGroup from "./UpdateTopicGroup";

const EditTopic = () => {
  // const [hasError, setErrors] = useState(false);
  const [topic, setTopic] = useState({});
  const [wasUpdated, setWasUpdated] = useState(null);

  const isMounted = useRef(true);
  let { id } = useParams();

  useEffect(
    () => {
      // isMounted.current = true;

      const fetchData = async () => {
        const response = await axios.get(`/api/topics/${id}`);
        // .then(res => {
        //   const data = res.data;
        //   if (isMounted.current) {
        //     setTopic(data);
        //   }
        // })
        // .catch(err => setErrors(err));

        if (isMounted.current) {
          setTopic(response.data);
          // console.log("isMounted.current is: " + isMounted.current);
        }
        // if (isMounted.current) {
        //   setTopic(response.data);
        //   console.log("DID WE RUN????")
        // }
      };

      fetchData();

      // console.log("useEffect in EditTopic ran " + JSON.stringify(topic));
      // console.log("wtf is isMounted: " + JSON.stringify(isMounted));

      return () => {
        isMounted.current = false;
      };
    },
    [topic, id]
  );

  useEffect(
    () => {
      const timer = setTimeout(() => {
        setWasUpdated(null);
      }, 7000);
      // console.log("in USEEEEE " + response);
      return () => clearTimeout(timer);
    },
    [wasUpdated]
  );

  const {
    category,
    page,
    parentTopicTitle,
    topicGroups,
    _id
    // subtitle,
    // image,
    // importance,
  } = topic;

  return (
    <div className="text-center">
      {!isMounted ? (
        <div>Doesn't exist =/</div>
      ) : (
        <div>
          {/*<div body style={{backgroundColor: "#e4e4e4"}}>*/}

          {!topic.category ? (
            <Card className="loading-card">
              <div>Loading topic</div>
              <Spinner className="mx-auto" color="secondary" />
            </Card>
          ) : (
            <div>
              <Col sm={{ size: "auto" }}>
                <DeleteTopicButton
                  setTopic={setTopic}
                  topicType="parentTopic"
                  idToDelete={_id}
                />
              </Col>
              <Row>
                <Col md="1">
                  <GoBack />
                </Col>
                <Col md="10">
                  <h3 className="mb-4 mt-2">
                    {category && (
                      <span>
                        {category && category} |{" "}
                        {parentTopicTitle && <b>{parentTopicTitle}</b>} |{" "}
                        {page && page}
                      </span>
                    )}
                  </h3>
                </Col>
                <Col md="1" />
              </Row>

              {/*-----------------*/}
              {/*-----------------*/}
              {/*EDIT TOPIC DETAILS*/}
              {/*-----------------*/}
              {/*-----------------*/}
              <Row className="justify-content-center" noGutters={true}>
                <Col sm="2">
                  <UpdateParentTopic setTopic={setTopic} topic={topic} />
                  <AddTopicGroup topicId={_id} setTopic={setTopic} />
                </Col>

                <Col sm="10">
                  {/*{topicGroups.length > 0 && (*/}
                  {/*  <Col md="4">*/}
                  {/*    <h2 className="title-outline-box text-black-50">*/}
                  {/*      ---TOPIC GROUPS---*/}
                  {/*    </h2>*/}
                  {/*  </Col>*/}
                  {/*)}*/}
                  <Col md="12">
                    {topicGroups &&
                      topicGroups.map(({ title, subtopics, image, _id }) => {
                        return (
                          <div className="gentle-outline-x" key={_id}>
                            {/*<h1>*/}
                            {/*  <b>{title}</b>*/}
                            {/*</h1>*/}
                            {/*<div style={{ fontSize: "14px", marginTop: "-20px" }}>*/}
                            {/*  (Topic Group)*/}
                            {/*</div>*/}
                            <Row noGutters={true}>
                              <Col md="12">
                                <UpdateTopicGroup
                                  groupId={_id}
                                  setTopic={setTopic}
                                  title={title}
                                  image={image}
                                />
                                {/*<DeleteTopicButton*/}
                                {/*  topicType="topicGroup"*/}
                                {/*  idToDelete={_id}*/}
                                {/*  setTopic={setTopic}*/}
                                {/*/>*/}
                              </Col>
                            </Row>

                            {/*------------------*/}
                            {/*DELETE TOPIC GROUP*/}
                            {/*------------------*/}
                            {/*<Col sm={{ size: "auto" }}>*/}
                            {/*  {" "}*/}
                            {/*  <DeleteTopicButton*/}
                            {/*    topicType="topicGroup"*/}
                            {/*    idToDelete={_id}*/}
                            {/*  />*/}
                            {/*</Col>*/}

                            <hr className="border-dark m-3" />
                            {/*------------------*/}
                            {/*SUBTOPICS SECTIONS*/}
                            {/*------------------*/}
                            <Row noGutters={true}>
                              <Col md="12">
                                <h5>
                                  <b>Subtopics</b>
                                </h5>
                              </Col>
                              {subtopics[0] &&
                                subtopics.map(subtopic => {
                                  return (
                                    <Col
                                      className="p-1"
                                      md="6"
                                      key={subtopic._id}
                                    >
                                      <UpdateSubtopic
                                        setTopic={setTopic}
                                        subtopic={subtopic}
                                        groupId={_id}
                                        setWasUpdated={setWasUpdated}
                                      />
                                      {wasUpdated && (
                                        <Card className="gentle-outline-x">
                                          <h3>Response</h3>
                                          {wasUpdated}
                                        </Card>
                                      )}
                                    </Col>
                                  );
                                })}

                              <Col className="p-1" md="6">
                                <AddSubtopicForm
                                  groupId={_id}
                                  parentTopic={title}
                                  setTopic={setTopic}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </Col>
                </Col>
              </Row>

              {/*----------------*/}
              {/*SHOW TOPIC GROUP*/}
              {/*----------------*/}
              {/*<Row className="justify-content-center">*/}

              {/*</Row>*/}
              {/*<AddSubtopicForm parentId={_id} existingTopic={true}*/}
              {/*             // parentTopic={parentTopicTitle}*/}
              {/*/>*/}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditTopic;
