import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner, Row, Col } from "reactstrap";
import axios from "axios";
import _ from "lodash";
import ChartNew from "../Charts/ChartNew";
import CountrySearchForm from "./CountrySearchForm";
import DetailedCasesDash from "../DetailedCasesDash";

const CountryDash = ({ incomingCases, clickedCountry }) => {
  const [allCases, setAllCases] = useState([]);
  const [countryCase, setCountryCase] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [noResult, setNoResult] = useState(false);
  const _isMounted = useRef(true);

  useEffect(
    () => {
      // check if a clickedCountry value was passed down from CoronaDash and CoronaCaseTable
      const isThereClickedCountry = clickedCountry.trim() === null || clickedCountry.trim() === "";

      async function fetchData() {
        const result = await axios.get("/api/jhu/get/global-time");
        if (result.data.success) {
          // console.log("resssss" + result.data.jhuDataRes.jhuData);
          setAllCases(result.data.jhuDataRes.jhuData);
          setIsLoading(false);
        } else {
          console.log("res failed: " + JSON.stringify(result));
        }
      }

      isThereClickedCountry ? fetchData() : selectCountry(clickedCountry);

      return () => {
        _isMounted.current = false;
      };
    },
    [clickedCountry]
  );

  const selectCountry = countryInput => {
    let country = countryInput === "United States" ? "US" : countryInput;

    const filteredCases = allCases.filter(x => {
      return x["Country/Region"] === country;
    });

    // cleaned the Lat and Long fields that were screwing up the summing totals
    const result = filteredCases.map(({ Lat, Long, ...rest }) => ({ ...rest }));

    // result exists
    if (result[0]) {
      setSelectedCountry(countryInput);
      let cray = [];
      // let sliced = [];
      let notSliced = [];

      // when many Country/Region objects match, need to be merged
      if (result.length > 2) {
        result.forEach((array, i) => {
          const converted = Object.entries(result[i]).map(([key, value]) => ({
            // date: key.replace(/\/20/, ""),
            date: key,
            confirmed: parseInt(value)
          }));

          const countDaily = converted.map((obj, i, arr) => ({
            date: obj.date,
            confirmed: obj.confirmed,
            new: arr[i - 1] ? obj.confirmed - arr[i - 1].confirmed : obj.confirmed
          }));

          cray.push(countDaily);
        });

        //flattened double nested array to a single array of objects (i.e. [ [{}], [{}] ] => [ {}, {} ]
        const flatArr = cray.flat();

        // grouped objects by "date" property into new OBJECT:  { 1/22: [{date: 1/22, confirmed: 0, new: 64}, ....] }
        const groupedByDate = flatArr.reduce((acc, cur) => {
          let key = cur["date"];
          !acc[key] && (acc[key] = []);
          acc[key].push(cur);
          return acc;
        }, {});

        // to push the new looped data into and send this out as a final array
        let newArray = [];
        // essentially an Object.forEach() since groupedByDate was in Object format as written above
        Object.keys(groupedByDate).forEach(dateKey => {
          // console.log(JSON.stringify(groupedByDate[dateKey]))
          newArray.push({
            date: dateKey,
            confirmed: groupedByDate[dateKey].reduce((acc, cur) => {
              return acc + (!cur.confirmed ? 0 : cur.confirmed);
            }, 0),
            new: groupedByDate[dateKey].reduce((acc, cur) => {
              return acc + (!cur.new ? 0 : cur.new);
            }, 0)
          });
        });

        // sliced = newArray.slice(newArray.length - 30);
        notSliced = newArray;

        // const combinedArr = _.chain(cray).groupBy('date').mapValues(v => _.chain(v).map('value').flattenDeep()).value();
        // sliced = combinedArr.slice(combinedArr.length - 20);
        // console.log("cray " + JSON.stringify(cray));
      } else {
        const converted = Object.entries(result[0]).map(([key, value]) => ({
          // date: key.replace(/\/20/, ""),
          date: key,
          confirmed: parseInt(value)
        }));

        const countDaily = converted.map((obj, i, arr) => ({
          date: obj.date,
          confirmed: obj.confirmed,
          new: arr[i - 1] ? obj.confirmed - arr[i - 1].confirmed : obj.confirmed
        }));

        // remove first elements of the converted array
        // sliced = countDaily.slice(countDaily.length - 30);

        notSliced = countDaily;
      }

      // setCountryCase(sliced);
      setCountryCase(notSliced);
      setNoResult(false);
      // console.log("mannnnn");
    } else {
      // console.log("jicker");
      setNoResult(true);
    }
  };

  const filterCases = country => {
    let jam = country === "United States" ? "US" : country;
    const filtered = incomingCases.filter(c => c.country === jam);
    // let result = filtered.map(({ confirmed, deaths, recovered }) => ({ confirmed, deaths, recovered }))
    return filtered[0];
  };

  //to shorten cases being sent to components
  const sliceCase = (casesWithAllDates, shortenBy) => {
    return casesWithAllDates.slice(casesWithAllDates.length - shortenBy);
  };

  const calcPeakDate = country => {
    return country.reduce((peakDate, date) => {
      return (peakDate.new || 0) > date.new ? peakDate : date;
    }, {});
  };

  const calcSixtyDayPeak = country => {
    const countrySliced = _.takeRight(country, 60);
    return countrySliced.reduce((peakDate, date) => {
      return (peakDate.new || 0) > date.new ? peakDate : date;
    }, {});
  };

  const calcSixtyDayLow = country => {
    const countrySliced = _.takeRight(country, 60);
    return countrySliced.reduce((lowestDate, date) => {
      return lowestDate.new < date.new || 1 > date.new ? lowestDate : date;
    }, {});
  };

  //---------------------------------------------------------//
  //---------------------------------------------------------//
  //---------------------------------------------------------//
  return (
    <div>
      {isLoading ? (
        <Card className="loading-card">
          <Spinner className="mx-auto opacity-1" color="secondary" />
        </Card>
      ) : (
        <Card
          body
          className="p-2 p-md-3"
          // style={{ minHeight: "500px" }}
        >
          {/*{console.log(JSON.stringify(countryCase))}*/}
          <CountrySearchForm selectCountry={selectCountry} />
          {noResult && (
            // <div className="pt-1 mt-2 mb-0 px-3 d-inline-block">
            <div className="mt-n1 mb-0 px-3 d-inline-block">
              <h5>
                <b>we currently have no data for that country</b>
              </h5>
            </div>
          )}

          {countryCase.length > 0 ? (
            <div>
              <h5 className="mb-md-n5 mb-n4 mt-sm-1 opacity-1">
                <b>{selectedCountry}</b>
              </h5>
              <ChartNew selection={sliceCase(countryCase, 121)} />
              <h4 className="opacity-2 mt-n3 mb-0">
                <b>{selectedCountry}</b>
              </h4>
              <DetailedCasesDash
                todayTotal={filterCases(selectedCountry)}
                yesterdayTotal={countryCase[countryCase.length - 1]}
                ereYesterdayTotal={countryCase[countryCase.length - 2]}
                peakDate={calcPeakDate(countryCase)}
                sixtyDayLow={calcSixtyDayLow(countryCase)}
                sixtyDayPeakDate={calcSixtyDayPeak(countryCase)}
                allDates={countryCase.slice(3, countryCase.length)}
              />
              <div className="opacity-2 mt-n2 mb-n1 font-sm">
                <b>KINDTHINK.COM</b>
              </div>
            </div>
          ) : (
            <Row noGutters={true}>
              <Col>
                <h3 className="opacity-1 p-4" style={{ paddingTop: "80x" }}>
                  select a country from the chart or search above
                </h3>
              </Col>
            </Row>
          )}
        </Card>
      )}
    </div>
  );
};

export default CountryDash;

// {/*<div className="gentle-outline-x">*/}
// {/*  <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>*/}
// {/*    <h4>*/}
// {/*      <b>Select Country</b>*/}
// {/*    </h4>*/}
//
// {/*    <input*/}
// {/*      className="form-control"*/}
// {/*      name="country"*/}
// {/*      placeholder="select country"*/}
// {/*      ref={register({ required: true })}*/}
// {/*    />*/}
//
// {/*    <input*/}
// {/*      type="submit"*/}
// {/*      disabled={!formState.dirty || formState.isSubmitting}*/}
// {/*      className="btn btn-dark mt-1"*/}
// {/*    />*/}
// {/*  </Form>*/}
// {/*  */}
// {/*  /!*{response && (*!/*/}
// {/*  /!*  <Card className="gentle-border">*!/*/}
// {/*  /!*    <h3>Response</h3>*!/*/}
// {/*  /!*    {JSON.stringify(selection)}*!/*/}
// {/*  /!*  </Card>*!/*/}
// {/*  /!*)}*!/*/}
// {/*</div>*/}

// ORIGINALLY USED THIS IN useEffect
// axios
//   .get("/api/jhu/get/global-time")
//   .then(res => {
//     const result = res.data.jhuDataRes.jhuData.filter(x => {
//       return x["Country/Region"] === selectedCountry;
//     });
//
//     const converted = Object.entries(result[0]).map(([key, value]) => ({
//       date: key.replace(/\/20/, ""),
//       confirmed: parseInt(value)
//     }));
//
//     const slick = converted.map((obj, i, arr) => ({
//       date: obj.date,
//       confirmed: obj.confirmed,
//       new: arr[i - 1]
//         ? obj.confirmed - arr[i - 1].confirmed
//         : obj.confirmed
//     }));
//
//     // remove first 4 elements of the converted array
//     const sliced = slick.slice(56);
//
//     if (_isMounted.current) {
//       setCases(sliced);
//       setIsLoading(false);
//       // console.log(JSON.stringify(result));
//     }
//   })
//   .catch(err => {
//     console.log("ERRRRR " + err);
//     return null;
//   });
