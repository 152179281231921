import React from "react";
import { Col, Row, Badge } from "reactstrap";

const ThreeBoxDataRow = ({ changeAmount, changePercent, timespanText, boxOneText, boxTwoText, showTrend, days }) => {
  return (
    <Row
      className=" align-items-center no-gutters justify-content-center rounded-8" // px-0 px-md-2 no-gutters
      style={{
        backgroundColor: showTrend
          ? changePercent < 0
            ? "rgb(208, 255, 211)"
            : "rgb(255, 219, 220)"
          : changePercent < 0
          ? "rgba(208, 255, 211, 0.2)"
          : "rgba(255, 219, 220, 0.2)",
        border: !showTrend && "1px solid rgb(232, 232, 229)",
      }}
    >
      <Col md="6" xs="6">
        <div className="py-1 p-0 justify-content-center align-items-center">
          <div className="mb-n1 opacity-3" style={{ fontSize: "0.8em" }}>
            {boxOneText && boxOneText}
          </div>
          <div
            style={{
              fontSize: "1.5em",
            }}
          >
            <div
              style={{
                // transform: "translate(-2px, 0px)",
                fontWeight: "bold",
              }}
            >
              {/*{changeAmount.toLocaleString("en")}*/}
              {boxOneText === "Yesterday's Cases" || boxOneText === "New Cases Today" ? (
                <div>{changeAmount.toLocaleString("en")}</div>
              ) : (
                <div>{Math.round(changeAmount / days).toLocaleString("en")}</div>
              )}
            </div>
          </div>
          {/*<div className="mt-n2" style={{ fontSize: "11px" }}>Resets 23:59 GMT</div>*/}
          {/*<div className="mt-n2" style={{ fontSize: "11px" }}>*/}
          {/*  /!*since {timeSinceReset()}*!/*/}
          {/*  resets 12am GMT daily*/}
          {/*</div>*/}
        </div>
      </Col>
      <Col md="6" xs="6">
        <div className="py-1 p-lg-1 justify-content-center align-items-center">
          <div className="mb-n1 opacity-3" style={{ fontSize: "0.65em" }}>
            {boxTwoText && boxTwoText}
          </div>
          <div style={{ fontSize: "1.1em", color: "#6f6f6f" }}>
            <Badge color={changePercent < 0 ? "success" : "danger"} style={{ opacity: !showTrend && "0.6" }}>
              {changePercent.toLocaleString()}% {changePercent < 0 ? <span>▼</span> : <span>▲</span>}
            </Badge>
          </div>
        </div>
      </Col>
      <Col
        md="12"
        // sm="6" xs="12"
      >
        {/*<div className="p-md-1 mt-n2 opacity-3" style={{ lineHeight: "1", fontSize: "0.8em" }}>*/}
        {/*  Cases have <b> {changePercent < 0 ? "decreased" : "increased"} </b>{" "}*/}
        {/*  {timespanText && timespanText}*/}
        {/*</div>*/}

        {days && changeAmount > 10 && (
          <div className="mb-2 mx-2 mt-n1 opacity-4" style={{ lineHeight: "1" }}>
            <Badge color="light" className="d-block font-weight-normal">
              {/*{days}-day average: <b> {Math.round(changeAmount / days).toLocaleString("en")}</b>*/}
              {days}-day total cases: <b> {changeAmount.toLocaleString("en")}</b>
            </Badge>
          </div>
        )}

        {changePercent > 15 && showTrend === "true" && changeAmount > 200 && (
          // <div className="p-md-1 opacity-3" style={{ lineHeight: "1", fontSize: "0.9em", color: "red" }}>
          <div className="mb-2 mx-2 mt-n1 opacity-4" style={{ lineHeight: "1", fontSize: "0.9em", color: "red" }}>
            <Badge color="danger" className="d-block">
              ⚠ Significant upward trend ⚠
            </Badge>
          </div>
        )}

        {changePercent < -25 && showTrend === "true" && changeAmount > 200 && (
          <div className="mb-2 mx-2 mt-n1 opacity-3" style={{ lineHeight: "1", fontSize: "0.9em", color: "green" }}>
            <Badge color="success" className="d-block">
              ✫ Significant downward trend ✫
            </Badge>
          </div>
        )}
      </Col>
      {/*<Col  md="5" xs="5" >*/}
      {/*  Cases have{" "}*/}
      {/*  <b>gone {changePercent < 0 ? "down" : "up"}</b>{" "}*/}
      {/*  so far today*/}
      {/*</Col>*/}
      {/*<Col className="mt-n3 mb-1">*/}
      {/*  Cases have{" "}*/}
      {/*  <b>gone {changePercent < 0 ? "down" : "up"}</b>{" "}*/}
      {/*  so far today*/}
      {/*</Col>*/}
    </Row>
  );
};

export default ThreeBoxDataRow;
