import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import {
  // NavLink as RRNavLink,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useParams
} from "react-router-dom";
// import {
//   Navbar,
//   Collapse,
//   Nav,
//   NavLink,
//   NavItem,
//   NavbarToggler,
//   Container,
//   Card
// } from "reactstrap";
import TopicItem from "./TopicItem";
import CoronaDash from "../Cases/CoronaDash";
import TopicPage from "./TopicPage";
import TopicHeaderMenu from "./TopicHeaderMenu";
import axios from "axios";
import USDash from "../Cases/US/USDash";
import TopicHeaderLoading from "./TopicHeaderLoading";
// import TopicHeaderLoading from "./TopicHeaderLoading";
// import {hyphen} from "../Utilities/emdash";

// const hyphen = word => {
//   return word.replace(/[^a-zA-Z0-9\- ]/g,"").replace(/ /g, "-").toLowerCase();
// };

const Topics = () => {
  let { category, pageId } = useParams();
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [notFound, setNotFound] = useState("");

  let { path } = useRouteMatch();

  // const cleanPath = url => {
  //   const fixedPath = url.endsWith("/")
  //     ? url.substring(0, url.length - 1)
  //     : url;
  //   return fixedPath;
  // };

  useEffect(
    () => {
      // const titleBrowser = JSON.stringify(pageId);
      // document.title = document.title + ' - ' + {titleBrowser};

      let isCancelled = false;
      async function fetchData() {
        const response = await axios.get(
          `/api/topics/one-per-cat/${category}/`
        );

        if (!isCancelled) {
          // if (!Array.isArray(response.data) || !response.data.length) {
          //   setNotFound("topic was not found, here's a hug instead 🤗");
          //   setIsLoading(false);
          //   console.log("ran");
          // } else {
          setTopics(response.data);
          setIsLoading(false);
          // }

          // set no subtopic in order to redirect if they mistyped subtopic name into browser
          // if (!Array.isArray(response.data) || !response.data.length) {
          //   console.log("Error my friend, no response.data in TopicItem");
          // } else {
          //   // gives an array of [true, true, true], but if one is false, then the topic exists
          //   const trueArray = response.data.map(
          //     topObj => hyphen(topObj.parentTopicTitle) !== hyphen(topicId)
          //   );
          //   trueArray.every(x => x) && setNoSubtopic(topicId);
          // }
        }
        // return response.data;
      }

      fetchData();

      return () => {
        isCancelled = true;
      };
    },
    [category, pageId]
  );

  return (
    <div style={{ marginTop: "-11px" }}>
      {/*<TopicHeaderLoading />*/}
      {!Array.isArray(topics) || !topics.length || isLoading ? (
        <TopicHeaderLoading />
        // <div></div>
      ) : (
        <div>{topics.length > 1 && <TopicHeaderMenu topics={topics} />}</div>
      )}

      <Switch>
        <Route exact path={path}>
          <Redirect to="/t/covid19/data" />
        </Route>

        {/*{!Array.isArray(topics) || !topics.length ? (<span />) : (*/}
        {/*  <Route exact path={path}>*/}
        {/*    <Redirect to={`/topics/covid19/${hyphen(topics[0].category)}`} />*/}
        {/*  </Route>*/}
        {/*)}*/}

        <Route path={`${path}/data/us/l`} component={USDash} />
        <Route path={`${path}/data`} component={CoronaDash} />
        {/*<Route path={`${path}/map`} component={USMapChart} />*/}

        {/*{console.log(url + " path " + path)}*/}
        <Route path={`${path}/:pageId/:topicId`}>
          <TopicItem />
        </Route>

        <Route path={`${path}/:pageId`}>
          <TopicPage />
        </Route>

        {/*<Route path={`${path}`}>*/}
        {/*  <Redirect to="/*" />*/}
        {/*</Route>*/}

        {/*<Route path={`${path}/:topicId`}>*/}
        {/*  <TopicItem />*/}
        {/*</Route>*/}
      </Switch>
    </div>
  );
};

// const Fab = () => {
//   // The `path` lets us build <Route> paths that are
//   // relative to the parent route, while the `url` lets
//   // us build relative links.
//   let { path, url } = useRouteMatch();
//
//   return (
//     <div>
//       <Switch>
//         <Route path={`${path}/:topicId`}>
//           {/*<TopicItem />*/}
//           <TopicPage />
//         </Route>
//         {/*<Route path={`${path}/`}>*/}
//         {/*  <TopicItem />*/}
//         {/*</Route>*/}
//         <Route path={`${path}`}>
//           <Redirect to="/*" />
//         </Route>
//       </Switch>
//     </div>
//   );
// }

// const Jam = () => {
//   // The `path` lets us build <Route> paths that are
//   // relative to the parent route, while the `url` lets
//   // us build relative links.
//   let { path, url } = useRouteMatch();
//   let { topicId } = useParams();
//
//   if(!topicId) {
//
//   }
//
//   return (
//     <div>
//       <Switch>
//         <Route path={`${path}/:topicId`}>
//           <TopicItem />
//         </Route>
//         <Route path={`${path}/`}>
//           <TopicItem />
//         </Route>
//         <Route path={`${path}`}>
//           <Redirect to="/*" />
//         </Route>
//       </Switch>
//     </div>
//   );
// }

export default Topics;
