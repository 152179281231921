import React from // useEffect, useRef // useState
"react";
// import ReactDOM from "react-dom";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  // VictoryLine,
  // VictoryStack,
  // VictoryLabel,
} from "victory";
// import { Card, Spinner } from "reactstrap";
// import axios from "axios";
// import chartThemeOne from "./ChartThemeOne";
import moment from "moment";

const ChartNew = ({ selection }) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [countryData, setCountryData] = useState([]);
  // const _isMounted = useRef(true);

  // useEffect(() => {
  //   if(selection) {
  //     setCountryData(selection);
  //     // setIsLoading(false);
  //   }
  //   console.log("useEffect in ChartNew")
  // }, [selection])

  return (
    <div>
      <VictoryChart
        // padding={{ top: 17, bottom: 27, left: 37, right: 22 }}
        padding={{ top: 40, bottom: 30, left: 30, right: 20 }}
        height={180}
        // width={300}
        domainPadding={{ x: 15 }}
        animate={{
          duration: 500,
          onLoad: { duration: 500 },
        }}
        minDomain={{ y: 0 }}
        // adding the material theme provided with Victory
        theme={VictoryTheme.material}
        // theme={chartThemeOne}
        // domainPadding={{ x: [10, -10] }}
      >
        <VictoryAxis
          style={{
            tickLabels: {
              padding: 5,
              // angle: -45,
              fontSize: 6,
              verticalAnchor: "middle",
            },
            // axisLabel: { padding: 15, opacity: 0.2 }
          }}
          // label="Date"
          fixLabelOverlap={true}
          tickFormat={(x) => moment(moment(x, "MM/DD/YY")).format("MMM DD")}
        />
        <VictoryAxis
          style={{
            axisLabel: { padding: 15, opacity: 0.1 },
            tickLabels: { padding: 1, fontSize: 10 },
          }}
          dependentAxis
          // label="New Cases"
          tickFormat={(x) => (x > 999 ? `${x / 1000}k` : x)}
        />

        {/*<VictoryStack>*/}
        <VictoryBar
          barWidth={2}
          style={{
            data: { fill: "#F4511E" },
          }}
          data={selection.map((obj) => {
            const container = {
              date: obj.date,
              new: obj.new < 0 ? 0 : obj.new,
            };
            return container;
          })}
          x="date"
          y="new"
          labels={({ datum }) =>
            `${datum.new.toLocaleString()} \n${moment(moment(datum.date, "MM/DD/YY")).format("MMMM DD")}`
          }
          fixLabelOverlap={true}
          // style={{ labels: { fill: "black", fontSize: "10px" } }}
          // labelComponent={<VictoryLabel dy={-4} />}
          labelComponent={<VictoryTooltip />}
        />
        {/*<VictoryLine*/}
        {/*  interpolation="catmullRom"*/}
        {/*  style={{*/}
        {/*    data: { stroke: "rgba(0,0,0,0.1)" },*/}
        {/*    parent: { border: "1px solid #ccc" }*/}
        {/*  }}*/}
        {/*  data={selection}*/}
        {/*  x="date"*/}
        {/*  y="new"*/}
        {/*/>*/}

        {/*</VictoryStack>*/}
      </VictoryChart>
      {/*)}*/}
    </div>
  );
};

export default ChartNew;

// {/*{isLoading ? (*/}
// {/*  <Card className="loading-card">*/}
// {/*    <Spinner*/}
// {/*      style={{ opacity: "0.8", margin: "auto" }}*/}
// {/*      // color="secondary"*/}
// {/*    />*/}
// {/*  </Card>*/}
// {/*) : (*/}
