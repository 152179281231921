import React, { useState, useEffect } from "react";
import { Card, Spinner, Table, Button } from "reactstrap";
import _ from "lodash";

const CoronaCaseTable = ({ incomingCases, setClickedCountry }) => {
  // const [hasError, setErrors] = useState(false);
  const [cases, setCases] = useState([]);

  useEffect(() => {
    // console.log('lin CaseTable ' + JSON.stringify(props));
    setCases(incomingCases);
  }, [incomingCases]);

  // const handleClick = country => {
  //   console.log("country: " + country)
  //   countryClick(country);
  // };

  return (
    <div>
      {/*{hasError === true && <span style={{ color: "black" }}>Has error</span>}*/}

      {!Array.isArray(cases) || !cases.length ? (
        <Card className="loading-card">
          <Spinner className="mx-auto opacity-1" color="secondary" />
        </Card>
      ) : (
        <div style={{ overflow: "auto", maxHeight: "545px" }}>
          <Table size="sm" className="mb-0" responsive bordered striped>
            <thead>
              <tr style={{ fontSize: "11px" }}>
                <th>Region</th>
                <th>Confirmed Cases</th>
                <th>Recovered</th>
                <th>Deaths</th>
                <th>Case Fatality Risk</th>
                <th>Closed Cases</th>
                <th>Closed Case Fatality Risk</th>
              </tr>
            </thead>
            <tbody>
              {cases.map(({ country, confirmed, deaths, active, recovered }) => {
                const percentageRecovered = _.round((deaths / (deaths + recovered)) * 100);
                return (
                  <tr key={country}>
                    <td>
                      <Button
                        className="m-0 px-1 bg-light text-dark"
                        color="link"
                        onClick={() => setClickedCountry(country)}
                      >
                        {country}
                      </Button>
                    </td>
                    <td>{confirmed.toLocaleString("en")}</td>
                    <td className="text-success">
                      {recovered && percentageRecovered < 50 ? <b>{recovered.toLocaleString("en")}</b> : <span>-</span>}
                    </td>
                    <td style={{ color: "#a90014" }}>{deaths.toLocaleString("en")}</td>
                    <td>{_.round((deaths / confirmed) * 100, 1)}%</td>
                    <td>{(deaths + recovered).toLocaleString("en")}</td>
                    <td>
                      {deaths + recovered > 0 && percentageRecovered < 50
                        ? _.round((deaths / (deaths + recovered)) * 100) + "%"
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default CoronaCaseTable;
