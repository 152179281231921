import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Form } from "reactstrap";
import axios from "axios";

const AddParentTopic = () => {
  const { register, handleSubmit, errors, reset, formState, watch } = useForm({
    defaultValues: { category: "covid19", importance: 100 }
  }); // initialise the hook
  const [response, setResponse] = useState(null);
  const addNavItem = watch("addNavItem");

  // const { register, handleSubmit, watch, errors } = useForm(); // initialise the hook
  // const addImportance = watch("addImportance");

  const onSubmit = async data => {
    delete data.addNavItem;

    console.log(data);

    const man = await axios
      .post(`/api/topics`, data)
      .then(res => {
        console.log("data was added " + JSON.stringify(res.data));
        setResponse(res.data);
        return res.data;
      })
      .catch(err => {
        setResponse({ err: err, msgFromEm: "error in post =/" });
        return err;
      });

    if (man.success) {
      setResponse(man.topic);
      reset();
    }
  };

  return (
    <Card body>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <h4>
          Add <b>Parent</b> Topic
        </h4>
        <input
          className="form-control"
          name="category"
          placeholder="category"
          ref={register({ required: true })}
        />
        {errors.category && "category is required"}

        <input
          className="form-control"
          name="page"
          placeholder="page"
          ref={register({ required: true })}
        />
        {errors.page && "page is required"}

        <input
          className="form-control"
          name="parentTopicTitle"
          placeholder="parent topic title"
          ref={register({ required: true })}
          // ref={register({ pattern: /\d+/ })}
        />
        {errors.parentTopic && "Please enter parent topic title"}

        <input
          className="form-control"
          name="image"
          placeholder="image"
          ref={register}
        />
        <input
          className="form-control"
          name="subtitle"
          placeholder="subtitle"
          ref={register}
        />
        {/*<div>Add Importance? <input name="addImportance" type="checkbox" ref={register} /></div>*/}

        {/*{addImportance && (*/}
        <input
          className="form-control"
          name="importance"
          type="number"
          placeholder="importance"
          ref={register}
        />
        <input
          className="form-control"
          name="disclaimer"
          placeholder="disclaimer"
          ref={register}
        />

        <div className="gentle-outline mt-3">
          <div className="font-sm">
            Is It A Nav Item?{" "}
            <input name="addNavItem" type="checkbox" ref={register} />
          </div>

          {addNavItem && (
            <div>
              <input
                className="form-control"
                name="navItem.text"
                placeholder="nav item TEXT"
                ref={register}
              />
              <input
                className="form-control"
                name="navItem.image"
                placeholder="nav item IMAGE"
                ref={register}
              />
              <input
                className="form-control"
                name="navItem.ranking"
                type="number"
                placeholder="nav item RANKING"
                ref={register}
              />
            </div>
          )}
        </div>

        {/*)}*/}

        <input
          type="submit"
          disabled={!formState.dirty || formState.isSubmitting}
          className="btn btn-dark mt-1"
        />
      </Form>
      <br />

      {response && (
        <Card body>
          <h3>Response</h3>
          {JSON.stringify(response)}
        </Card>
      )}
    </Card>
  );
};

export default AddParentTopic;
