import React from "react";
import { Row, Col } from "reactstrap";
import AddParentTopic from "./AddParentTopic";
// import AddTopicGroup from "./AddTopicGroup";
// import DeleteCases from "../../Cases/CaseAdmin/DeleteCases";
// import AddSubtopicForm from "./AddSubtopicForm";
import GetAllTopics from "./GetAllTopics";

const TopicsAdminDash = () => {
  return (
    <div className="text-center">
      <Row >
        <Col>
          <GetAllTopics />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <AddParentTopic />
        </Col>

        {/*<AddTopicGroup />*/}
        {/*<AddSubtopicForm />*/}

      </Row>
    </div>
  );
};

export default TopicsAdminDash;

// <Button onClick={this.updateTest}>Update Test</Button>

// } else {
//   return (
//     <Container style={{ textAlign: "center", marginTop: "20px" }}>
//       {/*<Button onClick={updateData}>Update Data</Button>*/}
//       <Button onClick={this.fetchInfo}>It Was Updated</Button>
//       <Button onClick={this.updateTest}>Update Test</Button>
//
//       {!Array.isArray(data) || !data.length ? (
//         <div />
//       ) : (
//         <div>
//           <Card body>
//             <Table size="sm" responsive bordered striped>
//               <thead>
//               <tr style={{ fontSize: "11px" }}>
//                 <th>Region</th>
//                 <th>Confirmed Cases</th>
//                 <th>Deaths</th>
//                 <th>Case Fatality Risk</th>
//                 <th>Closed Cases</th>
//                 <th>Closed Case Fatality Risk</th>
//               </tr>
//               </thead>
//               <tbody>
//               {data &&
//               data.map(
//                 ({ country, confirmed, deaths, active, recovered }) => {
//                   return (
//                     <tr key={country}>
//                       <td>{country}</td>
//                       <td>{confirmed.toLocaleString("en")}</td>
//                       <td style={{ color: "#a90014" }}>
//                         {deaths.toLocaleString("en")}
//                       </td>
//                       <td>{_.round((deaths / confirmed) * 100, 2)}%</td>
//                       <td>
//                         {(deaths + recovered).toLocaleString("en")}
//                       </td>
//                       <td>
//                         {deaths + recovered > 0
//                           ? _.round(
//                             (deaths / (deaths + recovered)) * 100
//                           )
//                           : 0}%
//                       </td>
//                     </tr>
//                   );
//                 }
//               )}
//               </tbody>
//             </Table>
//
//             <Card style={{ fontSize: "10px" }}>
//                   <span>
//                     Updated at{" "}
//                     {moment(global.dateAdded).format("MMMM Do YYYY, h:mm:ss a")}{" "}
//                     PST
//                   </span>
//             </Card>
//           </Card>
//         </div>
//       )}
//     </Container>
//   );
// }
// }

// fetchInfo = name => {
// axios
//   .get(
//     `https://services9.arcgis.com/N9p5hsImWXAccRNI/arcgis/rest/services/Z7biAeD8PAkqgmWhxG2A/FeatureServer/2/query?where=confirmed%3E1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=standard&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=true&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=`
//   )
//   .then(res => {
//     const feat = res.data.features;
//
//     console.log("we're inside TopicsAdminDash axios get request");
//
//     const arr = feat.map(obj => ({
//       country: obj.attributes.Country_Region,
//       confirmed: obj.attributes.Confirmed,
//       deaths: obj.attributes.Deaths,
//       active: obj.attributes.Active,
//       recovered: obj.attributes.Recovered
//     }));
//
//     const final = Object.keys(arr).map(key => arr[key]);
//
//     axios.post(`/api/info/`, { array: final }).then(res => {
//       this.setState({
//         data: res.data.allCases,
//         isLoaded: true
//       });
//     });
//   })
//   .catch(function(error) {
//     // handle error
//     console.log(error);
//     return null;
//   });
// };

// import React, { useState, useCallback } from "react";
// import {Card, Container, Table, Button } from "reactstrap";
// import axios from "axios";
// import _ from "lodash";
// import moment from "moment";
//
// const TopicsAdminDash = () => {
//   // const [hasError, setErrors] = useState(false);
//   // const [success, setSuccess] = useState(false);
//   // const [data, setData] = useState({});
//   //
//   // const updateData = useCallback(async () => {
//   //   await axios
//   //     .get(
//   //       `https://services9.arcgis.com/N9p5hsImWXAccRNI/arcgis/rest/services/Z7biAeD8PAkqgmWhxG2A/FeatureServer/2/query?where=confirmed%3E1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=standard&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=true&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=`
//   //     )
//   //     .then(res => {
//   //       // const data = JSON.stringify(res.data);
//   //       // const parsed = JSON.parse(data);
//   //       const feat = res.data.features;
//   //
//   //       // console.log("DATAAA " + JSON.stringify(res.data));
//   //       console.log("we're inside TopicsAdminDash axios get request");
//   //
//   //       // features.attributes
//   //       // const arr = feat.map(obj =>
//   //       //  ( {country: obj.attributes.Country_Region})
//   //       // };
//   //
//   //       const arr = feat.map(obj => ({
//   //         country: obj.attributes.Country_Region,
//   //         confirmed: obj.attributes.Confirmed,
//   //         deaths: obj.attributes.Deaths,
//   //         active: obj.attributes.Active,
//   //         recovered: obj.attributes.Recovered
//   //       }));
//   //
//   //       const final = Object.keys(arr).map(key => arr[key]);
//   //
//   //       axios.post(`/api/info/`, { array: final }).then(res => {
//   //         setSuccess(true);
//   //         setData(res.data.allCases);
//   //       });
//   //     })
//   //     .catch(err => setErrors(err));
//   // }, []);
//   //
//   // return (
//   //   <Container style={{ textAlign: "center", marginTop: "20px" }}>
//   //     {hasError && <div>We got errors man</div>}
//   //     {success && <div>We succeeded my friend =].</div>}
//   //     <Button onClick={updateData}>Update Data</Button>
//   //
//   //     {!Array.isArray(data) ||
//   //       (!data.length && (
//   //         <div>
//   //           <Card body>
//   //             <Table size="sm" responsive bordered striped>
//   //               <thead>
//   //                 <tr style={{ fontSize: "11px" }}>
//   //                   <th>Region</th>
//   //                   <th>Confirmed Cases</th>
//   //                   <th>Deaths</th>
//   //                   <th>Case Fatality Risk</th>
//   //                   <th>Closed Cases</th>
//   //                   <th>Closed Case Fatality Risk</th>
//   //                 </tr>
//   //               </thead>
//   //               <tbody>
//   //                 {data.map(
//   //                   ({ country, confirmed, deaths, active, recovered }) => {
//   //                     return (
//   //                       <tr>
//   //                         <td>{country}</td>
//   //                         <td>{confirmed.toLocaleString("en")}</td>
//   //                         <td style={{ color: "#a90014" }}>
//   //                           {deaths.toLocaleString("en")}
//   //                         </td>
//   //                         <td>{_.round((deaths / confirmed) * 100, 2)}%</td>
//   //                         <td>{(deaths + recovered).toLocaleString("en")}</td>
//   //                         <td>
//   //                           {deaths + recovered > 0
//   //                             ? _.round((deaths / (deaths + recovered)) * 100)
//   //                             : 0}%
//   //                         </td>
//   //                       </tr>
//   //                     );
//   //                   }
//   //                 )}
//   //               </tbody>
//   //             </Table>
//   //
//   //             <Card style={{ fontSize: "10px" }}>
//   //               <span>
//   //                 Updated at{" "}
//   //                 {moment(global.dateAdded).format("MMMM Do YYYY, h:mm:ss a")}{" "}
//   //                 PST
//   //               </span>
//   //             </Card>
//   //           </Card>
//   //         </div>
//   //       ))}
//   //   </Container>
//   // );
// };
//
// export default TopicsAdminDash;
