import React from "react";
import { Card, Row, Col } from "reactstrap";

const NoMatch = ({ what }) => {
  const image = `https://res.cloudinary.com/hkf5qjpv3/image/upload/v1585905432/health-prem/misc/hug_1_tmiiwo.png`;

  return (
    <Row>
      <Col className="px-1 px-sm-2">
      <Card body className="text-center loading-card">
        <h3>this {what} doesn't exist yet you lovely person</h3>
        <h3>enjoy this hug instead</h3>
        <div>
          {" "}
          <img
            style={{ maxWidth: "80px" }}
            src={image}
            alt=""
          />
        </div>
        <div>then feel free to select another topic</div>
      </Card>
      </Col>
    </Row>
  );
};

export default NoMatch;
