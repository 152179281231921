import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

const GoBack = ({ history }) => (
  <Button
    className="custom-nav-btn px-3"
    onClick={() => history.goBack()}
    alt="Go back"
  >
    Back
  </Button>
);

export default withRouter(GoBack);
