// import moment from 'moment';

// class emdash {
  // static hyphen = word => {
  //   return word.replace(/[^a-zA-Z0-9\- ]/g,"").replace(/ /g, "-").toLowerCase();
  // }
// }

export const hyphen = word => {
  return word
    .replace(/[^a-zA-Z0-9\- ]/g, "")
    .replace(/ /g, "-")
    .toLowerCase();
};

// export default emdash;
