import React, { useState } from "react";
import { Navbar, Collapse, Nav, NavLink, NavItem, NavbarToggler, Container, Card, Spinner } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";

const TopicHeaderLoading = () => {
  const [isOpen, setIsOpen] = useState(true);
  const blankImage = `https://res.cloudinary.com/hkf5qjpv3/image/upload/v1586323003/health-prem/misc/circle_hojhbi.png`;
  const toggle = () => setIsOpen(!isOpen);

  const sixItems = ["", "", "", "", "", ""];
  // const sixItems = ["", ""];

  return (
    <div>
      <Navbar
        color="faded"
        light
        expand="lg"
        className="p-0 mb-1 justify-content-center"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px 0px",
          borderBottom: "1px solid rgba(0,0,0,0.13)"
        }}
      >
        <div className="justify-content-center container">
          <NavbarToggler className="btn-block light-btn-color opacity-3 mw-100" onClick={toggle}>
            {isOpen ? "CLOSE" : "SHOW ALL TOPICS"}
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            {/*<Collapse isOpen={isOpen} navbar style={{ height: "96px" }}>*/}
            <Nav
              className="mx-auto justify-content-center text-center align-items-center flex-wrap flex-row"
              style={{ fontSize: "12px", opacity: "0.3" }}
              // style={{ fontSize: "12px", opacity: "0.2", paddingTop: "25px" }}
              navbar
            >
              {/*<div className="d-flex justify-content-center">*/}
              {/*  <div className="spinner-border" role="status">*/}
              {/*    <span className="sr-only">Loading...</span>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {sixItems.map((item, index) => {
                return (
                  <NavItem className="col-4 col-md-2 col-lg-2 px-0" key={index}>
                    <NavLink>
                      <img style={{ width: "20px" }} src={blankImage} className="img-fluid w-25 pt-1" alt="" />
                      <div>KINDTHINK</div>
                    </NavLink>
                  </NavItem>
                );
              })}

              {/*<NavItem className="col-4 col-md-2 col-lg-2 px-0">*/}
              {/*  <NavLink>*/}
              {/*    <img style={{ width: "20px" }} src={blankImage} className="img-fluid w-25 pt-1" alt="" />*/}
              {/*    <div>KINDTHINK</div>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default TopicHeaderLoading;

// {sixItems.map(blank => {
//   return (
//     <NavItem className="col-4 col-md-2 col-lg-2 px-0 mx-auto">
//       <NavLink tag={RRNavLink} to="" style={{width: "180px", height: "97px"}}>
//         {/*<div*/}
//         {/*  style={{ width: "20px" }}*/}
//         {/*  className="img-fluid w-25"*/}
//         {/*/>*/}
//         <Spinner
//           className="gray-dot opacity-1"
//           style={{ marginTop: "10px" }}
//           color="secondary"
//         />
//         {/*<div className="nav-item-loading">DATA</div>*/}
//         {/*<div*/}
//         {/*  className="nav-item-loading"*/}
//         {/*  style={{ marginTop: "-6px" }}*/}
//         {/*>*/}
//         {/*  LOADING*/}
//         {/*</div>*/}
//       </NavLink>
//     </NavItem>
//   );
// })}
