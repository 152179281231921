import React, { useState } from "react";
// import { connect } from "react-redux";
import {
  NavLink as RRNavLink,
  useRouteMatch,
  // Switch,
  // Route,
  // Redirect,
  // useParams
} from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  NavLink,
  NavItem,
  NavbarToggler,
  // Container,
  // Card
} from "reactstrap";
import _ from "lodash";

const TopicHeaderMenu = ({ topics }) => {
  const [isOpen, setIsOpen] = useState(false);
  const blankImage = `https://res.cloudinary.com/hkf5qjpv3/image/upload/v1586323003/health-prem/misc/circle_hojhbi.png`;

  let { url } = useRouteMatch();

  const toggle = () => setIsOpen(!isOpen);

  const cleanPath = (url) => {
    const fixedPath = url.endsWith("/") ? url.substring(0, url.length - 1) : url;
    return fixedPath;
  };

  const splitPageNameText = (page) => {
    const splitText = _.split(page, "/", page.split("/").length);
    // console.log("page: " + page + " " + splitText);
    if (splitText.length > 1) {
      return (
        <div>
          {splitText[1].charAt(0) !== "|" ? (
            <div>
              <div>{splitText[0]} /</div>
              <div style={{ marginTop: "-6px" }}>{splitText[1]}</div>
            </div>
          ) : (
            <div>
              <div>{splitText[0]}</div>
              <div style={{ marginTop: "-6px" }}>{splitText[1].replace("|", "")}</div>
            </div>
          )}
        </div>
      );
    } else {
      return page;
    }
  };

  return (
    <div>
      <Navbar
        color="faded"
        light
        expand="lg"
        className="p-0 mb-1 justify-content-center"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px 0px",
          borderBottom: "1px solid rgba(0,0,0,0.13)",
        }}
      >
        <div className="justify-content-center container">
          <NavbarToggler style={{}} className="btn-block light-btn-color opacity-3 mw-100" onClick={toggle}>
            {isOpen ? "CLOSE" : "SHOW ALL TOPICS"}
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="mx-auto mt-lg-2 align-content-center text-center justify-content-center flex-wrap flex-row"
              style={{ fontSize: "12px" }}
              navbar
            >
              {topics.map(
                (
                  {
                    category,
                    parentTopicTitle, // navItemImage, // navItemText,
                    navItem,
                    page,
                    image,
                    _id,
                  },
                  topicIndex
                ) => {
                  return (
                    <NavItem key={_id} className="col-4 col-md-2 col-lg-2 px-0">
                      <NavLink tag={RRNavLink} to={`${cleanPath(url)}/${page}`}>
                        {navItem || image ? (
                          <img
                            style={{ width: "20px" }}
                            src={navItem.image ? navItem.image : image ? image : blankImage}
                            // src={image}
                            className="img-fluid w-25"
                            alt=""
                          />
                        ) : (
                          <img style={{ width: "20px" }} src={blankImage} className="img-fluid w-25" alt="" />
                        )}
                        {/*{navItem && JSON.stringify(navItem)}*/}

                        {navItem ? (
                          <div>{splitPageNameText(navItem.text.toUpperCase())}</div>
                        ) : (
                          <div>{parentTopicTitle.toUpperCase()}</div>
                        )}

                        {/*{navItemImage || image ? (*/}
                        {/*  <img*/}
                        {/*    style={{ width: "20px" }}*/}
                        {/*    src={navItemImage ? navItemImage : image}*/}
                        {/*    className="img-fluid w-25"*/}
                        {/*    alt=""*/}
                        {/*  />*/}
                        {/*) : (*/}
                        {/*  <img*/}
                        {/*    style={{ width: "20px" }}*/}
                        {/*    src={blankImage}*/}
                        {/*    className="img-fluid w-25"*/}
                        {/*    alt=""*/}
                        {/*  />*/}
                        {/*)}*/}

                        {/*{navItemText ? (*/}
                        {/*  <div>{splitPageNameText(navItemText.toUpperCase())}</div>*/}
                        {/*) : (*/}
                        {/*  <div>{parentTopicTitle.toUpperCase()}</div>*/}
                        {/*)}*/}
                      </NavLink>
                    </NavItem>
                  );
                  // <NavItem className="col-4 col-md-2 col-lg-2 px-0">
                  //   <NavLink tag={RRNavLink} to={`${cleanPath(url)}/symptoms`}>
                  //     <img
                  //       style={{ width: "20px" }}
                  //       src="https://res.cloudinary.com/hkf5qjpv3/image/upload/v1584839559/health-prem/009-fever_vii29n.png"
                  //       className="img-fluid w-25"
                  //       alt=""
                  //     />
                  //     <div>SYMPTOMS /</div>
                  //     <div style={{ marginTop: "-6px" }}>PREVENTION</div>
                  //   </NavLink>
                  // </NavItem>
                }
              )}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default TopicHeaderMenu;

// {/*future proof below in case multi line required*/}
// {/*{splitText*/}
// {/*  .slice(1)*/}
// {/*  .map((text, index) => (*/}
// {/*    <div style={{ marginTop: "-6px" }}>{text}</div>*/}
// {/*  ))}*/}
