import React, {
  useState
  // useEffect, useRef
} from "react";
import { Button, Input, Col } from "reactstrap";
import _ from "lodash";
// import moment from "moment";

const countries = [
  "United States",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  // "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  // "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Canada",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  // "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  // "Congo",
  "Congo (Brazzaville)",
  "Congo (Kinshasa)",
  // "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  // "Falkland Islands",
  // "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  // "French Polynesia",
  // "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  // "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kosovo",
  "Kenya",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "North Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  // "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  // "Saint Pierre and Miquelon",
  // "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Korea, South",
  "Spain",
  "Sri Lanka",
  // "St Kitts & Nevis",
  // "St Lucia",
  // "St Vincent",
  // "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan*",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  // "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  // "Turkmenistan",
  // "Turks and Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  // "Virgin Islands (US)",
  "West Bank and Gaza",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

const CountrySearchForm = ({ selectCountry }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  const cleanTerm = _.startCase(searchTerm.trim().toLocaleLowerCase());

  const results =
    !cleanTerm || cleanTerm.length < 2
      ? []
      : countries.filter(country => country.includes(cleanTerm));

  return (
    <div className="mt-1">
      <h6>COVID-19 COUNTRY SEARCH</h6>
      <Col sm="12" md={{ size: 6, offset: 3 }}>
        <Input
          className="text-center mt-2"
          type="text"
          placeholder="enter country name"
          value={searchTerm}
          onChange={handleChange}
        />
      </Col>
      {/*{cleanTerm.length < 2 && (*/}
      {/*  <div className="mt-n2">*/}
      {/*    /!*<b className="opacity-3">enter country name for detailed data</b>*!/*/}
      {/*    <div className="mt-n2 opacity-1">*/}
      {/*      results show after 2 letters*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      {cleanTerm.length > 1 &&
        results.length < 1 && (
          <div>
            <div className="text-black-50">
              country not found, please double check your spelling
            </div>
          </div>
        )}
      <div>
        {results.map(item => (
          <Button
            key={item}
            className="country-btn mt-0 mt-sm-1 mb-1 mb-sm-3"
            onClick={() => selectCountry(item)}
          >
            {item}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default CountrySearchForm;

//older country list
// const countries = [
//   "United States",
//   "Canada",
//   "Afghanistan",
//   "Albania",
//   "Algeria",
//   "American Samoa",
//   "Andorra",
//   "Angola",
//   "Anguilla",
//   "Antarctica",
//   "Antigua and/or Barbuda",
//   "Argentina",
//   "Armenia",
//   "Aruba",
//   "Australia",
//   "Austria",
//   "Azerbaijan",
//   "Bahamas",
//   "Bahrain",
//   "Bangladesh",
//   "Barbados",
//   "Belarus",
//   "Belgium",
//   "Belize",
//   "Benin",
//   "Bermuda",
//   "Bhutan",
//   "Bolivia",
//   "Bosnia and Herzegovina",
//   "Botswana",
//   "Bouvet Island",
//   "Brazil",
//   "British lndian Ocean Territory",
//   "Brunei Darussalam",
//   "Bulgaria",
//   "Burkina Faso",
//   "Burundi",
//   "Cambodia",
//   "Cameroon",
//   "Cape Verde",
//   "Cayman Islands",
//   "Central African Republic",
//   "Chad",
//   "Chile",
//   "China",
//   "Christmas Island",
//   "Cocos (Keeling) Islands",
//   "Colombia",
//   "Comoros",
//   "Congo",
//   "Cook Islands",
//   "Costa Rica",
//   "Croatia (Hrvatska)",
//   "Cuba",
//   "Cyprus",
//   "Czech Republic",
//   "Denmark",
//   "Djibouti",
//   "Dominica",
//   "Dominican Republic",
//   "East Timor",
//   "Ecudaor",
//   "Egypt",
//   "El Salvador",
//   "Equatorial Guinea",
//   "Eritrea",
//   "Estonia",
//   "Ethiopia",
//   "Falkland Islands (Malvinas)",
//   "Faroe Islands",
//   "Fiji",
//   "Finland",
//   "France",
//   "France, Metropolitan",
//   "French Guiana",
//   "French Polynesia",
//   "French Southern Territories",
//   "Gabon",
//   "Gambia",
//   "Georgia",
//   "Germany",
//   "Ghana",
//   "Gibraltar",
//   "Greece",
//   "Greenland",
//   "Grenada",
//   "Guadeloupe",
//   "Guam",
//   "Guatemala",
//   "Guinea",
//   "Guinea-Bissau",
//   "Guyana",
//   "Haiti",
//   "Heard and Mc Donald Islands",
//   "Honduras",
//   "Hong Kong",
//   "Hungary",
//   "Iceland",
//   "India",
//   "Indonesia",
//   "Iran",
//   "Iraq",
//   "Ireland",
//   "Israel",
//   "Italy",
//   "Ivory Coast",
//   "Jamaica",
//   "Japan",
//   "Jordan",
//   "Kazakhstan",
//   "Kenya",
//   "Kiribati",
//   "Korea, Democratic People's Republic of",
//   "Korea, Republic of",
//   "Kuwait",
//   "Kyrgyzstan",
//   "Lao People's Democratic Republic",
//   "Latvia",
//   "Lebanon",
//   "Lesotho",
//   "Liberia",
//   "Libyan Arab Jamahiriya",
//   "Liechtenstein",
//   "Lithuania",
//   "Luxembourg",
//   "Macau",
//   "Macedonia",
//   "Madagascar",
//   "Malawi",
//   "Malaysia",
//   "Maldives",
//   "Mali",
//   "Malta",
//   "Marshall Islands",
//   "Martinique",
//   "Mauritania",
//   "Mauritius",
//   "Mayotte",
//   "Mexico",
//   "Micronesia, Federated States of",
//   "Moldova, Republic of",
//   "Monaco",
//   "Mongolia",
//   "Montserrat",
//   "Morocco",
//   "Mozambique",
//   "Myanmar",
//   "Namibia",
//   "Nauru",
//   "Nepal",
//   "Netherlands",
//   "Netherlands Antilles",
//   "New Caledonia",
//   "New Zealand",
//   "Nicaragua",
//   "Niger",
//   "Nigeria",
//   "Niue",
//   "Norfork Island",
//   "Northern Mariana Islands",
//   "Norway",
//   "Oman",
//   "Pakistan",
//   "Palau",
//   "Panama",
//   "Papua New Guinea",
//   "Paraguay",
//   "Peru",
//   "Philippines",
//   "Pitcairn",
//   "Poland",
//   "Portugal",
//   "Puerto Rico",
//   "Qatar",
//   "Reunion",
//   "Romania",
//   "Russian Federation",
//   "Rwanda",
//   "Saint Kitts and Nevis",
//   "Saint Lucia",
//   "Saint Vincent and the Grenadines",
//   "Samoa",
//   "San Marino",
//   "Sao Tome and Principe",
//   "Saudi Arabia",
//   "Senegal",
//   "Seychelles",
//   "Sierra Leone",
//   "Singapore",
//   "Slovakia",
//   "Slovenia",
//   "Solomon Islands",
//   "Somalia",
//   "South Africa",
//   "South Georgia South Sandwich Islands",
//   "Spain",
//   "Sri Lanka",
//   "St. Helena",
//   "St. Pierre and Miquelon",
//   "Sudan",
//   "Suriname",
//   "Svalbarn and Jan Mayen Islands",
//   "Swaziland",
//   "Sweden",
//   "Switzerland",
//   "Syrian Arab Republic",
//   "Taiwan",
//   "Tajikistan",
//   "Tanzania, United Republic of",
//   "Thailand",
//   "Togo",
//   "Tokelau",
//   "Tonga",
//   "Trinidad and Tobago",
//   "Tunisia",
//   "Turkey",
//   "Turkmenistan",
//   "Turks and Caicos Islands",
//   "Tuvalu",
//   "Uganda",
//   "Ukraine",
//   "United Arab Emirates",
//   "United Kingdom",
//   "Uruguay",
//   "Uzbekistan",
//   "Vanuatu",
//   "Vatican City State",
//   "Venezuela",
//   "Vietnam",
//   "Virigan Islands (British)",
//   "Virgin Islands (U.S.)",
//   "Wallis and Futuna Islands",
//   "Western Sahara",
//   "Yemen",
//   "Yugoslavia",
//   "Zaire",
//   "Zambia",
//   "Zimbabwe"
// ];
