import React from "react";
import { Card, Row, Col } from "reactstrap";

import Questions from "./Questions";

const Misinformation = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Row>
        <Col md="12">
          <div>
            <Card body>
              <h5>TOPICS</h5>
              <Questions category={"misinformation"} />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Misinformation;
