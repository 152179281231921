import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Spinner, Button, Badge } from "reactstrap";
import axios from "axios";
// import _ from "lodash";
import moment from "moment";
import Questions from "../Questions";
import UpdateCoronaStats from "./CaseAdmin/UpdateCoronaStats";
import CoronaCaseTable from "./CoronaCaseTable";
import GlobalData from "./GlobalData";
import CountryDash from "./Country/CountryDash";
// import USDash from "./US/USDash";
import { Link } from "react-router-dom";

// import Topics from "./Topics/Topics";
// import Topics from "./Topics/Topics"
// import MarchCDCTable from "../TempNews/MarchCDCTable";
// import ChartOld from "./Charts/ChartOld";

const CoronaDash = () => {
  const [hasError, setErrors] = useState(false);
  const [corona, setCorona] = useState({});
  const [dateAdded, setDateAdded] = useState();
  const [allCases, setAllCases] = useState({});
  const [showMoreCases, setShowMoreCases] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const _isMounted = useRef(true);
  const [clickedCountry, setClickedCountry] = useState("");

  useEffect(
    () => {
      // let isCancelled = false;
      // let timeout;
      async function fetchData() {
        axios
          .get(`/api/corona/cases`)
          .then(res => {
            const allCases = res.data.allCases;
            const dateAdded = res.data.dateAdded;
            UpdateCoronaStats.updateData(dateAdded);

            const sorted = allCases.sort((a, b) => (a.confirmed > b.confirmed ? -1 : 1));
            // const filtered = sorted.filter(c => c.confirmed > 20000);
            const filtered = sorted.slice(0, 10);

            if (_isMounted.current) {
              setDateAdded(dateAdded);
              setCorona(filtered);
              setAllCases(sorted);
              // timeout = setTimeout(() => setClickedCountry("US"), 2000);
            }
          })
          .catch(err => {
            UpdateCoronaStats.updateUnconditionally();
            setErrors(err);
            console.log("In catch error of CoronaDash: " + JSON.stringify(err));
            return null;
          });
      }

      fetchData();

      // console.log("in stats useEffects");
      return () => {
        // isCancelled = true;
        // clearTimeout(timeout);
        _isMounted.current = false;
      };
    },
    [clickedCountry]
  );

  // const countryClickFunc = country => {
  //   setClickedCountry(country);
  // };

  return (
    <div style={{ textAlign: "center" }}>
      {hasError === true && (
        <span>Apologies, the data is taking longer to update, please wait a few seconds and then refresh the page</span>
      )}

      <Row>
        {/*<Col className="px-1 px-sm-2" lg="8">*/}
        <Col className="px-1 px-sm-2" lg="12">
          <GlobalData />
        </Col>

        <Col className="px-1 px-sm-2 mt-n2" lg="12">
          {/*<Button className="bg-white opacity-4 py-1 my-2 font-weight-bold" block color="light" tag={Link} to="/t/covid19/data/us/l?city=los-angeles&state=california">*/}
          <Button className="opacity-4 my-2 mx-0 py-2 badge d-block border country-btn" block tag={Link} to="/t/covid19/data/us/l?city=los-angeles&state=california">
            <div style={{ fontFamily: "Lato" }} className="font-size-md py-1 text-muted">VIEW UNITED STATES COUNTY DATA</div>
          </Button>
        </Col>

        {/*<Col className="px-1 px-sm-2 mt-n2" lg="12">*/}
        {/*  {!Array.isArray(corona) || !corona.length ? (*/}
        {/*    <Card className="loading-card">*/}
        {/*      <Spinner className="mx-auto opacity-1" color="secondary" />*/}
        {/*    </Card>*/}
        {/*  ) : (*/}
        {/*    <div>*/}
        {/*      <Button>Detailed United States Data (by city and state)</Button>*/}
        {/*      /!*<CountryDash incomingUSCases={allUSCases} />*!/*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</Col>*/}

        <Col className="px-1 px-sm-2 mt-n2" lg="6">
          {!Array.isArray(corona) || !corona.length ? (
            <Card className="loading-card">
              <Spinner className="mx-auto opacity-1" color="secondary" />
            </Card>
          ) : (
            <div>
              <CountryDash incomingCases={allCases} clickedCountry={clickedCountry} />
            </div>
          )}
        </Col>
        <Col className="px-1 px-sm-2 mt-n2" lg="6">
          {!Array.isArray(corona) || !corona.length ? (
            <Card className="loading-card">
              <Spinner className="mx-auto opacity-1" color="secondary" />
            </Card>
          ) : (
            <Card body>
              <div style={{ marginTop: "-12px", opacity: "0.7" }} className="mb-1 pb-0">
                <Badge color="light">
                  Data Updated {/*{moment(global.dateAdded).format("MMMM Do YYYY, h:mm:ss a")}{" "}PST*/}
                  {moment(dateAdded).fromNow()}
                </Badge>
              </div>
              {showMoreCases ? (
                <div>
                  <Button className="btn-light" block onClick={() => setShowMoreCases(false)}>
                    Show Less Countries
                  </Button>
                  <CoronaCaseTable incomingCases={allCases} setClickedCountry={setClickedCountry} />

                  <Button className="btn-light" block onClick={() => setShowMoreCases(false)}>
                    Show Less Countries
                  </Button>
                </div>
              ) : (
                <div>
                  <CoronaCaseTable incomingCases={corona} setClickedCountry={setClickedCountry} />

                  <Button className="btn-light" block onClick={() => setShowMoreCases(true)}>
                    Show All Countries
                  </Button>
                </div>
              )}
              <Card style={{ fontSize: "10px" }}>
                Data collected from World Health Organization, CDC, & Johns Hopkins University.
              </Card>
            </Card>
          )}
        </Col>

        {/*<Col className="px-1 px-sm-2 mt-n2" lg="6">*/}
        {/*  {!Array.isArray(corona) || !corona.length ? <Card className="loading-card">*/}
        {/*      <Spinner className="mx-auto opacity-1" color="secondary" />*/}
        {/*    </Card> : <div>*/}
        {/*      <ChartOld cases={corona.filter(c => c.confirmed > 75000)} />*/}
        {/*    </div>}*/}
        {/*</Col>*/}
        {/*<Col className="px-1 px-sm-2 mt-n2" lg="6">*/}
        {/*  <MarchCDCTable />*/}
        {/*</Col>*/}
      </Row>
      <Row>
        <Col className="px-1 px-sm-2 mt-n2" lg="12">
          {showQuestions ? (
            <Card body>
              <h5>INTERPRETING THE DATA</h5>

              <Questions category={"statistics"} />

              <Button className="btn-light" block onClick={() => setShowQuestions(false)}>
                Close Q&A
              </Button>
            </Card>
          ) : (
            <Card body className="opacity-2">
              <Button className="btn-light" block onClick={() => setShowQuestions(true)}>
                Show Q&A About Interpreting the Data
              </Button>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CoronaDash;
// ALTERNATIVE CLEANER USEEFFECT:
// useEffect(() => {
//   let didCancel = false;
//
//   async function fetchData() {
//     let jammer = await axios
//       .get(`/api/corona/cases`)
//     const fammer = await jammer.data;
//     const allCases = fammer.allCases;
//     const dateAdded = fammer.dateAdded;
//     UpdateCoronaStats.updateData(dateAdded);
//
//     const sorted = allCases.sort(
//       (a, b) => (a.confirmed > b.confirmed ? -1 : 1)
//     );
//     const filtered = sorted.filter(c => c.confirmed > 20000);
//
//     return {dateAdded, filtered, sorted};
//   }
//
//   fetchData()
//     .then(data =>{
//       if (!didCancel) {
//         setDateAdded(data.dateAdded);
//         setCorona(data.filtered);
//         setAllCases(data.sorted);
//       }
//     })
//   ;
//
//   return () => {
//     didCancel = true;
//   };
// }, []);

// {/*{global.cases ? (*/}
// {/*  <div style={{ marginBottom: "12px" }}>*/}
// {/*    <div>*/}
// {/*      <Card body className="pl-0 pr-0">*/}
// {/*        <h5>*/}
// {/*          <b>COVID-19 GLOBALLY</b>*/}
// {/*        </h5>*/}
//
// {/*        <Row className="no-gutters align-items-center global-numbers">*/}
// {/*          <Col md="3" xs="6">*/}
// {/*            <div*/}
// {/*              className="p-2 p-md-1"*/}
// {/*              // style={{borderRight: "1px solid rgb(204, 204, 204)"}}*/}
// {/*            >*/}
// {/*              Confirmed*/}
// {/*              <div style={{ fontSize: "1.8em" }}>*/}
// {/*                <b> {global.cases.toLocaleString("en")} </b>*/}
// {/*              </div>*/}
// {/*              /!*<div style={{ fontSize: "11px" }}>Confirmed</div>*!/*/}
// {/*              <div style={{ fontSize: "11px" }}>Cases</div>*/}
// {/*            </div>*/}
// {/*          </Col>*/}
// {/*          <Col md="3" xs="6">*/}
// {/*            <div*/}
// {/*              className="p-2 p-md-1"*/}
// {/*              // style={{borderLeft: "1px solid rgb(204, 204, 204)"}}*/}
// {/*            >*/}
// {/*              Deaths*/}
// {/*              <div style={{ fontSize: "1.8em", color: "rgb(181, 111, 111)" }}>*/}
// {/*                <b>{global.deaths.toLocaleString("en")}</b>*/}
// {/*              </div>*/}
// {/*              <div style={{ fontSize: "11px" }}>*/}
// {/*                /!*Deaths*!/*/}
// {/*                <div>{global.globalDeathRisk}%</div>*/}
// {/*              </div>*/}
// {/*            </div>*/}
// {/*          </Col>*/}
//
// {/*          <Col md="3" xs="6" style={{ color: "#1da000" }}>*/}
// {/*            <div className="p-2 p-md-1" >*/}
// {/*              Recovered*/}
// {/*              <div  style={{ fontSize: "1.8em" }} className="pt-0">*/}
// {/*                /!*<b>*!/*/}
// {/*                <b> {global.recovered.toLocaleString("en")}</b>*/}
// {/*                /!*</b>*!/*/}
// {/*              </div>*/}
// {/*              <div style={{ fontSize: "11px" }}>*/}
//
// {/*                <div>{global.globalRecoveredPercent}%</div>*/}
// {/*              </div>*/}
// {/*            </div>*/}
// {/*          </Col>*/}
//
// {/*          <Col md="3" xs="6">*/}
// {/*            <div*/}
// {/*              className="p-2 p-md-1"*/}
// {/*              // style={{borderRight: "1px solid rgb(204, 204, 204)"}}*/}
// {/*            >*/}
// {/*              Active*/}
// {/*              <div style={{ fontSize: "1.8em", color: "rgb(132, 132, 132)" }}>*/}
// {/*                <b>{global.active.toLocaleString("en")}</b>*/}
// {/*              </div>*/}
// {/*              <div style={{ fontSize: "11px" }}>Pending Outcomes</div>*/}
// {/*              /!*<div style={{ fontSize: "11px" }}>Infected</div>*!/*/}
// {/*            </div>*/}
// {/*          </Col>*/}
//
// {/*        </Row>*/}
// {/*        */}
// {/*      </Card>*/}
// {/*    </div>*/}
// {/*  </div>*/}
// {/*) : (*/}
// {/*  <Card className="loading-card">*/}
// {/*    <h6>COVID-19 DATA LOADING</h6>*/}
// {/*    <Spinner className="mx-auto" color="secondary" />*/}
// {/*  </Card>*/}
// {/*)}*/}
